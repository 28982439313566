import styled from 'styled-components'

export const CheckboxStyle = styled.label`
margin-bottom: 2px;
display: flex;
gap: 2px;
align-items: center;
justify-content: center;
font-size: var(--inputLabelFontSize);
color: var(--checkboxColor);
font-weight: 500;
letter-spacing: 0.02rem;
white-space: nowrap;
position: relative;
align-self: start;
width: min-content;
min-height: 50px;
/* width: 30px; */
span{
    /* margin-left: 5px; */
    display: inline-block;
    width: 15px;
    height: 15px;
    background: var(--inputBackground);
    border: var(--checkboxBorder);
    position: relative;
    box-shadow: var(--inputBoxShadow);
    cursor: pointer;
}

strong{
    margin-left: 0.2rem;
    color: (--primary);
    font-weight: 700;
}

input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

input:checked ~ span{
    background: rgb(255,255,255);
    box-shadow: var(--inputBoxShadow);
    border: var(--checkboxBorder);
}

input:checked ~ span:after{
    content: "";
    position: relative;
    display: block;
    top: 1px;
    width: 3px;
    height: 8px;
    margin: auto auto;
    border: var(--checkboxCheck);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
`;