import React from 'react'
import styled from 'styled-components'
const Wrapper = styled.div`
    background: transparent;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: var(--defaultTagBorder);
    background: var(--defaultTagBgd);
    color: var(--defaultTagColor);
    font-weight: ${props => (props.itemIndex + 1) === (props.currentIndex + 1) && props.step != 1 ? '700' : '400'};
    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius || '20px'};
    position: relative;
    gap: ${({ gap }) => gap};
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width || 'min-content'};
    height: ${({ height }) => height || '40px'};
    letter-spacing: ${({ letterSpacing }) => letterSpacing || '0.1rem'};
    font-size: 10px;

    >div{
        width: ${({ textWidth }) => textWidth || 'calc(100% - 60px)'};
        
        text-overflow: ${props => props.overflowOff ? '' : 'ellipsis'};
        white-space: nowrap;
        position: relative;
        overflow: ${props => props.overflowOff ? '' : 'hidden'};
    }

`;

export const Button = styled.button`
    all: unset;
    font-family: var(--defaultFont);
    color: '#fff';
    position: ${({ buttonPosition }) => buttonPosition || 'relative'};
    opacity: ${({ buttonOpacity }) => buttonOpacity};
    cursor: pointer;


`;

const Tag = ({ color, onClick, name, ...props }) => {

    return (
        <Wrapper
            className="tag"
            color={color}
            buttonPosition="relative"
            buttonRight="0"
            buttonOpacity="0.35"
            textLeft="0"
            height="min-content"
            borderRadius="5px"
            overflowOff={true}
            textWidth="fit-content"
            width="fit-content"
            gap="5px"
            padding="10px"
            letterSpacing="unset"
            onClick={onClick}
            {...props}>
            <div>{name}</div>
            <Button onClick={onClick} color={color} {...props}><span className='closeOrRemoveTag'>&#10006;&#xFE0E;</span></Button>
        </Wrapper>
    )
}


export default Tag