import { createGlobalStyle } from 'styled-components';

const hexToRgbA = (hex, alpha = 1) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
}


export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --navBarHeight: 60px;
        --defaultContainerHeight: calc(100vh - var(--navBarHeight));
        --background: #fff;
        --backgroundActiveLi: rgba(255,255,255,0.25);
        --borderRightActiveLi: 2px solid rgb(255,255,255);
        --yellow: #F2BD00;
        --white: #fff;
        --primary: #000;
        --fontColor: #000;
        --secondary: #575555;
        --lightGrey: #f2f2f2;
        --black: #000;
        --red: #9e0000;
        --green: #03cc00;
        --formBlue1: rgb(56, 106, 255);
        --formBlueRaw: 56, 106, 255;
        --formBlue1o25: rgba(56, 106, 255,0.25);
        --formBlue2: rgb(27, 134, 246);
        --formBlue2Raw: 27, 134, 246;
        --themis: var(--formBlue1);
        --defaultBorder: 1px solid var(--primary);
        --borderMediumThick: 2px solid var(--primary);
        --defaultFont: 'Albert Sans', sans-serif;
        --formColor: #000000;
        --defaultPagePadding: 0 50px;
        --formBackground: var(--background);
        --formBackground90: linear-gradient(90deg,var(--formBlue1), var(--formBlue2));
        --formBackgroundAngled: linear-gradient(45deg,var(--formBlue1), var(--formBlue2));
        --formBackgroundAngledTransparent: linear-gradient(45deg,rgba(56, 106, 255,.95), rgba(27, 134, 246,.95));
        --footerBgd: var(--lightGrey);
        --footerColor: #000;
        --primaryBackground:#e1e6ea ;
        --primaryBackground:var(--lightGrey);
        --formSelectedTileColor: var(--formBlue1);
        --formTileBoxShadow: 0 0 5px 3px rgba(var(--formBlueRaw),1);
        --defaultTileBorder: 1px solid rgba(0,0,0,0.1);
        --defaultTileSelectedBorder: 1px solid rgba(var(--formBlueRaw),0.5);
        --defaultTileBoxShadow: 0 0 5px rgba(var(--formBlue2Raw),1.0);
        --formSelectedTileBackground: var(--formBlue1o25);
        --defaultTagBgd:var(--formBlue1o25);
        --defaultTagColor:var(--formBlue1);
        --defaultTagBorder:1px solid var(--formBlue1);
        --geoAreaStroke: rgba(56, 106, 255,1);
        --geoAreaFillDefault: rgba(56, 106, 255,0);
        --geoAreaDetailedFillDefault: rgba(255,255,255,0.05);
        --geoAreaFillHover: rgba(56, 106, 255,0.25);
        --geoAreaFillSelected: rgba(56, 106, 255,0.25) !important;
        --greyBackground: #262626;
        --greyBackground: #262626;
        --buttonFontSize: 13px;
        --dropdownLetterSpacing: '';
        --dropdownBoxShadow: none;
        --dropdownBorder: var(--defaultBorder);
        --dropdownTextTransform: none;
        --dropdownHoverBackground: rgb(27, 134, 246, 0.5);
        --dropdownColor: var(--formBlue1);
        --homeBgd: var(--primaryBackground);
        --inputBoxShadow: none;
        --defaultInputBoxShadow: inset 0 0 4px rgba(var(--formBlue2Raw),0.5);
        --inputBackground: rgba(255,255,255,0.25);
        --inputPlaceHolderColor: rgba(0, 34, 128,0.5);
        --inputColor: #fff;
        --inputBackground: transparent;
        --inputBorder: var(--defaultBorder);
        --linkColor: var(--formBlue1);
        --linkColorDisclaimer: var(--primary);
        --underlineFocus: 2px solid var(--formBlue1);
        --underline: 2px solid var(--black);
        --underLineThemeBgd: rgba(var(--formBlue2Raw),0.1);
        --dropdownSelectedItemBackground: #dbe7f2;
        --dropdownBackground: #dbe7f2;
        --inputFocusOutline: var(--defaultBorder);
        --inputFocusColor: var(--formBlue1);
        --inputFocusBorder: var(--defaultBorder);
        --listUnderLineBgdHighlight: var(--formBlue1);
        --inputLabelFontSize: 0.7rem;
        --checkboxColor: var(--primary);
        --checkboxCheck: 1px solid var(--formBlue2);
        --asSeenOnFilter: invert(50%) sepia(100%) saturate(4840%) hue-rotate(216deg) brightness(100%) contrast(103%);
        --checkboxBoxShadow: none;
        --checkboxBorder: var(--inputBorder);
        --buttonFallbackBgd: var(--formBlue1);
        --defaultButtonFontWeight: 700;
        --buttonfontWeightHover: 700;
        --defaultButtonLetterSpacing: 0.03em;
        --defaultButtonPadding: 20px 30px; 
        --contactFormButtonColor: var(--primaryBackground);
        --contactFormButtonBgd: var(--defaultButtonColor);
        --contactFormButtonBgdHover: var(--defaultButtonColor);
        --contactFormButtonBorder: 1px solid var(--defaultButtonColor);
        --contactFormButtonBorderHover: 1px solid var(--defaultButtonColor);
        --defaultButtonBackground: #386aff;
        --buttonDisabledBackground: #c2c2c2;
        --buttonDisabledBorder: 1px solid var(--formBlue1);
        --buttonDisabledBorder2: 1px solid var(--formBlue1);
        --buttonDisabledColor: #828282;
        --buttonDisabledColor2: var(--formBlue1);
        --buttonDisabledBgd: rgb(56, 106, 255,0.15);
        --defaultButtonColor: rgb(56, 106, 255,1);
        --defaultButtonBackgroundHover: rgb(56, 106, 255,1);;
        --defaultButtonColorHover: var(--primaryBackground);
        --defaultButtonBorderHover: 1px solid rgba(255,255,255,1);
        --defaultButtonBorder: 2px solid var(--defaultButtonBackground);
        --formSubmitButtonColor: #fff;
        --formSubmitButtonBgd: var(--defaultButtonBackground);
        --formSubmitButtonColorHover: #fff;
        --formSubmitButtonBgdHover: var(--defaultButtonBackground);
        --formBackButtonColor:  var(--defaultButtonBackground);
        --formBackButtonBgd: #fff;
        --formBackButtonColorHover: #fff;
        --formBackButtonBgdHover: var(--defaultButtonBackground);
        --formBackButtonBorder: 2px solid var(--defaultButtonBackground);
        --fontMicro: 10px;
        --navArrowFill: #e0f5ff;
        --navArrowOpacity: 1;
        --navArrowStroke: var(--formBlue1);
        --navArrowCircleFill: var(--formSelectedTileBackground);
        --navBarPadding: 0px 50px;
        --navBarBoxShadow: 0 0 5px #000;
        --navBarFontWeightActive: 700;
        --fontSmall: 11px;
        --headerWeight: 500;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --fontNormal: 1.0em;
        --bold: 700;
        --medium: 500;
        --boldSmall: 600;
        --boldSmallLetterSpacing: 0.07rem;
        /* --buttonHeight: 56px; */
        --buttonBorder: 1px solid white;
        --purpleGrey: #696980;
        --themisMobileStroke: rgba(var(--formBlueRaw), 0.25);
        --defaultInputHeight: 49px;
        --caretColor: var(--formBlue1);
        --resultsTileBackground: var(--primaryBackground);
        --hamburgerMenuFill: var(--formBlue1);
        --progressBarBackground: rgba(var(--formBlueRaw),0.25);
        --progressBar: rgba(var(--formBlueRaw),0.9);
        --skipToEndBgd: var(--formSelectedTileBackground);
        --skipToEndColor: var(--navArrowStroke);
        --skipToEndBorder: 1px solid var(--navArrowStroke);
    }
    
    .antiSpamPhoneNumber{
        &::after{
            content: '1-800-234-4919';
        }
    }
    .no-wrap{
        white-space: nowrap;
    }
    .privacycontact{
        &::after{
            content: "(332) 333-0426";
        }
    }
    .cp{
        &::after{
            content: "Copyright ©2022-2023 Development Technologies Group, LLC. All Rights Reserved.";
        }
    }
    * {
        box-sizing: border-box;
        font-family: var(--defaultFont);
    }
    #root{
        max-width: 100%;
        height: 100%;
    }
    .gradientText{
        background: var(--formBackgroundAngled);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .closeOrRemoveTag{
        background: var(--formBackgroundAngled);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .noMarginForText{
        h1, h2, h3, p{
            margin: 0;
        }
    }
    .hideOnMobile{
        
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            position: absolute;
            z-index: -1;
            visibility: hidden;
            width: 0;
            height: 0;
            display: none;
        }
    }
    .centerOnMobile{
        
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            text-align: center;
        }
    }
    /* .changingStep{
        .previousStep{
            display: flex !important;
            animation-name: example2;
            animation-duration: 200ms;
            position: absolute;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
        .activeStep{
            position: absolute;
            animation-name: example;
            animation-duration: 200ms;
            animation-delay: 200ms;
            opacity: 0;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
        @keyframes example {
            from {
                transform: translateY(600px);
                opacity: 0;
            }
            to {
                transform: translateY(0);
                opacity: 1;
            }
        }
        @keyframes example2 {
            from {
                transform: translateY(0);
                opacity: 1;
            }
            to {
                transform: translateY(-600px);
                display: none;
                opacity: 0;
            }
        }
    }
    &:not(.changingStep) .previousStep{
        display: none;
        transition: 0s;
    } */
    .hideOnDesktop{
        position: absolute;
        z-index: -1;
        visibility: hidden;
        width: 0;
        height: 0;
        display: none;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            position: relative;
            z-index: undefined;
            visibility: undefined;
            /* width: 0; */
            /* height: 0; */
            /* display: none; */
        }
    }
    .hideOnDesktop2{
        
        position: relative;
            display: undefined;
            visibility: undefined;
        @media only screen and (min-width: 750px), screen and (min-device-width: 750px) {
            position: absolute;
            visibility: hidden;
            display: none;
        }
    }
    .homeHeaderContainer{
        position: relative;
        overflow: hidden;
        h1, h3, p{
            transition: transform 0.5s, opacity 1.5s;
            position: relative;
        }
        h1, h3, p{
            opacity: 1;
            transform: translateY(0);
        }
        h1:not(.visible), h3:not(.visible), p:not(.visible){
            opacity: 0;
            transform: translateY(200px);
        }
    }
    .homeHeaderContainer.overflowVisible{
        overflow: visible;
    }
    h1.homeHeader{
        color: var(--formBlue1);
    }
    .homeHeader{
        width: 100%;
        max-width: 100%;
        text-align: left;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            max-width: 100%;
            width: 100%;
            text-align: center;
            
        }
        }
    
    h3.homeHeader{

        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            font-weight: 500 !important;
            
        }
        }
    
    .homeHeaderButton{
        max-width: 250px;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            max-width: 100%;
        }
    }
    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--black);
        height: 100%;
    }
    @font-face {
        font-family: 'Cano';
        src: local('Cano'), url({CanoOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    .left{
        text-align: left;
    }
    html{
        margin: 0;
        font-size: 15px;
        font-family: 'Colombia';
        height: 100%;
        background: var(--background);
        /* h1{
            font-size: 3em;
            font-weight: var(--bold);
        } 
        */
        h1{
            user-select: none;
            font-size: 2em;
            font-weight: var(--bold);
        }
        h2{
            user-select: none;
            font-size: 1.5em;
            font-weight: var(--medium);
        }
        h4{
            font-weight: var(--boldSmall);
            font-size: .9em;
            margin: 0;
            
            /* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
        }
        p{
            /* font-weight: 300; */
            font-size: 1rem;
        }
        a{
            display: flex;
            text-decoration: none;
            color: var(--fontColor);
        }

    }
    @media screen and (max-width:320px) {
    html {
        font-size: 14px;
    }
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }
    @media only screen and (max-width: 900px), screen and (max-device-width: 900px) {
        .hideOnMobile{
                visibility: hidden;
                display: none;
            }
            .p50LRMobile{
                padding-left: 50px;
                padding-right: 50px;
            }
            .flexRowMobile{
                display: flex;
                flex-direction: row
            }   
            .wrapMobile{
            flex-wrap: wrap;
            }
            .centerTextMobile{
                text-align: center;
            }
            .noWrapOnMobile{
                flex-wrap: nowrap;
            }
            
            .noPaddingMobile{
                padding: 0;
            }
        
    }
    @media only screen and (max-width: 1080px), screen and (max-device-width: 1080px) {
        .colOnMobile{
                flex-direction: column !important;
            }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;