import React from "react";
import styled from "styled-components";
import Flexbox from "../Flexbox";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
import Button from "../ButtonHome";
import { useNavigate } from "react-router-dom";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import handshake from "../../images/handshake.svg";
import investigateErrors from "../../images/investigateErrors.svg";
import diamond from "../../images/diamond.svg";
import magnify from "../../images/magnify.svg";
import scales from "../../images/scales.svg";
import protection from "../../images/protection.svg";
import ThemisAnimated from "../ThemisAnimated";
// import
const OrderedList = styled.ul`
  color: var(--primary);
  list-style: none;
  padding: 0;
  max-width: 700px;
  align-self: center;
  width: 90%;
  h3 {
    text-align: left;
    margin: 0;
    @media only screen and (max-width: 550px),
        screen and (max-device-width: 550px) {
          text-align: center;
        }
  }
  & > :not(:last-child) {
    margin: 0 0 50px;
  }
  /* margin: 100px 0; */
`;

const Span = styled.h2`
  z-index: 1;
  position: relative;
  height: min-content;
  width: min-content;
  white-space: nowrap;
  align-self: center;
  margin: 0;
  margin-top: -0.45em;
  /* font-size: 1.2rem; */
  ::after {
    content: "";
    font-size: inherit;
    position: absolute;
    left: 0;
    bottom: -0.25em;
    /* width: 0; */
    width: 102%;
    height: 0.2em;
    transition: all 0.4s ease;
    /* opacity: 0; */
    opacity: 1;
    background-color: transparent;
    /* background: linear-gradient(
      90deg,
      var(--formBlue1),
      var(--formBlue2) 50%,
      transparent 50%
    ); */
    background: linear-gradient(
      90deg,
      var(--listUnderLineBgdHighlight),
      transparent 50%,
      transparent 50%
    );
    background-position: 100% 0%;
    background-size: 200% 100%;
    z-index: -1;
  }
  &.visible::after {
    width: 102%;
    opacity: 1;
    background-position: 0% 0%;
    /* background-color: var(--formBlue1); */
  }
  @media only screen and (max-width: 550px),
        screen and (max-device-width: 550px) {
          white-space: normal;
          width: 100%;
        }
`;

const ListItem = styled.li`
  display: grid;
  width: 100%;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    "icon heading"
    ". text";
  column-gap: 20px;
  @media only screen and (max-width: 550px),
        screen and (max-device-width: 550px) {
          grid-template-columns: 1fr;
          grid-template-rows: min-content min-content min-content;
          grid-template-areas:
            "icon"
            "heading"
            "text";
            justify-content: center; 
            justify-items: center;     
            /* text-align: center !important; */
            gap: 20px;
        }
  /* text-shadow: 0 0 1px var(--formBlue1); */
`;

const Marker = styled.div`
  display: flex;
  background: #ffffff;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 45px;
  width: 45px;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.4s ease;
  border: 1px solid var(--formBlue1);

  &.visible {
    box-shadow: 0 0 12px #ffffff;
    /* box-shadow: 0 0 5px var(--formBlue1); */
  }
  img {
    position: relative;
    height: 100%;
    width: 100%;
  }
`;
const ListComponent = ({ img, heading, text }) => {
  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px 0px -40% 0px",
      threshold: 0.5, // half of item height
    },
    false
  );
  return (
    <ListItem>
      <Marker className={isVisible ? "visible" : undefined}>
        <img src={img} />
      </Marker>
      <Span
        ref={containerRef}
        gridArea="heading"
        className={isVisible ? "visible" : undefined}
      >
        {heading}
      </Span>
      <h3 style={{ fontWeight: "300", gridArea: "text" }}>{text}</h3>
    </ListItem>
  );
};

const PageList = () => {
  const navigate = useNavigate()
  const listItems = [
    {
      img: diamond,
      heading: "Maximize Your Compensation",
      text: `Equipped with the knowledge and experience required to pursue the absolute maximum possible payout for your case.`,
    },
    {
      heading: "Protection Against Unfair Claim Denial",
      text: "Protects you from insurance company lawyers working to deny your claim or minimize what you're rightly owed.",
      img: protection,
    },
    {
      // heading: 'A Monetary Motivation to Win',
      heading: "Only Paid When You Win",
      text: "You don't pay a thing unless the attorney wins your case.",
      img: handshake,
    },
    {
      heading: "Accurate Accident Assessment",
      text: "Cuts through the insurance company smokescreen to tell you exactly what's possible with your case.",
      img: scales,
    },
    {
      heading: "Free Case Evaluation",
      text: "A good attorney listens carefully, then tells you what your case is really worth and how you can get justice for your injuries.",
      img: magnify,
    },
  ];
  return (
    <Flexbox
      column
      alignItems="center"
      width="90%"
      alignSelf="center"
      padding="50px 0"
    // background="var(--formBackground)"
    >
      <Flexbox position="absolute" zIndex="0" alignSelf="end" right="50px">
        {/* <ThemisAnimated stroke="rgba(255,255,255,0.25)"/> */}
      </Flexbox>

      <h1 style={{ color: "inherit" }}>How can an attorney help?</h1>
      <OrderedList>
        {listItems.map((item, index) => (
          <ListComponent key={index} {...item} />
        ))}
      </OrderedList>
      {/* <Button
        boxShadow="0 0 7px rgba(0,0,0,0.5)"
        height="56px" margin="25px 0" padding="0" flexShrink="0" width="100%"
        // className="homeHeaderButton"
        border="none" borderHover="none"
        maxWidth="300px"
        text="Get Started" onClick={() => navigate('/form', { state: { form_entry: 'Home Page - How Can An Attorney Help Section' } })}
        background="var(--formBackgroundAngled)"
        colorHover="var(--formBlue1)"
        bgdHover="#fff" /> */}
      {/* <p style={{ margin: '-10px 0 0' }}>Want to <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/about')}>Learn More</span> first?</p> */}
    </Flexbox>
  );
};

export default PageList;
