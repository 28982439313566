import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import anime from 'animejs'

import Flexbox from '../Flexbox'

const SVG = styled.svg`
height: 100%;
max-height: calc(100vh - 100px);
text-align: center;
position: relative;
.cls-1{fill:url(#linear-gradient-2);}
#blurredEnvelope{filter:url(#gaussianBlur);}
.cls-2{stroke:#ccc;}
.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8{stroke-miterlimit:10;}
.cls-2,.cls-3,.cls-4,.cls-9,.cls-7,.cls-8{fill:none;}
.cls-2,.cls-7{stroke-width:4px;}
.cls-10{fill:#fff;}
.cls-3,.cls-4,.cls-5,.cls-6{stroke:#5d8bfe;stroke-width:6px;}
/* .cls-3,.cls-6{opacity:.5;} */
.cls-11{letter-spacing:-.02em;}
.cls-12{fill:#257cf9;font-family:'Albert Sans';font-size:22px;font-weight: bold}
.cls-5,.cls-6{fill:rgba(125, 162, 254, 0.35);}
.cls-13{letter-spacing:0em;}
.cls-14{letter-spacing:0em;}
.cls-9{stroke-linejoin:round;stroke-width:5px;}
.cls-9,.cls-7,.cls-8{stroke:#fff;stroke-linecap:round;}
.cls-15{letter-spacing:-.05em;}
.cls-7{opacity:.25;}
/* .cls-7,.cls-16{opacity:.25;} */
.cls-17{fill:#ccc;}
.cls-26,.cls-18{fill:#257df9;font-family:AlbertSans-Regular, 'Albert Sans';font-size:15.15px;}
.cls-19{fill:url(#linear-gradient);}
.cls-20{letter-spacing:0em;}
.cls-21{letter-spacing:-.05em;}
.ovrly{opacity:.15;}
.cls-8{stroke-width:3px;}
#attorneyCallText{fill: #fff;}
.yourAttorneyText{font-size:40px;color:#fff;}
.yourAttorneyText2{letter-spacing:-.07em;}
.yourAttorneyText3{letter-spacing:0em;}
.yourAttorneyText4{font-size:12px;}
.cls-30{letter-spacing:-.01em;}
.phone{fill:#0ad38c;}
@media only screen and (max-width: 550px),
        screen and (max-device-width: 550px) {
            width: 100%;
            max-height: 50vh;
        }
`;
const AnimatedStep2 = ({ className }) => {

    // useEffect(() => {
    //     const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
    //         let elements;
    //         elements = document.querySelectorAll(className);
    //         Array.from(elements).forEach((element) => {
    //             let BBox = element.getBBox();
    //             let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
    //             let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
    //             let BBoxX = BBox["x"] + BBoxX2;
    //             let BBoxY = BBox["y"] + BBoxY2;
    //             BBox = BBoxX + "px" + " " + BBoxY + "px";
    //             element.style.transformOrigin = BBox;
    //         });
    //     }
    //     fixSvgElementCoordinates('#letterhead')
    //     // fixSvgElementCoordinates('#tape2')

    //     anime.set('#letterText1', {
    //         opacity: 0
    //     })
    //     anime.set('.envelope', {
    //         opacity: 0
    //     })
    //     anime.set('#matchGroup', {
    //         opacity: 0
    //     })
    //     anime.set('#letterhead', {
    //         y: 381.95 + 326.1,
    //         height: 0
    //     })
    //     var tl = anime.timeline({
    //         easing: 'easeOutExpo',
    //         duration: 3500,
    //         autoplay: true,
    //         loop: true,
    //         delay: 3500
    //     })
    //     tl
    //         .add({
    //             targets: '.envelope',
    //             opacity: 1,
    //             duration: 550,
    //             delay: 500,
    //             // endDelay: 1000

    //         })
    //         .add({
    //             targets: '#letterhead',
    //             height: 326.1,
    //             y: 381.95,
    //             // scale: 1.5,
    //             duration: 1250,
    //             // delay: 500,
    //         })
    //         .add({
    //             targets: '#letterText1',
    //             opacity: 1,
    //             duration: 500,
    //             // endDelay: 1000

    //         })

    //         .add({
    //             targets: '#matchGroup',
    //             opacity: 1,
    //             duration: 250,
    //             endDelay: 1000
    //         }, '-=250')

    //     tl.play()

    // }, [])
    return (

        <SVG id="Layer_2" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 462.19 935.45">
            <defs>
                <linearGradient id="linear-gradient" x1="-80.15" y1="780.32" x2="545.04" y2="155.13" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#386aff" />
                    <stop offset="1" stopColor="#1b86f6" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="231.32" y1="384.87" x2="231.32" y2="693.81" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fff" stopOpacity=".75" />
                    <stop offset="1" stopColor="#fff" stopOpacity=".15" />
                </linearGradient>
                <filter id="gaussianBlur">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
                </filter>
                <filter id="AI_Shadow_1">
                    <feGaussianBlur in="SourceAlpha" result="blur" stdDeviation="5" />
                    <feColorMatrix result="bluralpha" type="matrix" values=
                        "1 0 0 0   0 0 1 0 0   0 0 0 1 0   0 0 0 0 0.4 0 " />
                    <feMerge>
                        <feOffset dx="0" dy="0" in="blur" result="offsetBlurredAlpha" />
                        <feMergeNode in="offsetBlurredAlpha" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g id="Layer_1-2">
                <path className="cls-17" d="M396.03,9c31.52,0,57.16,25.53,57.16,56.9V869.56c0,31.37-25.64,56.89-57.16,56.89H68.16c-31.51,0-57.15-25.52-57.15-56.89V65.9c0-31.37,25.64-56.9,57.15-56.9H396.03M139.82,50.96c-4.67,0-9.07-1.78-12.36-5.02-3.27-3.21-5.07-7.48-5.07-12,0-7.9-6.49-14.32-14.47-14.32h-41.27c-26.03,0-47.2,20.85-47.2,46.48V869.36c0,25.63,21.17,46.48,47.2,46.48H398.25c26.03,0,47.2-20.85,47.2-46.48V66.1c0-25.63-21.17-46.48-47.2-46.48h-41.27c-3.86,0-7.48,1.48-10.19,4.16-2.76,2.71-4.28,6.32-4.28,10.16,0,9.38-7.82,17.02-17.43,17.02H139.82M396.03,3H68.16C33.28,3,5.01,31.16,5.01,65.9V869.56c0,34.74,28.27,62.89,63.15,62.89H396.03c34.88,0,63.16-28.15,63.16-62.89V65.9c0-34.74-28.28-62.9-63.16-62.9h0ZM139.82,56.96h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48V869.36c0,22.35-18.44,40.48-41.2,40.48H66.65c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h0Z" />
                <path className="cls-17" d="M254.6,39.53h-44.56c-1.1,0-2,.9-2,2s.9,2,2,2h44.56c1.1,0,2-.9,2-2s-.9-2-2-2h0Z" />
                <path className="cls-17" d="M285.86,36.21c-2.94,0-5.32,2.38-5.32,5.32s2.38,5.32,5.32,5.32,5.32-2.38,5.32-5.32-2.38-5.32-5.32-5.32h0Z" />
                <path className="cls-19" d="M439.45,66.1V869.36c0,22.35-18.44,40.48-41.2,40.48H66.65c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48Z" />
                <line className="cls-2" x1="2" y1="118.55" x2="2" y2="153.28" />
                <line className="cls-2" x1="2" y1="192.63" x2="2" y2="262.87" />
                <line className="cls-2" x1="2" y1="277.79" x2="2" y2="348.03" />
                <line className="cls-7" x1="172.6" y1="898.91" x2="292.3" y2="898.91" />
                {/* <g id="nextArrow">
                    <path className="cls-10" d="M368.78,803.29c-4.38-4.38-6.89-10.46-6.87-16.67,0-13.06,10.63-23.69,23.69-23.69,6.38,0,12.34,2.54,16.88,7.08,4.42,4.42,6.88,10.29,6.92,16.55,.05,6.33-2.37,12.3-6.82,16.8-9.15,9.3-24.16,9.43-33.47,.28l-.27-.27-.07-.07Zm31.7-31.56c-3.98-3.98-9.26-6.17-14.87-6.17-11.61,0-21.05,9.45-21.05,21.05-.02,5.52,2.2,10.91,6.1,14.81l.06,.06,.27,.27c8.26,8.13,21.61,8.01,29.74-.26,3.95-4,6.1-9.3,6.06-14.93s-2.21-10.78-6.29-14.84h0Z" />
                    <polygon className="cls-10" points="394.65 786.68 386.81 794.52 385.01 792.71 389.76 787.96 377.95 787.96 377.95 785.4 389.75 785.39 385.01 780.65 386.81 778.85 394.65 786.68" />
                </g>
                <g id="backArrow" className="cls-16">
                    <path className="cls-10" d="M96.12,770.05c4.38,4.38,6.89,10.46,6.87,16.67,0,13.06-10.63,23.69-23.69,23.69-6.38,0-12.34-2.54-16.88-7.08-4.42-4.42-6.88-10.29-6.92-16.55-.05-6.33,2.37-12.3,6.82-16.8,9.15-9.3,24.16-9.43,33.47-.28l.27,.27,.07,.07Zm-31.7,31.56c3.98,3.98,9.26,6.17,14.87,6.17,11.61,0,21.05-9.45,21.05-21.05,.02-5.52-2.2-10.91-6.1-14.81l-.06-.06-.27-.27c-8.26-8.13-21.61-8.01-29.74,.26-3.95,4-6.1,9.3-6.06,14.93s2.21,10.78,6.29,14.84h0Z" />
                    <polygon className="cls-10" points="70.25 786.66 78.08 778.83 79.89 780.64 75.14 785.38 86.95 785.38 86.95 787.95 75.15 787.96 79.89 792.69 78.08 794.5 70.25 786.66" />
                </g> */}
                <g id="blurredEnvelope">
                    <path className="cls-5 envelope" d="M368.45,549.77l-137.13,83.5-137.13-83.5c1.45-3.36,3.78-6.34,6.81-8.58l119.92-89.03c6.17-4.58,14.63-4.58,20.8,0l119.92,89.03c3.03,2.24,5.36,5.22,6.81,8.58Z" />
                    <rect id="letterhead" className="cls-1" x="114.73" y="381.95" width="233.18" height="326.1" rx="19.21" ry="19.21" />
                    <line className="cls-4 envelope" x1="231.32" y1="633.27" x2="94.19" y2="549.77" />
                    <path className="cls-6 envelope" d="M94.19,549.77l119.99,73.03-118.2,92.88c-2.27-4.47-3.54-9.53-3.54-14.88v-142.6c0-2.94,.61-5.8,1.75-8.43Z" />
                    <path className="cls-6 envelope" d="M370.2,558.2v142.6c0,5.35-1.27,10.41-3.54,14.88l-118.14-92.88,119.93-73.03c1.14,2.63,1.75,5.49,1.75,8.43Z" />
                    <line className="cls-3 envelope" x1="95.98" y1="715.68" x2="94.19" y2="716.77" />
                    <path className="cls-6 envelope" d="M366.66,715.68c-5.43,10.75-16.58,18.12-29.46,18.12H125.44c-12.88,0-24.03-7.37-29.46-18.12l135.34-106.41,135.34,106.41Z" />
                    <g id="letterText1">
                        <text className="cls-12" 
                        transform="translate(160.39 422.07)"
                        >
                            <tspan x="0" y="0">We found you</tspan>
                            <tspan x="13" y="26.4">an attorney.</tspan>
                            
                            {/* <tspan className="cls-15" x="0" y="0">W</tspan>
                            <tspan x="21.56" y="0">e </tspan>
                            <tspan className="cls-14" x="40.02" y="0">f</tspan>
                            <tspan className="cls-20" x="47.21" y="0">ound</tspan>
                            <tspan className="cls-11" x="-33.68" y="26.4">y</tspan>
                            <tspan className="cls-20" x="-21.45" y="26.4">ou an attorn</tspan>
                            <tspan className="cls-11" x="101.99" y="26.4">e</tspan><tspan className="cls-21" x="113.91" y="26.4">y</tspan>
                            <tspan className="cls-20" x="125.38" y="26.4">.</tspan> */}
                            </text>
                        <text className="cls-18" transform="translate(185.97 476.81)">
                            {/* <tspan x="0" y="0">Lorem Ipsum</tspan> */}
                        </text>
                    </g>
                    <g id="matchGroup">
                        <g id="personRight">
                            <path className="cls-8" d="M292.49,547.42v13.56c-5.45,3.53-11.95,5.58-18.92,5.58s-13.15-1.95-18.51-5.31v-13.83c0-6.07,4.71-11.02,10.66-11.44,2.16,1.88,4.98,3.01,8.06,3.01s5.9-1.13,8.06-3.01c5.96,.41,10.65,5.37,10.65,11.44Z" />
                            <path className="cls-8" d="M286.11,526.67c0,3.72-1.65,7.06-4.26,9.32-2.16,1.88-4.98,3.01-8.06,3.01s-5.9-1.13-8.06-3.01c-2.61-2.26-4.26-5.59-4.26-9.32,0-6.81,5.52-12.33,12.33-12.33s12.33,5.52,12.33,12.33Z" />
                        </g>
                        <path id="pathLeft" className="cls-9" d="M239.4,525.81h-15.89c-2.78-16.51-17.14-29.08-34.43-29.08-19.28,0-34.92,15.63-34.92,34.92,0,12.31,6.37,23.13,15.99,29.34,5.45,3.53,11.95,5.58,18.93,5.58s13.14-1.94,18.5-5.31c5.02-3.15,9.19-7.54,12.07-12.74" />
                        <g id="personLeft">
                            <path className="cls-8" d="M207.58,547.42v13.83c-5.36,3.37-11.71,5.31-18.51,5.31s-13.47-2.05-18.93-5.58v-13.56c0-6.07,4.7-11.01,10.65-11.44,2.16,1.87,4.99,3.01,8.07,3.01s5.9-1.13,8.06-3.01c5.95,.41,10.66,5.37,10.66,11.44Z" />
                            <path className="cls-8" d="M201.19,526.67c0,3.72-1.65,7.06-4.26,9.32-2.16,1.88-4.98,3.01-8.06,3.01s-5.91-1.14-8.07-3.01c-2.61-2.26-4.26-5.59-4.26-9.32,0-6.81,5.53-12.33,12.34-12.33s12.33,5.52,12.33,12.33Z" />
                        </g>
                        <polygon id="arrowLeft" className="cls-10" points="248.67 525.81 237.12 519.14 237.12 532.48 248.67 525.81" />
                        <polygon id="arrowRight" className="cls-10" points="213.97 537.49 225.52 544.16 225.52 530.82 213.97 537.49" />
                        <path id="pathRight" className="cls-9" d="M223.24,537.49h15.89c2.78,16.51,17.14,29.08,34.43,29.08,19.28,0,34.92-15.63,34.92-34.92,0-12.31-6.37-23.13-15.99-29.34-5.45-3.53-11.95-5.58-18.93-5.58s-13.14,1.94-18.5,5.31c-5.02,3.15-9.19,7.54-12.07,12.74" />
                    </g>
                </g>
                <g id="overlay" className="ovrly" >
                    <path d="M439.45,66.1V869.36c0,22.35-18.44,40.48-41.2,40.48H66.65c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48Z" />
                </g>
                <g id="attorneyCallText" className="callFadeIn">
                    <text className="yourAttorneyText" transform="translate(105.91 192.68)">Your Attorney
                        {/* <tspan className="yourAttorneyText2" x="0" y="0">Y</tspan>
                        <tspan x="23.16" y="0">our Attorn</tspan>
                        <tspan className="yourAttorneyText3" x="210.4" y="0">e</tspan>
                        <tspan x="232.56" y="0">y</tspan> */}
                    </text>
                    <text className="yourAttorneyText4" transform="translate(215.84 218.13)">
                        <tspan x="0" y="0">Office</tspan>
                    </text>
                </g>
                <g id="answerCall" className="callFadeIn">
                    <rect className="cls-10" opacity="0.35" x="77.4" y="753.48" width="310.1" height="65.73" rx="32.87" ry="32.87" />
                    <g id="backArrow" className="cls-24">
                        <path className="cls-9" d="M138.44,786.74c0,15.26-12.42,27.67-27.67,27.67-7.46,0-14.43-2.98-19.72-8.27-5.16-5.16-8.04-12.02-8.1-19.33-.06-7.39,2.77-14.37,7.97-19.62,10.68-10.86,28.22-11.02,39.1-.33l.32,.32s.06,.06,.08,.08c5.13,5.13,8.05,12.22,8.02,19.48Z" />
                    </g>
                    <g id="Layer_2-2">
                        <g id="Layer_4">
                            <path className="phone" d="M100.77,771c.61,.43,1.27,.79,1.81,1.3,1.41,1.33,2.76,2.72,4.12,4.1,1.24,1.25,1.23,2.46-.01,3.71-.86,.85-1.71,1.7-2.55,2.55-.52,.53-.56,.91-.14,1.52,2.5,3.56,5.61,6.66,9.17,9.17,.63,.45,1.01,.4,1.56-.15,.9-.89,1.79-1.79,2.68-2.68,.98-.98,2.21-1.1,3.21-.15,1.75,1.65,3.45,3.36,5.11,5.1,.94,.98,.83,2.24-.14,3.21-.56,.56-1.16,1.1-1.68,1.69-1.54,1.76-3.53,2.15-5.72,1.91-3-.34-5.69-1.56-8.2-3.15-5.58-3.54-10.02-8.18-13.06-14.09-.91-1.77-1.57-3.62-1.82-5.61-.02-.08-.05-.15-.09-.22v-2.08c.04-.13,.09-.27,.12-.4,.22-1.14,.67-2.16,1.54-2.96,.59-.55,1.13-1.16,1.73-1.71,.43-.39,.92-.7,1.38-1.05h.98Z" />
                        </g>
                    </g>
                    <text className="cls-26" transform="translate(149.02 791.73)">
                        <tspan x="0" y="0" fontSize="18px">slide to answer</tspan>
                        {/* <tspan className="cls-30" x="31.32" y="0">w</tspan>
                        <tspan x="46.2" y="0">er</tspan> */}
                    </text>
                </g>
            </g>
        </SVG>

    )
}

export default AnimatedStep2