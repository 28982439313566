import React from 'react'
import Airplane from '../Airplane'
import Flexbox from '../Flexbox'

const Success = () => {
    return (
        <Flexbox column color="var(--formColor)" height="100vh" alignItems="center" justifyContent="center" background="var(--formBackground)" margin="calc(var(--navBarHeight) * -1) 0 0">
            <Airplane/>
            <h1 style={{fontSize: '2.6rem', marginTop: '0'}}>Help is on the way!</h1>
            <p style={{maxWidth: '90%'}}>We understand your case is urgent so the attorney will call you as soon as they are available.</p>

        </Flexbox>
    )
}

export default Success