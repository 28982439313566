import React, { useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import PageContentContainer from './PageContentContainer'
import ButtonHome from "./ButtonHome";
import Button from './Button';
import styled from 'styled-components'
import Flexbox from './Flexbox';
import PageList from './PageListStyle3'
import anime from 'animejs';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import handshake from '../images/handshakebusiness.png'
const Span = styled.span`
font-family: inherit;
`;
const Results = ({ background = "var(--formBackground)" }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  // const [containerRef, isVisible] = useIntersectionObserver(
  //   {
  //     root: null,
  //     rootMargin: "0px",
  //     threshold: 0.75, // half of item height
  //   },
  //   true
  // );
  // useEffect(() => {
  //   let yourElement = document.querySelector('#num .num')
  //   let ab = document.querySelector('.ab')
  //   if (isVisible)
  //     anime({
  //       targets: yourElement,
  //       innerHTML: [1, 1200],
  //       easing: 'linear',
  //       duration: 3000,
  //       update: function (a) {
  //         let v = a.animations[0].currentValue
  //         if (v >= 1000) {
  //           v = (v / 1000).toFixed(2)
  //           if (ab.innerHTML === 'M')
  //             ab.innerHTML = 'B'
  //         }
  //         yourElement.innerHTML = v
  //       },
  //       round: 10 // Will round the animated value to 1 decimal
  //     })
  // }, [isVisible])
  return (
    <PageContentContainer column overflow="none" color="var(--primary)" padding="var(--navBarHeight) 0 0"

      margin="calc(var(--navBarHeight) * -1) 0 0" height="100%" minHeight="100vh">
      <Flexbox column width="100%" background={background}>

        <h1 style={{ margin: '50px 0 1em' }}>Results From Attorneys in Our Network</h1>
        <Flexbox id="num" fontSize="4rem" fontFamily="'Playfair Display',serif">$<Span className="num"></Span><Span className="ab">1.2B</Span></Flexbox>

        <span>Won For Clients</span>
        <Button boxShadow="0 0 7px rgba(0,0,0,0.5)"
          height="56px" margin="25px 0" padding="0" flexShrink="0" width="90%"
          // className="homeHeaderButton"
          border="none" borderHover="none"
          borderRadius="30px"
          maxWidth="350px"
          text="What's your case worth?"
          onClick={() => navigate('/form', { state: { form_entry: 'Results Page - Get Started Button' } })}
          // background="var(--formBackgroundAngled)"
          // colorHover="var(--formBlue1)"
          // bgdHover="#fff"
          background="var(--primaryBackground)"
          colorHover="var(--formBlue1)"
          bgdHover="#fff"
        />
      </Flexbox>
      <PageList />

      {/* {
        pathname.includes('result') && <ButtonHome boxShadow="0 0 7px rgba(0,0,0,0.5)"
          height="56px" margin="25px 0" padding="0" flexShrink="0" width="90%"
          // className="homeHeaderButton"
          border="none" borderHover="none"
          maxWidth="350px"
          text="Get Started" onClick={() => navigate('/form', { state: { form_entry: 'Results Page - Get Started Button' } })}
          background="var(--formBackgroundAngled)"
          colorHover="var(--formBlue1)"
          bgdHover="#fff" />
      } */}
    </PageContentContainer>
  )
}

export default Results