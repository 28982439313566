import React from 'react'
import styled from 'styled-components'
import WhyHire from './WhyHire'
import PageContentContainer from './PageContentContainer'
import HowItWorks from './HowItWorks'
import Section from './Section'
import Flexbox from './Flexbox'
import InvestigateIcon from './InvestigateIcon'
import FooterDisclaimer from './FooterDisclaimer'
import Footer from './Footer'
import Button from './ButtonHome'
import EmbeddedGeographicArea from './EmbeddedGeographicArea'
import ThemisAnimated from './ThemisAnimated'
import GridContainer from './GridContainer'
import About from './About'
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";
import attorneys from '../images/vincevaghn.png'
import AsSeenOn from './AsSeenOn'
import { useNavigate } from 'react-router-dom'
import Hero from './Hero'
import reducePenalties from '../images/reducePenalties.svg'
import investigateErrors from '../images/investigateErrors.svg'
import evaluateOptions from '../images/evaluateOptions.svg'
import freeInitCaseEval from '../images/free-initial-case-evaluation.svg'
import diamond from '../images/diamond.svg'
import handshake from '../images/handshake.svg'
import magnify from '../images/magnify.svg'
import badge from '../images/badge.svg'
import scales from '../images/scales.svg'

import backgroundimg from '../images/jurisdiction-677940.jpg'
import silhouette from '../images/silhouette-3686231.svg'
// import backgroundimg from '../images/erik-mclean-unsplash.jpg'

import PageList from './PageList'
import Results from './Results'

const GradientBgd = styled.div`
height: 100%;
width: 100%;
position: absolute;
object-fit: contain;
object-position: end;
background-repeat: no-repeat;
mask: url(${silhouette});
background: var(--formBackground);
mask-repeat: no-repeat;
mask-position: right center;
display: flex;
`;

const Span = styled.span`
display: block;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    margin-top: 10px;
}
`;

const P = styled.p`
text-align: left;
margin: -7px 0 0; 
font-size: 12px;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    text-align: center;
}
`;


const Home = () => {
    const navigate = useNavigate()
    const [containerRef, isVisible] = useIntersectionObserver(
        {
            root: null,
            rootMargin: "0px",
            threshold: 0.5, // half of item height
        },
        true
    );
    const listContent = [
        { color: '#000', heading: 'Maximize Your Compensation', text: "Equipped with the knowledge and experience required to pursue the absolute maximum possible compensation for your case.", img: handshake },
        { heading: 'Protection Against Unfair Claim Denial', text: "Protects you from insurance company attorneys working to unfairly deny your claim or minimize what you're rightly owed.", img: investigateErrors },
        { heading: 'No Out of Pocket Expenses to Hire & Monetary Motivation to Win', text: 'Many personal injury lawyers only require compensation if you win your case.', img: diamond },
        { heading: 'Accurate Accident Assessment', text: 'Explores available options to ensure you make informed choices', img: scales },
        { heading: 'Free Initial Evaluation', text: 'With free case evaluation and a majority of firms not requiring payment until your case is successfully resolved.', img: magnify },
    ]

    return (
        <PageContentContainer
            column
            display="flex"
            position="relative"
            color="var(--primary)"
            width="100%"
            height="100%"
            margin="calc(var(--navBarHeight) * -1) 0 0"
            background="var(--homeBgd)"
            justifyContent="start"
            alignItems="start"
            flexDirection="row"
            overflow="unset"
            padding="0 0">
            
            {/* <EmbeddedGeographicArea
                hideInput={true}
                subheadingFontSize="14px"
                subheadingMargin="0"
                mobilePadding="var(--navBarHeight) 0 0"
                headingFontSize="1.7rem"
                headingFontSizeMobile="1.2rem"
                heading={<>Connect with an award-winning attorney in our nationwide network.</>}
                // heading={<>Your claim may be worth more than they say.<br /><span style={{ fontSize: '0.7em' }}>Match with an award-winning attorney and find out. Free.</span></>}
                subheading="Select state where the accident occured on the map below."
                subheadingMobile={<><span>Where did the accident occur?</span><br /><span style={{ display: 'block', marginTop: '8px', fontWeight: '300' }}>Enter state or select on map below.</span></>}
            /> */}
            {/* <Flexbox
                minHeight="100vh"
                // minHeight="70vh"
                margin="0"
                // margin="50px 0"
                // mobileMargin="50px 5%"
                width="100%"
                padding="var(--navBarHeight) 0"
                mobilePadding="0 5%"
                justifyContent="center"
                position="relative"
                background="var(--lightGrey)"
                backgroundSize="contain"
                backgroundPosition="center right" flexDirection="column">
                <Flexbox position="absolute" justifyContent="end" width="100%" right="50px">
                    <ThemisAnimated />
                </Flexbox>
                <GridContainer column gap="20px" width="100%"
                    zIndex="1"
                    refId={containerRef}
                    className="homeHeaderContainer"
                    padding="0 50px"
                    paddingMobile="0"
                    justifyContent="center" alignItems="start">

                    <h1
                        className={isVisible ? "visible homeHeader" : "homeHeader"} style={{
                            // color: "#fff",
                            margin: '50px 0 0',
                        }}><span style={{ display: 'block' }}>Attorneys make settlements 3.5x larger.*</span><Span>Which one you hire matters.</Span></h1>
                    <h3 className={isVisible ? "visible homeHeader" : "homeHeader"} style={{
                        // color: "#fff",
                        fontWeight: '400',
                        margin: '0'
                    }}>
                        <span>Our network includes some of the most prestigious law firms in the country.</span>
                        <Span>They don't charge a dime unless you win.</Span></h3>
                    <P className={isVisible ? "visible" : undefined}>*Based on average found, according to a study by the Insurance Research Council in its report titled "Attorney Involvement In Auto Injury Claims"</P>
                    <Button text="Free Attorney Case Review" margin="5px 0" borderRadius="30px" boxShadow="0 0 5px rgba(0,0,0.5)" width="100%" maxWidth="400px" onClick={() => navigate('/form', { state: { form_entry: 'Home Page - Get Started Button' } })}/>
                </GridContainer>
                    <AsSeenOn />
            </Flexbox> */}
            <Hero />
            <Results background="rgba(var(--formBlueRaw),0.35)" />
            <About margin="0" 
            // hideButton={true}
                title="About" />
            <PageList />
            <EmbeddedGeographicArea
                hideInput={false}
                subheadingFontSize="14px"
                subheadingMargin="0"
                mobilePadding="var(--navBarHeight) 0 0"
                headingFontSize="1.7rem"
                headingFontSizeMobile="1.2rem"
                heading={<>Connect with an award-winning attorney in our nationwide network.</>}
                // heading={<>Your claim may be worth more than they say.<br /><span style={{ fontSize: '0.7em' }}>Match with an award-winning attorney and find out. Free.</span></>}
                subheading="Select state where the accident occured on the map below."
                subheadingMobile={<><span>Where did the accident occur?</span><br /><span style={{ display: 'block', marginTop: '8px', fontWeight: '300' }}>Enter state or select on map below.</span></>}
            />
        </PageContentContainer>
    )
}

export default Home