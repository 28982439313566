import styled from 'styled-components'

export const Icon = styled.img`
height: 30px;
width: 30px;
position: relative;
transform: ${({ transform }) => transform};

`;


export const SVG = styled.svg`
height: 30px;
width: 30px;
position: relative;
transform: ${({ transform }) => transform};
.d{
    fill: var(--navArrowFill);
    opacity:var(--navArrowOpacity);
}
.e{
    fill:none;
}
.e,.f{
    stroke: var(--navArrowStroke);
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:7px;
}
.f{
    fill: var(--navArrowCircleFill);
}
`;
