import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// phone number optional, email before number
import Step from "./Step";
import NavArrow from "./NavArrow";
import Flexbox from "../Flexbox";
import ProgressBar from "./ProgressBar";
import PageContentContainer from "../PageContentContainer";
import Button from "../Button";
import FormGrid from "./FormGrid";
import FormContentContainer from "./FormContentContainer";
import anime from 'animejs'
import API from "../../API";
import { useUnload } from '../../hooks/useUnload'
import {
  formatPhoneNumber,
  IconSelectionOption,
  newEnum,
  toTitleCase,
} from "../../utils";
import states from "../../utils/states.js";
import GridItem from "../GridItem";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

function validateName(str) {
  return /\d/.test(str);
}

const Form = ({ }) => {
  const formAbandonment = useRef('')
  const formEntry = useRef('Embedded Header')
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.form_entry) {
      formEntry.current = location.state.form_entry
    }
  }, [])
  const navigate = useNavigate();
  const [serverResponse, setServerResponse] = useState({
    message: "",
    shouldShow: false,
  });
  let previousFormData = window.sessionStorage.getItem("formData");
  const [person, setPerson] = useState("you");

  const injuryIsLossOfLife = (val) => val.toLowerCase() === "loss of life"
  if (previousFormData) {
    previousFormData = JSON.parse(previousFormData);
  }
  const getPrevValue = (name, defaultVal = "") => {
    return previousFormData && previousFormData[name]
      ? previousFormData[name]
      : defaultVal;
  };
  const getPrevEnum = (dependency, enumValues) => { };
  const getStep = (fieldName, _steps = steps) => {
    const stepId = Object.keys(_steps).find((id) => {
      return _steps[id].name === fieldName;
    });
    return stepId;
  };

  const jumpStepOnLoad = (_steps, stepOrder = []) => {
    if (location.state && location.state.jumpToStepName) {
      const {
        state: { jumpToStepName, svgPath, svgPathId, form_entry },
      } = location;
      let geographicAreaDetailedStep = getStep(jumpToStepName, _steps);
      sessionStorage.setItem("svgPath", JSON.stringify({ svgPath, svgPathId }));
      window.history.replaceState({}, document.title);
      if (form_entry) {
        formEntry.current = form_entry
      }
      // if (stateChange)
      if (geographicAreaDetailedStep) return geographicAreaDetailedStep;
      else return getPrevValue("active", stepOrder[0]);
    } else {
      return getPrevValue("active", stepOrder[0]);
    }
  };
  const _data = {
    // person: 'you',
    steps: {
      "step-1": {
        id: "step-1",
        name: "geographicArea",
        type: "geographicArea",
        placeholder: "",
        label: "",
        value: getPrevValue("geographicArea"),
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        stepWidth: "100%",
        isSelected: function (_val) {
          return this.value === _val;
        },
        onChange: function (_val, _stepOrder, _steps, svgPathOfState = null) {
          const dependentStep = Object.keys(_steps).find((step) => {
            let _dep = _steps[step]["dependency"];
            if (!_dep) return false;
            if (typeof _dep === "string")
              return _steps[step]["dependency"] === this.name;
            if (Array.isArray(_dep))
              return _steps[step]["dependency"].find(
                (val) => val === this.name
              );
          });

          if (svgPathOfState) {
            let _svgPath = svgPathOfState.getAttribute("d");
            let _svgPathId = svgPathOfState.getAttribute("id");
            sessionStorage.setItem(
              "svgPath",
              JSON.stringify({ _svgPath, _svgPathId })
            );
          }
          let update;
          if (dependentStep && svgPathOfState) {
            update = _steps[dependentStep].onDependencyUpdate(
              svgPathOfState,
              _val
            );
          }
          if (!update)
            setState((prev) => ({
              ...prev,
              steps: {
                ...prev.steps,
                [this.id]: {
                  ...prev.steps[this.id],
                  value: _val,
                },
              },
            }));
          else if (update.stepId)
            setState((prev) => ({
              ...prev,
              steps: {
                ...prev.steps,
                [this.id]: {
                  ...prev.steps[this.id],
                  value: _val,
                },
                [update.stepId]: {
                  ...prev.steps[update.stepId],
                  ...Object.fromEntries(
                    Object.entries(update).filter((it) => it[0] !== "stepId")
                  ),
                },
              },
            }));
        },
        validate: function (_val = this.value) {
          const _state = states.find(
            (it) => it.name.toLowerCase() === _val.toLowerCase()
          );
          if (_state) return true;
          return false;
        },
      },
      "step-8": {
        id: "step-8",
        name: "geographicAreaDetailed",
        type: "geographicArea",
        placeholder: "",
        label: "",
        input: "",
        stateName: getPrevValue("geographicArea", ""),
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        setInput: function (_val) {
          const thisStep = getStep("geographicAreaDetailed");
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              ["step-8"]: {
                ...prev.steps["step-8"],
                input: _val,
              },
            },
          }));
        },
        initialValue: getPrevValue("geographicAreaDetailed", {
          id: "",
          innerText: "",
          state: "",
          value: "",
        }),
        value: getPrevValue("geographicAreaDetailed", {
          id: "",
          innerText: "",
          state: "",
          value: "",
        }),
        searchSuggestions: [],
        currSelectedState: "",
        onSuggestionsRetrieval: function (values) {
          const thisStep = getStep("geographicAreaDetailed");
          if (thisStep)
            setState((prev) => ({
              ...prev,
              steps: {
                ...prev.steps,
                [thisStep]: {
                  ...prev.steps[thisStep],
                  searchSuggestions: values,
                },
              },
            }));
        },
        onNextStep: function () {
          if (
            typeof this.value === "object" &&
            this.value["innerText"].trim().length > 0
          )
            return true;
          else {
            let stepId = this.id;
            let geographicAreaDetailedInput = document.getElementById(
              "geographicAreaDetailedInput"
            );
            if (
              geographicAreaDetailedInput &&
              geographicAreaDetailedInput["value"].trim().length > 0
            ) {
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  [stepId]: {
                    ...prev.steps[stepId],
                    value: {
                      id: geographicAreaDetailedInput["value"],
                      innerText: geographicAreaDetailedInput["value"],
                      state: this.stateName,
                      value: geographicAreaDetailedInput["value"],
                    },
                  },
                },
              }));
              return true;
            } else {
              setDisabled(true);
              return false;
            }
          }
          return true;
        },
        stepWidth: "100%",
        svgPath: null,
        onChange: function (_val) {
          let v = _val;
          if (typeof v == "object" && !Object.keys(v).includes("state")) {
            v = { ...v, state: this.stateName, ste_name: this.stateName };
          }
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [this.id]: {
                ...prev.steps[this.id],
                value: v,
              },
            },
          }));
        },
        isSelected: function (_val) {
          return this.value.find((it) => it.id === _val.id);
        },
        validate: function (_val = this.value) {
          let stepId = this.id;
          let geographicAreaDetailedInput = document.getElementById(
            "geographicAreaDetailedInput"
          );

          if (
            (typeof _val === "string" && _val.trim() !== "") ||
            (typeof _val === "object" &&
              _val.innerText.trim() !== "")
          ) {
            console.log("from here 1")
            return true;
          } else {
            if (geographicAreaDetailedInput) {
              console.log("from here2")
              if (geographicAreaDetailedInput["value"].trim().length === 0)
                return false;
              if (geographicAreaDetailedInput["value"].trim().length > 0)
                return true;
            } else if (typeof (_val) === 'object'
              && _val['innerText'] && _val['innerText'].trim() !== '' && _val['state'] === getPrevValue('geographicArea', '')) {
              console.log("from here")
              return true
            }
            else return false;
          }
          return false;
        },
        dependency: "geographicArea",
        onDependencyUpdate: function (_path, state = "") {
          let stepId = this.id;
          return { stepId, svgPath: _path, stateName: state };
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [this.id]: {
                ...prev.steps[this.id],
                svgPath: _path,
                stateName: state,
              },
            },
          }));
        },
      },
      "step-9": {
        id: "step-9",
        name: "moreInfo",
        type: "text",
        stepWidth: "100%",
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        value: "",
        // value: getPrevValue('moreInfo', "email"),
        heading: "Tell us what happened",
        subtext: (
          <>
            The next few questions are{" "}
            <strong style={{ textDecoration: "underline" }}>optional</strong>,
            <br />
            but may be helpful to know before your call.
            <br /> <br /> Feel free to skip them if you prefer.
          </>
        ),
        labelInternal: true,
      },
      "step-4": {
        id: "step-4",
        type: "final-step",
        name: "finalStep",
        fieldOrder: ["field-4", "field-3", "field-1", "field-2"],
        validate: function () {
          let isValid = true;
          let keys = this.fieldOrder;
          for (let index = 0; index < keys.length; index++) {
            const element = this.fields[keys[index]];
            if (typeof element["validate"] === "function") {
              let v = element.validate();
              if (!v["value"]) {
                isValid = false;
                // setServerResponse({ message: v['message'] ? v['message'] : '', shouldShow: false })
                break;
              }
            }
          }
          // if (isValid)
          // setServerResponse({ message: '', shouldShow: false })
          return isValid;
        },
        fields: {
          "field-1": {
            id: "field-1",
            name: "phoneNumber",
            placeholder: { phoneNumber: "Phone [i.e. 213-555-5555]", extension: "25" },
            label: "Phone",
            stepWidth: "100%",
            labelInternal: true,
            type: "tel",
            isValid: false,
            shouldShow: false,
            value: getPrevValue("phoneNumber", {
              phoneNumber: "",
              extension: "",
            }),
            validate: function (v = this.value["phoneNumber"]) {
              let isValid = true;
              let val = v.trim();
              if (
                (val.length > 0 &&
                  !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(val)) || val.trim().length === 0
              )
                isValid = false;
              return { value: isValid, message: "Phone Number invalid" };
            },
            onKeyDown: function (event) {
            },
            onChange: function (event, type = "extension") {
              let _val = event.target.value.replace(/\D/g, "");
              let { value: isValid } = this.validate({ phoneNumber: _val });
              if (type === "phoneNumber") {
                let currValue = this.value["phoneNumber"];
              }
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: {
                          ...prev.steps["step-4"].fields[this.id].value,
                          [type]: _val,
                        },
                        isValid,
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-2": {
            id: "field-2",
            name: "additionalDetails",
            type: "textArea",
            placeholder: "Additional Details / Comments",
            label: <>Tell Us About Your Case (Optional)</>,
            // subtextLabel:
            //   "Optional, but the more specific you are about your case details, the better we can connect you.",
            value: getPrevValue("additionalDetails"),
            isValid: true,
            shouldShow: false,
            maxlength: 500,
            rows: 5,
            onChange: function (event) {
              const _val = event.target.value;
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: _val,
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-3": {
            id: "field-3",
            name: "email",
            placeholder: "Email",
            label: "Email",
            value: getPrevValue("email"),
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
              return { value: validateEmail(v), message: "Invalid email" };
            },
            onChange: function (event) {
              const _val = event.target.value;
              let { value: isValid } = this.validate(_val);
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: _val,
                        isValid,
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-4": {
            id: "field-4",
            name: "name",
            placeholder: "Name",
            label: "Name",
            value: getPrevValue("name"),
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
              return {
                value: v.trim() !== "" && !/\d/.test(v),
                message: "Name required",
              };
            },
            onChange: function (event) {
              const _val = event.target.value;
              let { value: isValid } = this.validate(_val);
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-4"]: {
                    ...prev.steps["step-4"],
                    fields: {
                      ...prev.steps["step-4"].fields,
                      [this.id]: {
                        ...prev.steps["step-4"].fields[this.id],
                        value: _val,
                        isValid,
                      },
                    },
                  },
                },
              }));
            },
          },
        },
      },
      "step-11": {
        id: "step-11",
        name: "injurySeverity",
        type: "iconSelection",
        value: getPrevValue("injurySeverity", "N/A"),
        multiSelect: false,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: false,
        storeFullOption: false,
        label: "How severe was the injury?",
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        validate: function () {
          if (typeof (this.value) !== 'string')
            return true
          return (
            this.value.trim() !== "" &&
            this.value.trim() !== "no-answer" &&
            this.value.trim() !== "N/A"
          );
        },
        labelInternal: true,
        get enum() {
          let dependencyValue = getPrevValue("leadIsVictim", "N/A");
          if (dependencyValue.includes("myself")) {
            return newEnum(["Moderate",
              "Significant", "Hospitalization without Surgery", "Surgery Required"]);
          } else
            return newEnum([
              "Moderate",
              "Significant",
              "Hospitalization without Surgery",
              "Surgery Required",
              "Loss of Life",
            ]);
        },

        dependency: "leadIsVictim",
        onDependencyUpdate: function (val) {
          let stepId = this.id;
          let _value = this.value;
          if (val.toLowerCase().includes("myself"))
            return {
              stepId,
              value: _value === "Loss of Life" ? "N/A" : _value,
              enum: newEnum(["Moderate",
                "Significant", "Hospitalization without Surgery", "Surgery Required"]),
            };
          else
            return {
              stepId,
              enum: newEnum([
                "Moderate",
                "Significant",
                "Hospitalization without Surgery",
                "Surgery Required",
                "Loss of Life",
              ]),
            };
        },
        imgProps: {
          height: "60px",
          width: "60px",
          mobileHeight: "50px",
          mobileWidth: "50px",
          minWidthMobile: "50px",
          minHeightMobile: "50px",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      "step-12": {
        id: "step-12",
        name: "injuriesSustained",
        type: "iconSelection",
        defaultValue: 'N/A',
        value: getPrevValue("injuriesSustained", 'N/A'),
        multiSelect: false,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        storeFullOption: true,
        isOptional: false,
        textPosition: 'top',
        label: "What is the most serious injury sustained?",
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        outerContainerProps: {
          width: "100%",
          maxWidth: "600px",
          height: "min-content",
        },
        validate: function (_val = this.value) {
          console.log("validating....", this.value)
          if (this.value.length === 0 || this.value === 'N/A')
            return false
          if (Array.isArray(this.value)) {
            let arrIncludesOther = this.value.find(it => it.inputFieldValue !== undefined)
            if (arrIncludesOther && arrIncludesOther.inputFieldValue.trim().length === 0)
              return false
            if (typeof (this.value) === "object") {
              if (this.value.showInputFieldOnClick === undefined || !this.value.showInputFieldOnClick)
                return true
              else if (this.value.showInputFieldOnClick === true && this.value.inputFieldValue.trim().length === 0)
                return false
              else
                return true
            };
          } else if (typeof this.value === 'object') {
            if (this.value.inputFieldValue !== undefined && this.value.inputFieldValue.trim().length === 0) {
              return false
            }
            else return true
          }
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        labelInternal: true,
        get enum() {
          return newEnum([
            "Anxiety",
            "Back/Neck Pain",
            "Broken Bones",
            "Cuts and Bruises",
            "Headaches",
            "Loss of Limb(s)",
            "Paralysis",
            "Traumatic Brain Injury",
            {
              displayName: "Other",
              id: "other-23",
              showInputFieldOnClick: true,
              placeholder: "Specify Injury",
              get inputFieldValue() {
                let _prev = getPrevValue("injuriesSustained", 'N/A');
                if (Array.isArray(_prev)) {
                  let vArrayIncOther = _prev.find(s => s.id === this.id)
                  if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                    return vArrayIncOther["inputFieldValue"];
                  return '';
                } else if (typeof (_prev) === 'object' && _prev["inputFieldValue"] !== undefined) {
                  return _prev["inputFieldValue"];
                }
                return ''
              },
            }
          ]);
          let dependencyValue = getPrevValue("leadIsVictim", "N/A");
          if (dependencyValue.includes("myself")) {
            return newEnum(["Anxiety",
              "Broken Bones",
              {
                displayName: "Other",
                id: "other-23",
                showInputFieldOnClick: true,
                get inputFieldValue() {
                  let _prev = getPrevValue("injuriesSustained", []);
                  let vArrayIncOther = _prev.find(s => s.id === this.id)
                  if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                    return vArrayIncOther["inputFieldValue"];
                  return '';
                },
                placeholder: "Specify Injury",
              },]);
          } else
            return newEnum([
              "Anxiety",
              "Broken Bones",
              {
                displayName: "Other",
                id: "other-23",
                showInputFieldOnClick: true,
                get inputFieldValue() {
                  let _prev = getPrevValue("injuriesSustained", []);
                  let vArrayIncOther = _prev.find(s => s.id === this.id)
                  if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                    return vArrayIncOther["inputFieldValue"];
                  return '';
                },
                placeholder: "Specify Injury",
              },
            ]);
        },

        dependency: ["injurySeverity"],
        // dependency: ["leadIsVictim", "injurySeverity"],
        onDependencyUpdate: function (val, dependency) {
          let stepId = this.id;
          let _value = this.value;
          if (dependency === "injurySeverity") {
            return {
              stepOrderChange: {
                action: injuryIsLossOfLife(val) ? "remove" : "add",
                stepId,
              },
            };
          }
          else
            return {
              stepId,
              enum: newEnum([
                "Anxiety",
                "Back/Neck Pain",
                "Broken Bones",
                "Cuts and Bruises",
                "Headaches",
                "Loss of Limb(s)",
                "Paralysis",
                "Traumatic Brain Injury",
                {
                  displayName: "Other",
                  id: "other-23",
                  showInputFieldOnClick: true,
                  placeholder: "Specify Injury",
                  get inputFieldValue() {
                    let _prev = getPrevValue("injuriesSustained", 'N/A');
                    if (Array.isArray(_prev)) {
                      let vArrayIncOther = _prev.find(s => s.id === this.id)
                      if (vArrayIncOther && vArrayIncOther["inputFieldValue"] !== undefined)
                        return vArrayIncOther["inputFieldValue"];
                      return '';
                    } else if (typeof (_prev) === 'object' && _prev["inputFieldValue"] !== undefined) {
                      return _prev["inputFieldValue"];
                    }
                    return ''
                  },
                }
              ]),
            };
        },
        imgProps: {
          height: "60px",
          width: "60px",
          mobileHeight: "50px",
          mobileWidth: "50px",
          minWidthMobile: "50px",
          minHeightMobile: "50px",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      "step-3": {
        id: "step-3",
        name: "accidentYear",
        type: "iconSelection",
        labelInternal: true,
        multiSelect: false,
        itemsPerRow: 5,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: false,
        labelInternal: true,
        outerContainerProps: { width: "100%" },
        tileProps: {
          // maxWidth: "250px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
        validate: function () {
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        label: "When did the accident occur?",
        value: getPrevValue("accidentYear", "N/A"),
        get sortedOptions() {
          return Object.keys(this.enum).sort(
            (a, b) => b.replace(/[^\d]+/g, "") - a.replace(/[^\d]+/g, "")
          );
          //Number(a.replace(/[^\d.-]+/g, ''))
        },
        get enum() {
          return newEnum(
            (function (x) {
              return x.map((it, index) => {
                if (index < x.length - 1) {
                  return (new Date().getFullYear() - index).toString();
                } else return `${new Date().getFullYear() - index} Or Earlier`;
              });
            })(new Array(4).fill(0))
          );
        },
      },
      "step-21": {
        id: "step-21",
        name: "lostWages",
        type: "iconSelection",
        value: getPrevValue("lostWages", "N/A"),
        multiSelect: false,
        itemsPerRow: 2,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: true,
        label: "Did the accident result in lost wages or income? (Optional)",
        labelInternal: true,
        enum: {
          "yes-21": new IconSelectionOption("Yes", null, "yes-21"),
          "no-21": new IconSelectionOption("No", null, "no-21"),
        },
        outerContainerProps: { width: "100%" },
        tileProps: {
          maxWidth: "250px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
      },
      "step-23": {
        id: "step-23",
        name: "medicalBills",
        type: "iconSelection",
        value: getPrevValue("medicalBills", "N/A"),
        multiSelect: false,
        itemsPerRow: 2,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: true,
        // validate: function () {
        //   return (
        //     this.value !== "N/A" &&
        //     this.value.trim() !== "" &&
        //     this.value !== "no-answer"
        //   );
        // },
        label: "Are there medical bills as a result of the injury? (Optional)",
        labelInternal: true,
        enum: {
          "yes-23": new IconSelectionOption("Yes", null, "yes-23"),
          "no-23": new IconSelectionOption("No", null, "no-23"),
        },
        outerContainerProps: { width: "100%" },
        tileProps: {
          maxWidth: "250px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
      },
      "step-24": {
        id: "step-24",
        name: "alreadyHasLegalRepresentation",
        type: "iconSelection",
        value: getPrevValue("alreadyHasLegalRepresentation", "N/A"),
        multiSelect: false,
        itemsPerRow: 2,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: false,
        validate: function () {
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        label: "Is an attorney already representing you in this case?",
        labelInternal: true,
        enum: {
          "yes-24": new IconSelectionOption("Yes", null, "yes-24"),
          "no-24": new IconSelectionOption("No", null, "no-24"),
        },
        outerContainerProps: { width: "100%" },
        tileProps: {
          maxWidth: "250px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
      },
      "step-16": {
        id: "step-16",
        name: "policeReportFiled",
        type: "iconSelection",
        value: getPrevValue("policeReportFiled", "N/A"),
        multiSelect: false,
        itemsPerRow: 3,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: true,
        label: "Was a police report filed? (Optional)",
        labelInternal: true,
        outerContainerProps: { width: "100%" },
        tileProps: {
          maxWidth: "250px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
        // sectionImage: policeCar,
        // sectionImageProps: { width: "90%", height: "auto", maxWidth: "300px" },
        enum: {
          // maybe distinguish between no test and refused test
          "yes-4": new IconSelectionOption("Yes", null, "yes-4"),
          "no-4": new IconSelectionOption("No", null, "no-4"),
          "not-sure-4": new IconSelectionOption("Not Sure", null, "not-sure-4"),
        },
      },
      "step-19": {
        id: "step-19",
        name: "leadIsVictim",
        type: "iconSelection",
        value: getPrevValue("leadIsVictim", "N/A"),
        multiSelect: false,
        itemsPerRow: 3,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: false,
        validate: function () {
          return (
            this.value !== "N/A" &&
            this.value.trim() !== "" &&
            this.value !== "no-answer"
          );
        },
        label: "Are you filling this out for yourself or someone else?",
        labelInternal: true,
        enum: newEnum(['Myself', 'Someone Else']),
        outerContainerProps: { width: "100%" },
        tileProps: {
          maxWidth: "250px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
      },
      "step-name": {
        id: "step-name",
        name: "nameOfInjuredPerson",
        type: "input",
        labelProps: { justifySelf: "start", margin: "0" },
        placeholder: "First & Last Name",
        label: "Who was injured?",
        // labelInternal: true,
        value: getPrevValue("nameOfInjuredPerson", ""),
        dependency: "leadIsVictim",
        onDependencyUpdate: function (val, dependency = "") {
          let stepId = this.id;
          return {
            stepOrderChange: {
              action: val.toLowerCase().includes("myself") ? "remove" : "add",
              stepId,
            },
          };
        },
        validate: function () {
          return !/\d/.test(this.value) && this.value !== "";
        },
      },
      "step-18": {
        id: "step-18",
        name: "ongoingCareRequired",
        type: "iconSelection",
        value: getPrevValue("ongoingCareRequired", "N/A"),
        multiSelect: false,
        itemsPerRow: 3,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: true,
        get label() {
          let dependencyValue = getPrevValue("leadIsVictim", "N/A");
          if (dependencyValue.toLowerCase().includes("myself"))
            return "Do you require ongoing care? (Optional)";
          else {
            let depValue2 = getPrevValue("nameOfInjuredPerson", "");
            if (depValue2.length)
              return `Does ${depValue2} require ongoing care? (Optional)`;
            else return "Do you require ongoing care? (Optional)";
          }
        },
        labelInternal: true,
        enum: {
          // maybe distinguish between no test and refused test
          "yes-18": new IconSelectionOption("Yes", null, "yes-18"),
          "no-18": new IconSelectionOption("No", null, "no-18"),
          "not-sure-18": new IconSelectionOption(
            "Not Sure",
            null,
            "not-sure-18"
          ),
        },
        dependency: ["leadIsVictim", "injurySeverity", "nameOfInjuredPerson"],
        onDependencyUpdate: function (val, dependency = "") {
          let stepId = this.id;
          let label = this.label;
          if (dependency === "injurySeverity") {
            return {
              stepOrderChange: {
                action: val.toLowerCase() === "loss of life" ? "remove" : "add",
                stepId,
              },
            };
          }
          if (dependency === "nameOfInjuredPerson" && val.trim().length > 0) {
            return {
              stepId,
              label: `Does ${val} require ongoing care? (Optional)`,
            };
          }
          if (
            dependency === "leadIsVictim" &&
            val.toLowerCase().includes("myself")
          ) {
            return {
              stepId,
              label: "Do you require ongoing care? (Optional)",
            };
          } else {
            return {
              stepId,
              additionalValueChecks: ["nameOfInjuredPerson"],
              update: function ({ nameOfInjuredPerson = "" }) {
                if (nameOfInjuredPerson !== "")
                  return {
                    label: `Does ${nameOfInjuredPerson} require ongoing care? (Optional)`,
                  };
                else
                  return {
                    label: `Do you require ongoing care? (Optional)`,
                  };
              },
            };
          }
        },

        outerContainerProps: { width: "100%" },
        tileProps: {
          maxWidth: "250px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
      },
      "step-17": {
        id: "step-17",
        name: "responsibleParty",
        type: "iconSelection",
        value: getPrevValue("responsibleParty", []),
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: false,
        valueArrayKey: 'displayName',
        valueIsSelectedKey: 'displayName',
        label: "Who was at fault?",
        labelInternal: true,
        validate: function () {
          return this.value.length > 0;
        },
        get enum() {
          return {
            lead: new IconSelectionOption(
              "Myself/Person I'm Representing",
              null,
              "lead"
            ),
            "another-person": new IconSelectionOption("Another Person"),
            "a-business": new IconSelectionOption("A Business"),
            "not-sure-21": new IconSelectionOption(
              "Not Sure",
              null,
              "not-sure-21"
            ),
          };
        },
        // getEnum: {},
        outerContainerProps: {
          width: "100%",
          maxWidth: "700px",
          height: "min-content",
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
          height: "min-content",
        },
      },
      submit: {
        id: "submit",
        name: "submit",
      },
    },

    stepOrder: getPrevValue("stepOrder", [
      "step-1",
      "step-8",
      "step-17",
      "step-19",
      "step-name",
      "step-11",
      'step-12',
      "step-3",
      "step-9",
      "step-21",
      "step-23",
      "step-18",
      // "step-16",
      // "step-24",
      "step-4",
      "submit",
    ]),
    get initialStepOrder() {
      return [
        "step-1",
        "step-8",
        "step-17",
        "step-19",
        "step-name",
        "step-11",
        'step-12',
        "step-3",
        "step-9",
        "step-21",
        "step-23",
        "step-18",
        "step-4",
        "submit",
      ];
    },
    get active() {
      return jumpStepOnLoad(this.steps, this.stepOrder);
      // return getPrevValue("active",
      //     jumpStepOnLoad(this.steps, this.stepOrder)
      // )
    },
    get party() {
      return "You";
    },
    startTime: Date.now(),
    endTime: null,
  };
  const [state, setState] = useState(_data);
  const { steps, active, stepOrder, initialStepOrder } = state;
  const [activeNew, setActiveNew] = useState(active)
  const [disabled, setDisabled] = useState(true);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const initialRender = useRef(true);
  const handleChange =
    (name, _value = null) =>
      (event) => {
        const stepId = getStep(name);
        const step = steps[stepId];
        if (name === "geographicArea" && _value) {
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [stepId]: {
                ...prev.steps[stepId],
                value: [...prev.steps[stepId].value, _value],
              },
            },
          }));
        } else if (name !== "phoneNumber" && event.target) {
          let value = event.target.value;

          //////
          const dependentSteps = Object.keys(steps).filter((_st) => {
            let _dep = steps[_st]["dependency"];
            if (!_dep) return false;
            if (typeof _dep === "string")
              return steps[_st]["dependency"] === step["name"];
            if (Array.isArray(_dep))
              return steps[_st]["dependency"].filter(
                (val) => val === step["name"]
              );
          });
          let dependentStepsObject = {};
          let newSteps = steps;
          /**
           * @type {Array}
           */
          let newStepOrder = stepOrder;
          if (dependentSteps) {
            for (let i = 0; i < dependentSteps.length; i++) {
              let dependentStep = dependentSteps[i];
              let result = steps[dependentStep].onDependencyUpdate(
                value,
                step["name"]
              );
              if (
                result["stepId"] !== undefined ||
                result["additionalValueChecks"] !== undefined
              ) {
                let updateFields = Object.fromEntries(
                  Object.entries(result).filter(
                    (it) =>
                      it[0] !== "stepId" &&
                      it[0] !== "additionalValueChecks" &&
                      it[0] !== "update"
                  )
                );
                if (Array.isArray(result["additionalValueChecks"])) {
                  let vals = {};
                  for (
                    let index = 0;
                    index < result["additionalValueChecks"].length;
                    index++
                  ) {
                    const stepName = result["additionalValueChecks"][index];
                    const additionalCheckStepId = getStep(stepName, steps);
                    if (additionalCheckStepId) {
                      let additionalCheckStep = steps[additionalCheckStepId];

                      vals[stepName] = additionalCheckStep["value"];
                    }
                  }

                  updateFields = {
                    ...updateFields,
                    ...result["update"]({ ...vals }),
                  };
                }
                newSteps[dependentStep] = {
                  ...newSteps[dependentStep],
                  ...updateFields,
                };
              }
              if (result["stepOrderChange"] !== undefined) {
                let {
                  stepOrderChange: { action, stepId: depStepId },
                } = result;
                if (
                  action === "add" &&
                  !newStepOrder.find((el) => el === depStepId)
                ) {
                  let insertionIndex = initialStepOrder.findIndex(
                    (s) => s === depStepId
                  );
                  if (insertionIndex >= 0)
                    newStepOrder.splice(insertionIndex, 0, depStepId);
                } else if (action !== "add") {
                  newStepOrder = newStepOrder.filter((it) => it !== depStepId);
                }
              }
            }
          }
          setState(function (prev) {
            return {
              ...prev,
              stepOrder: newStepOrder,
              steps: {
                ...prev.steps,
                ...newSteps,
                [stepId]: {
                  ...prev.steps[stepId],
                  value: value,
                },
              },
            };
          });
          //////
          // setState((prev) => ({
          //   ...prev,
          //   steps: {
          //     ...prev.steps,
          //     [stepId]: {
          //       ...prev.steps[stepId],
          //       value,
          //     },
          //   },
          // }));
        }
      };

  const formLength = Object.keys(stepOrder).length;
  const formatData = (flatten = true) => {
    const data = {};
    data.startTime = state["startTime"];
    data.endTime = Date.now();
    stepOrder.forEach((stepId) => {
      if (stepId === "submit") return;

      const step = steps[stepId];

      if (!flatten) {
        data[step.name] = step.value;
        if (step.name === "finalStep") {
          Object.keys(step["fields"]).forEach((fieldKey) => {
            const field = step.fields[fieldKey];
            data[field.name] = field.value;
          });
        }
        return;
      }
      if (step.name === "geographicArea") data["ste_name"] = step.value;
      if (step.name === "geographicAreaDetailed") {
        data["city"] = step.value.value;
        data["pla_code"] = step.value.pla_code;
        // data['svgPath'] = step.svgPath
        // data["ste_name"] = step.value.state
        return;
      }
      if (step.name === "testType") {
        data[step.name] = step.value.value;
        // data["ste_name"] = step.value.state
        return;
      }
      if (step.name === "finalStep") {
        Object.keys(step["fields"]).forEach((fieldKey) => {
          const field = step.fields[fieldKey];
          data[field.name] = field.value;
        });
      }
      if (step.name === "injuriesSustained") {
        if (Array.isArray(step.value))
          data[step.name] = step.value.map((it) => {
            let val = it[step['valueArrayKey'] ? step['valueArrayKey'] : 'id'];
            if (val === 'Other') {
              let otherObj = step['value'].find(ob => ob.displayName === 'Other')
              if (otherObj && otherObj['inputFieldValue']) {
                return (handleStepValues(otherObj['inputFieldValue'], step))
              }
            }
            if (it)
              return (handleStepValues(val, step))
          });
        else {
          let val = step.value[step['valueArrayKey'] ? step['valueArrayKey'] : 'id'];
          let x = '';
          if (val === 'Other') {
            let otherObj = step['value']
            if (otherObj && otherObj['inputFieldValue']) {
              x = (handleStepValues(otherObj['inputFieldValue'], step))
            }
          }
          else
            x = (handleStepValues(val, step))
          data[step.name] = x
        }
        return;
      }
      else if (Array.isArray(step.value))
        data[step.name] = step.value.map((it) => handleStepValues(it, step));
      else if (typeof step.value === "string")
        data[step.name] = handleStepValues(step.value, step);
      else data[step.name] = step.value;
    });
    return data;
  };

  const handleStepValues = (it, step) => {
    if (it["id"]) {
      if (/-\d/.test(it["id"])) {
        let displayName =
          step.enum[it.id]["text"] || step.enum[it.id]["displayName"];
        return {
          ...it,
          id: it["id"].split(/-\d/)[0],
          displayName
          // displayName: toTitleCase(displayName.replace(/-/g, " ")),
        };
      } else {
        return it;
      }
    } else {
      return (step['valueArrayKey'] || step['type'] === 'input') ? it : toTitleCase(it.split(/-\d/)[0].replace(/-/g, " "), true);
    }
  };

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url !== undefined) window.location = url;
    };
    if (window.gtag)
      window.gtag("event", "conversion", {
        send_to: "AW-11046786106/FN4rCMrE-IYYELqowpMp",
        event_callback: callback,
      });
    return false;
  }

  const submitForm = async () => {
    try {
      const data = formatData();
      const resData = await API.submitForm(data);
      if (window.sessionStorage.getItem("formData"))
        sessionStorage.removeItem("formData");
      if (resData) {
        const { googleCount } = resData
        // if (googleCount) {
        if (window.dataLayer && Array.isArray(window.dataLayer)) {
          let ev = {
            'event': 'form_submission',
            'form_id': 'PRIMARY FORM',
            'form_entry': formEntry.current
          }
          if (googleCount)
            ev['isCAC'] = true
          window.dataLayer.push(ev)
        }
      }
      navigate("/success");
    } catch (error) {
      console.log("error", error)
      if (error.response["data"]) {
        const {
          response: {
            status,
            data: { msg },
          },
        } = error;
        navigate("/error", {
          state: { error: { status, msg }, ref: "form-submission" },
        });
      } else {
        navigate("/error", {
          state: {
            error: { status: error.response.status, msg: "An error occurred" },
            ref: "form-submission",
          },
        });
      }
    }
  };

  function hasPhoneNumber() {
    const phoneNumberStep = getStep("phoneNumber");
    if (!phoneNumberStep) return false;
    if (steps[phoneNumberStep].value === "") return false;
    return true;
  }

  useUnload((e) => {

    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      if (window.dataLayer.find(it => it['event'] === 'form_submission') === undefined) {
        window.dataLayer.push({
          'event': 'form_abandonment',
          'eventCategory': 'Form Abandonment',
          'eventAction': formAbandonment.current
        })
      }
    }
  })

  const getSelectedStates = () => {
    const stepId = getStep("geographicArea");
    if (stepId) {
      let _val = steps[stepId].value;
      return steps[stepId].value;
    }
  };

  useEffect(() => {
    const onCarriageReturn = (e) => {
      // nextStep()
      if (e.code === 'Enter') {
        console.log("enter key pressed")
        console.log(steps[active])
        nextStep()
      }
    }

    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    validateActiveStep();
    sessionStorage.setItem(
      "formData",
      JSON.stringify({ ...formatData(false), active, stepOrder })
    );
    // window.addEventListener('keydown', onCarriageReturn)
    // function cleanup() {
    //   window.removeEventListener("keydown", onCarriageReturn);
    // }
    // window.addEventListener('keyup', cleanup, { once: true })
    // return () => {
    //   window.removeEventListener('keydown', onCarriageReturn)
    // }

  }, [state]);
  useEffect(() => {
    if (Array.isArray(window.dataLayer)) {
      if (!formAbandonment.current.includes(steps[active].name)) {
        if (formAbandonment.current === '') {
          formAbandonment.current = steps[active].name
        }
        else
          formAbandonment.current = formAbandonment.current + ` > ${steps[active].name}`
        window.dataLayer.push({
          'event': 'form_engagement',
          'eventCategory': 'Form Engagement',
          'eventAction': formAbandonment.current
        });
      }
    }
  }, [active])
  // useEffect(() => {
  //   setActiveNew(active)
  //   if (!isLastStep()) {
  //     let x = document.querySelector('.content')
  //     if (x) {
  //       x.classList.add('changingStep')
  //       x.style.overflow = 'hidden'
  //     }
  //     setTimeout(() => {
  //       // setActiveNew(active)
  //       let x = document.querySelector('.content')
  //       if (x) {
  //         x.classList.remove('changingStep')
  //         x.style.overflow = 'visible'
  //       }
  //     }, 800)
  //   }

  // }, [active])

  console.log(steps[active])
  const validateActiveStep = () => {
    if (steps[active]) {
      const valid = steps[active].validate
        ? steps[active].validate(steps[active].value)
        : true;
      if (valid) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };
  const getNextStepKey = (_stepOrder = stepOrder) => {
    const activeIndex = _stepOrder.findIndex((stepId) => stepId === active);
    return activeIndex + 1;
  };
  async function nextStep() {
    console.log("next step", disabled)
    const nextStep = getNextStepKey();
    if (steps[active].onNextStep) {
      let res = steps[active].onNextStep();
      if (typeof (res) === 'boolean' && res === false) return;
    } else if (disabled) return;
    if (isLastStep()) {
      setState((prev) => ({ ...prev, active: stepOrder[nextStep] }));
      await submitForm();
    } else if (nextStep < formLength) {
      // if (!skipSetter)
      setState((prev) => ({ ...prev, active: stepOrder[nextStep] }));
      // else return stepOrder[nextStep]
    }

  }
  const getActiveIndex = (_steps = stepOrder) =>
    _steps.findIndex((stepId) => stepId === active);
  const isFirstStep = () => getActiveIndex() === 0;
  const isLastStep = () => getActiveIndex() + 1 === formLength - 1;
  const isSubmitStep = () => getActiveIndex() + 1 === formLength;


  const getStepIndex = (_stepId, _steps = stepOrder) =>
    _steps.findIndex((stepId) => stepId === _stepId);

  const getPrevStepIndex = (returnStepId = false) => {
    const _steps = Object.keys(steps);
    const activeIndex = stepOrder.findIndex((stepId) => stepId === active); //getActiveIndex(_steps)
    const stepIndex = activeIndex - 1;
    if (!returnStepId)
      return stepIndex
    else
      return stepOrder[stepIndex]
  }

  const getNextStepIndex = (returnStepId = false) => {
    const _steps = Object.keys(steps);
    const activeIndex = stepOrder.findIndex((stepId) => stepId === active); //getActiveIndex(_steps)
    const stepIndex = activeIndex + 1;
    if (!returnStepId)
      return stepIndex
    else
      return stepOrder[stepIndex]
  }
  const optionalStepsSection = getActiveIndex() >= getStepIndex("step-9") && !isLastStep() && !isSubmitStep()
  const skipToLastStep = () => {
    let jumpToIndex = stepOrder.length - 2
    if (optionalStepsSection)
      setState(prev => ({ ...prev, active: stepOrder[jumpToIndex] }))
  }

  const backStep = () => {
    let stepIndex = getPrevStepIndex()
    if (stepIndex >= 0)
      setState((prev) => ({ ...prev, active: stepOrder[stepIndex] }));
  };

  const onKeyDown = (name) => (e) => {
    const { key } = e;
    if (key === "Enter") {
      nextStep();
    } else {
      const stepId = getStep(name);
      let value = key;
      if (name === "phoneNumber") {
        const { fields } = steps[active];
        const matchingField = Object.keys(fields).find((key) => {
          const field = fields[key];

          return field.name === name;
        });

        if (matchingField) {
          let _val = steps[active].fields[matchingField].value["phoneNumber"];

          if (key === "Backspace" && isKeyReleased) {
            const _noDash = _val.replace(/\D/g, "");
            _val = _noDash.slice(0, _noDash.length - 1);
            _val = formatPhoneNumber(_val);
          } else {
            _val = _val + key.replace(/\D/g, "");
            const _noDash = _val.replace(/\D/g, "");
            _val = formatPhoneNumber(_noDash);
          }
          let { value: isValid } =
            steps[active].fields[matchingField].validate(_val);
          if (name === "phoneNumber")
            setState((prev) => ({
              ...prev,
              steps: {
                ...prev.steps,
                [active]: {
                  ...prev.steps[active],
                  fields: {
                    ...prev.steps[active].fields,
                    [matchingField]: {
                      ...prev.steps[active].fields[matchingField],
                      value: {
                        ...prev.steps[active].fields[matchingField].value,
                        phoneNumber: _val,
                      },
                      isValid,
                    },
                  },
                },
              },
            }));
        }

        // }
      }
      // setIsKeyReleased(false);
    }
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };



  const filterDuplicatesOut = (arr, val) => {
    if (typeof val === "string") {
      return arr.reduce((a, b) => {
        if (a.includes(b)) return a.filter((it) => it !== b);
        else a.push(b);
        return a;
      }, []);
    } else {
      return arr.reduce((a, b, index, array) => {
        if (a.findIndex((el) => el["id"] === b["id"]) >= 0)
          return a.filter((it) => it["id"] !== b["id"]);
        else if (!b["id"].includes("not-sure")) a.push(b);
        return a;
      }, []);
    }
  };
  const addOrRemoveGeoDetailedIfDC = (_val, newStepOrder, geoStepId, currStepId) => {
    if ((new RegExp('District of Columbia', "i")).test(_val)) {
      newStepOrder = newStepOrder.filter(step => step !== geoStepId)
    } else {
      if (!newStepOrder.includes(geoStepId) && initialStepOrder.includes(geoStepId))
        newStepOrder.splice(newStepOrder.findIndex(step => step === currStepId) + 1, 0, geoStepId)
    }
    return newStepOrder
  }

  const findStepDependency = (parentStepName) => {
    return Object.keys(steps).find((step) => {
      let _dep = steps[step]["dependency"];
      if (!_dep) return false;
      if (typeof _dep === "string")
        return steps[step]["dependency"] === parentStepName;
      if (Array.isArray(_dep))
        return steps[step]["dependency"].find(
          (val) => val === parentStepName
        );
    });
  }

  const setSvgPath = (svgPathOfState) => {
    let _svgPath = svgPathOfState.getAttribute("d");
    let _svgPathId = svgPathOfState.getAttribute("id");
    sessionStorage.setItem(
      "svgPath",
      JSON.stringify({ _svgPath, _svgPathId })
    );
  }

  const geographicAreaOnChange = (_val, svgPathOfState) => {
    let update;
    const currStepId = getStep('geographicArea')
    const step = steps[currStepId]
    if (!step)
      return
    const geoStepId = getStep('geographicAreaDetailed')
    let multiSelect = step.multiSelect || false;
    let _active = active;
    let newStepOrder = stepOrder
    newStepOrder = addOrRemoveGeoDetailedIfDC(_val, newStepOrder, geoStepId, currStepId)
    if (svgPathOfState)
      setSvgPath(svgPathOfState)
    const dependentStep = findStepDependency(step['name'])

    if (dependentStep && svgPathOfState) {
      update = steps[dependentStep].onDependencyUpdate(
        svgPathOfState,
        _val
      );
    }
    if (!multiSelect && step.validate(_val)) {
      _active = newStepOrder[getNextStepKey(newStepOrder)];
    }
    if (!update) {
      setState((prev) => ({
        ...prev,
        stepOrder: newStepOrder,
        active: _active,
        steps: {
          ...prev.steps,
          [currStepId]: {
            ...prev.steps[currStepId],
            value: _val,
          },
        },
      }));
    }
    else if (update.stepId) {
      setState((prev) => ({
        ...prev,
        stepOrder: newStepOrder,
        active: _active,
        steps: {
          ...prev.steps,
          [currStepId]: {
            ...prev.steps[currStepId],
            value: _val,
          },
          [update.stepId]: {
            ...prev.steps[update.stepId],
            ...Object.fromEntries(
              Object.entries(update).filter((it) => it[0] !== "stepId")
            ),
          },
        },
      }));
    }

  }
  const geographicAreaDetailedOnChange = (_val) => {
    const stepId = getStep('geographicAreaDetailed')
    const step = steps[stepId]
    if (!step)
      return
    let multiSelect = step.multiSelect || false;
    let _active = active;
    let newStepOrder = stepOrder
    let v = _val;
    if (typeof v == "object" && !Object.keys(v).includes("state")) {
      v = { ...v, state: step.stateName, ste_name: step.stateName };
    }
    if (!multiSelect && step.validate(_val)) {
      _active = newStepOrder[getNextStepKey(newStepOrder)];
    }
    setState((prev) => ({
      ...prev,
      active: _active,
      steps: {
        ...prev.steps,
        [step.id]: {
          ...prev.steps[step.id],
          value: v,
        },
      },
    }));
  }
  /*
  if storeFullOption is true for the step, option is passed as an object else it's a string
  */
  const onInputFieldChange = (stepId, enumId, value) => {
    if (!steps[stepId]) return;
    if (
      steps[stepId].inputFieldValue ||
      steps[stepId].storeFullOption === false
    )
      setState(function (prev) {
        return {
          ...prev,
          steps: {
            ...prev.steps,
            [stepId]: {
              ...prev.steps[stepId],
              inputFieldValue: value,
            },
          },
        };
      });
    else if (steps[stepId]["enum"][enumId].inputFieldValue !== undefined) {

      let multiSelect = steps[stepId].multiSelect || false;
      let v = value
      setState(function (prev) {
        return {
          ...prev,
          steps: {
            ...prev.steps,
            [stepId]: {
              ...prev.steps[stepId],
              enum: {
                ...prev.steps[stepId]["enum"],
                [enumId]: {
                  ...prev.steps[stepId]["enum"][enumId],
                  inputFieldValue: value,
                },
              },
              value: !multiSelect ? {
                ...prev.steps[stepId].value,
                inputFieldValue: value,
              } : prev.steps[stepId].value.map(it => {
                if (it.inputFieldValue !== undefined) {
                  return ({ ...it, inputFieldValue: v })
                }
                else return it
              }),
              // inputFieldValue: value
            },
          },
        };
      });
    }
  };
  const iconSelectionOnChange = (stepId, option) => {
    const step = steps[stepId];
    if (step) {
      let storeFullOption = step.storeFullOption || false;
      let multiSelect = step.multiSelect || false;
      let currentValue = step.value;
      let newValue, arrIncludesNotSure, newValueIsNotSure;
      let additionalKeys = step.additionalKeys || [];
      let _additionalOnClick = step["onClick"] || undefined;
      let hasTextField = typeof (option) === 'object' && option.inputFieldValue !== undefined
      if (storeFullOption) {
        newValue = (({ id, displayName, ...option }) => ({
          id,
          displayName,
          ...option,
          ...Object.fromEntries(additionalKeys.map((it) => [it, option[it]])),
        }))(option);
      } else if (typeof option !== "string") {
        newValue = option[step['valueArrayKey'] ? step['valueArrayKey'] : "id"];
      } else {
        newValue = option
      }
      if (multiSelect) {
        arrIncludesNotSure =
          currentValue.findIndex((el) => {
            let v = el['id'] ? el['id'] : el
            return (v.replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase().includes('not sure'))
          }
          ) >= 0;
        newValueIsNotSure = newValue["id"]
          ? newValue["id"].includes("not-sure")
          : newValue.replace(/\d/g, "").replace(/-/g, " ").trim().toLowerCase().includes('not sure');
        if (!arrIncludesNotSure && newValueIsNotSure) newValue = [newValue];
        else {
          newValue = filterDuplicatesOut([...currentValue, newValue], newValue);
        }
      } else {
        newValue = option;
      }
      const dependentSteps = getDependentSteps(step, steps);
      let dependentStepsObject = {};
      let newSteps = steps;
      /**
       * @type {Array}
       */
      let newStepOrder = stepOrder;
      if (dependentSteps) {
        for (let i = 0; i < dependentSteps.length; i++) {
          let dependentStep = dependentSteps[i];
          let result = steps[dependentStep].onDependencyUpdate(
            option,
            step["name"]
          );

          if (
            result["stepId"] !== undefined ||
            result["additionalValueChecks"] !== undefined
          ) {
            let updateFields = Object.fromEntries(
              Object.entries(result).filter(
                (it) =>
                  it[0] !== "stepId" &&
                  it[0] !== "additionalValueChecks" &&
                  it[0] !== "update"
              )
            );
            if (Array.isArray(result["additionalValueChecks"])) {
              let vals = {};
              for (
                let index = 0;
                index < result["additionalValueChecks"].length;
                index++
              ) {
                const stepName = result["additionalValueChecks"][index];
                const additionalCheckStepId = getStep(stepName, steps);
                if (additionalCheckStepId) {
                  let additionalCheckStep = steps[additionalCheckStepId];
                  vals[stepName] = additionalCheckStep["value"];
                }
              }
              updateFields = {
                ...updateFields,
                ...result["update"]({ ...vals }),
              };
            }
            newSteps[dependentStep] = {
              ...newSteps[dependentStep],
              ...updateFields,
            };
          }
          if (result["stepOrderChange"] !== undefined) {
            let {
              stepOrderChange: { action, stepId: depStepId },
            } = result;
            if (
              action === "add" &&
              !newStepOrder.find((el) => el === depStepId)
            ) {
              let insertionIndex = initialStepOrder.findIndex(
                (s) => s === depStepId
              );
              if (insertionIndex >= 0)
                newStepOrder.splice(insertionIndex, 0, depStepId);
            } else if (action !== "add")
              newStepOrder = newStepOrder.filter((it) => it !== depStepId);
          }
        }
      }
      let _active = active;
      if (!multiSelect && !hasTextField) {
        _active = newStepOrder[getNextStepKey(newStepOrder)];
      }
      setState(function (prev) {
        return {
          ...prev,
          stepOrder: newStepOrder,
          active: _active,
          steps: {
            ...prev.steps,
            ...newSteps,
            [stepId]: {
              ...prev.steps[stepId],
              value: newValue,
            },
          },
        };
      });
    }
  };

  function getDependentSteps(activeStep = steps["active"], _steps = steps) {
    return Object.keys(_steps).filter((_st) => {
      let _dep = _steps[_st]["dependency"];
      if (!_dep) return false;
      if (typeof _dep === "string")
        return _steps[_st]["dependency"] === activeStep["name"];
      if (Array.isArray(_dep))
        return _steps[_st]["dependency"].filter(
          (val) => val === activeStep["name"]
        );
    });
  }
  return (
    <PageContentContainer
      marginTop="0"
      position="absolute"
      top="0"
      color="var(--primary)"
      height="100vh"
      background="var(--formBackground)"
      justifyContent="start"
      alignItems="start"
      flexDirection="row"
      padding="0 0 50px"
    >
      <ProgressBar
        active={active}
        formLength={formLength - 1}
        getActiveIndex={getActiveIndex}
        isLastStep={isLastStep()}
      />
      <FormGrid stepType={steps[active].type}>
        {
          optionalStepsSection &&
          <GridItem
            row="back-start"
            alignSelfMobile="center"
            position="fixed"
            alignSelf="end"
            zIndex="1"
            bottom="50px"
            bottomMobile="unset"
            left="50%"
            transform="translateX(-50%)"
            justifySelf="center" positionMobile="absolute" col="1 / -1"><Button
              // boxShadow="0 0 4px rgba(0,0,0,0.5)" 
              border="var(--skipToEndBorder)"
              // rightMobile="5%" 
              widthMobile="min-content"
              fontSize="11px"
              background="var(--skipToEndBgd)"
              fontWeight="400"
              fontWeightHover="500"
              color="var(--skipToEndColor)"
              borderRadius="50px"
              textTransform="uppercase"
              onClick={skipToLastStep}
              padding="5px 15px" text="Skip to End" /></GridItem>}
        {!isSubmitStep() && (
          <GridItem
            alignSelf="center"
            className="formNavigation"
            // row="1 / -1"
            gridArea="back"
          >
            <NavArrow
              isLastStep={isLastStep()}
              direction="back"
              disabled={active === stepOrder[0]}
              onClick={backStep}
              className={isLastStep() ? "hideOnDesktop" : undefined}
            />
          </GridItem>
        )}
        {steps[active] &&
          !steps[active]["labelInternal"] &&
          steps[active].label && (
            <Flexbox
              // gridCol="2" gridRow="1"
              gridArea="label"
              justifySelf="center"
              alignSelf="end"
              {...steps[active]["labelProps"]}
              maxWidth={
                steps[active] && steps[active].maxWidth
                  ? steps[active].maxWidth
                  : undefined
              }
            >
              <h3 style={{ margin: "inherit" }}>
                {steps[active] &&
                  !steps[active]["labelInternal"] &&
                  steps[active].label}
              </h3>
              {steps[active].subtextLabel && (
                <h4 style={{ margin: "inherit" }}>
                  {steps[active].subtextLabel}
                </h4>
              )}
            </Flexbox>
          )}
        <FormContentContainer stepType={steps[active].type}>
          {/* {(!isFirstStep() && steps[active]) && (
            <Flexbox className="previousStep">
              <Step
                {...state}
                serverResponse={serverResponse}
                setDisabled={setDisabled}
                setServerResponse={setServerResponse}
                geographicAreaOnChange={geographicAreaOnChange}
                geographicAreaDetailedOnChange={geographicAreaDetailedOnChange}
                backStep={backStep}
                handleChange={handleChange}
                step={steps[getPrevStepIndex(true)]}
                nextStep={nextStep}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                iconSelectionOnChange={iconSelectionOnChange}
                selectedStates={getSelectedStates()}
              />
            </Flexbox>
          )} */}
          {/* {steps[active] && (
            <Step
              {...state}
              className="activeStep"
              serverResponse={serverResponse}
              setDisabled={setDisabled}
              setServerResponse={setServerResponse}
              geographicAreaOnChange={geographicAreaOnChange}
              geographicAreaDetailedOnChange={geographicAreaDetailedOnChange}
              backStep={backStep}
              handleChange={handleChange}
              step={steps[activeNew]}
              nextStep={nextStep}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              iconSelectionOnChange={iconSelectionOnChange}
              selectedStates={getSelectedStates()}
            />
          )} */}
          {steps[active] && (
            <Step
              {...state}
              className="activeStep"
              serverResponse={serverResponse}
              setDisabled={setDisabled}
              setServerResponse={setServerResponse}
              geographicAreaOnChange={geographicAreaOnChange}
              geographicAreaDetailedOnChange={geographicAreaDetailedOnChange}
              backStep={backStep}
              handleChange={handleChange}
              step={steps[active]}
              nextStep={nextStep}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              iconSelectionOnChange={iconSelectionOnChange}
              onInputFieldChange={onInputFieldChange}
              selectedStates={getSelectedStates()}
            />
          )}
        </FormContentContainer>
        {!isSubmitStep() && (
          <GridItem
            gridArea="next"
            alignSelf="center"
            justifySelf="end"
            className="formNavigation"
            width={isLastStep() ? "79px" : undefined}
          >
            {!isLastStep() && (
              <NavArrow
                disabled={disabled}
                onClick={nextStep}
                direction={isLastStep() ? "submit" : "next"}
              />
            )}
          </GridItem>
        )}
      </FormGrid>
    </PageContentContainer>
  );
};

export default Form;
