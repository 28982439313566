import axios from "axios";

const api = {
    submitForm: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            leadType: 'Auto Accident',
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/form/submit/createLead`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res['data']
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    submitContactForm: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/contact`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    getFAQ: async () => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/faq/`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return res.data.faq
        } catch (error) {
            console.log(error)
        }

    },
    searchPlaces: async (search = '', states = []) => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/places/searchPlaces`,
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    search: search.replaceAll(' ', '+'),
                    states: Array.isArray(states) ? states.map(st => st.replaceAll(' ', '+')) : states.replaceAll(' ', '+')
                }
            });
            console.log("result is: ", res)
            return res.data['result']
        } catch (error) {
            console.log(error)
        }
    },
}

export default api;