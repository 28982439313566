import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import TiledInput from './TiledInput'
import InputField from './InputField'
import TextArea from './TextArea'
import Flexbox from './Flexbox'
import { toTitleCase, formatPhoneNumber } from '../utils'
import states from "../utils/states.js";
import { validateEmail } from './Form'
import PageContentContainer from './PageContentContainer'
import GeographicAreaDetailed from './GeographicAreaDetailed'
import Button from './ButtonHome'
import Dropdown from './Dropdown'
import RadioButtons from './RadioButtons'
import Checkbox from './Checkbox'
import AsSeenOn from './AsSeenOn'
import CheckmarkValidate from './CheckmarkValidate'
import FAQ from './FAQ'
import Cases from './Cases'
import API from '../API'
import GridContainer from './GridContainer'
const Container = styled.div`
/* box-shadow: var(--formTileBoxShadow); */
display: grid;
position: relative;
width: 95%;
max-width: 700px;
padding: 20px 20px 40px;
grid-auto-flow: row;
grid-template-columns: 1fr;
grid-auto-rows: min-content;
gap: 15px;
height: 100%;
`;

const Label = styled.label`
font-size: 14px;
text-align: left;

h3{
    margin: 0 0 5px;
}
`;

const Link = styled(NavLink)`
color: var(--formBlue1);
display: inline;
`;

const GridC = styled.div`
display: grid;
grid-template-rows: min-content;
grid-template-columns: ${({ cols = 2, gridCol }) => gridCol || `repeat(${cols}, 1fr)`};
gap: 15px;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    grid-template-columns: 1fr;
    /* gap: 0; */
    grid-template-rows: min-content;
}
`;

const Box = ({ showContainer = false, cols, showLabel = false, gridCol, forEl, label, children }) => {
    if (!showContainer)
        return (
            <>{children}</>
        )
    else
        return (
            <div>
                {showLabel && <Label style={{ fontSize: '14px', margin: '0' }} for={forEl}>
                    <h3 >{label}</h3>
                </Label>}
                <GridC cols={cols} gridCol={gridCol}>{children}</GridC>
            </div>
        )
}

const Home2 = () => {
    let previousFormData = window.sessionStorage.getItem("formDataInline");
    const navigate = useNavigate()
    const getPrevValue = (name, defaultVal = "") => {
        // let _v = JSON.parse(previousFormData)
        return ((previousFormData && JSON.parse(previousFormData)[name]
            ? JSON.parse(previousFormData)[name]
            : defaultVal))
    }
    const [response, setResponse] = useState('')
    const [shouldShowValidators, setShouldShowValidators] = useState(false)
    const [serverResponse, setServerResponse] = useState({ showServerResponse: false, msg: '', didSend: false, sending: false, buttonText: 'Submit', isValid: false })
    const { isValid, showServerResponse } = serverResponse
    const setValid = (v) => {
        setServerResponse(prev => ({
            ...prev,
            isValid: v
        }))
    }

    const [formData, setFormData] = useState({
        startTime: getPrevValue('startTime', Date.now()),
        fields: {
            "field-1": {
                id: "field-1",
                name: "name",
                label: 'Name',
                type: "input",
                value: getPrevValue("name", ""),
                isValid: false,
                shouldShow: false,
                validate: function (v = this.value) {
                    return v.trim().length > 0
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-2": {
                id: "field-2",
                name: "phoneNumber",
                label: "Phone",
                type: "input",
                value: getPrevValue("phoneNumber", ""),
                isValid: false,
                shouldShow: false,
                validate: function (v = this.value) {
                    let isValid = true;
                    let val = v.trim()
                    if (val.length === 0 || (val.length > 0 && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(val)))
                        isValid = false
                    return isValid
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-3": {
                id: "field-3",
                name: 'email',
                type: 'input',
                value: getPrevValue("email", ''),
                label: "Email",
                wrapperWidth: '100%',
                get isValid() {
                    return this.validate(this.value)
                },
                shouldShow: false,
                placeholder: 'Email',
                validate: function (v = this.value) {
                    return validateEmail(v)
                }
            },
            "field-4": {
                id: "field-4",
                name: 'geographicArea',
                type: 'TiledInput',
                value: getPrevValue("geographicArea"),
                label: "Where did the accident take place? Enter state, then city.",
                wrapperWidth: '100%',
                get isValid() {
                    return this.validate(this.value)
                },
                shouldShow: false,
                placeholder: 'Enter State',
                items: getPrevValue("geographicArea") !== "" ? [{
                    value: getPrevValue("geographicArea"),
                    id: getPrevValue("geographicArea"),
                }] : [],
                validate: function (_val = this.value) {
                    const _state = states.find(
                        (it) => it.name.toLowerCase() === _val.toLowerCase()
                    );
                    if (_state) return true;
                    return false;
                },
            },
            "field-5": {
                id: "field-5",
                name: 'geographicAreaDetailed',
                type: 'TiledInput',
                value: getPrevValue("geographicAreaDetailed", {
                    id: "",
                    innerText: "",
                    state: "",
                    value: "",
                }),
                initialValue: getPrevValue("geographicAreaDetailed", {
                    id: "",
                    innerText: "",
                    state: "",
                    value: "",
                }),
                label: "Where did the accident take place?",
                wrapperWidth: '100%',
                get isValid() {
                    return this.validate(this.value)
                },
                isEnabled: getPrevValue("geographicArea") !== "" ? true : false,
                stateName: getPrevValue("geographicArea", ""),
                shouldShow: false,
                placeholder: 'Enter City',
                input: "",
                setInput: function (_val) {
                    setFormData((prev) => ({
                        ...prev,
                        fields: {
                            ...prev.fields,
                            [this.id]: {
                                ...prev.fields[this.id],
                                input: _val,
                            },
                        },
                    }));
                },
                // items: getPrevValue("geographicAreaDetailed", {
                //     id: "",
                //     innerText: "",
                //     state: "",
                //     value: "",
                // })['id'] !== "" ? [
                //     getPrevValue("geographicAreaDetailed", "")
                // ] : [],
                validate: function (_val = this.value) {
                    console.log("_val", _val)
                    let stepId = this.id;
                    let geographicAreaDetailedInput = document.getElementById(
                        "geographicAreaDetailedInput"
                    );

                    if (
                        (typeof _val === "string" && _val.trim() !== "") ||
                        (typeof _val === "object" &&
                            _val.innerText.trim() !== "")
                    ) {
                        console.log("from here 1")
                        return true;
                    } else {
                        if (geographicAreaDetailedInput) {
                            console.log("from here2")
                            if (geographicAreaDetailedInput["value"].trim().length === 0)
                                return false;
                            if (geographicAreaDetailedInput["value"].trim().length > 0)
                                return true;
                        } else if (typeof (_val) === 'object'
                            && _val['innerText'] && _val['innerText'].trim() !== '' && _val['state'] === getPrevValue('geographicArea', '')) {
                            console.log("from here")
                            return true
                        }
                        else return false;
                    }
                    return false;
                },
            },
            "field-6": {
                id: "field-6",
                name: 'additionalDetails',
                type: 'textArea',
                value: getPrevValue("additionalDetails", ""),
                label: "Tell Us About Your Case (Optional)",
                wrapperWidth: '100%',
                isValid: true,
                placeholder: 'The more detail you provide, the better our AI-driven matching process works.',
            },

            "field-9": {
                id: "field-9",
                name: 'responsibleParty',
                maxWidth: '400px',
                type: 'radio',
                label: "Who was at fault?",
                wrapperWidth: '100%',
                options: [
                    "Someone Else",
                    "Myself",
                ].map(it => ({ id: it, value: it, innerText: it, label: it })),
                get selected() {
                    let initialValue = getPrevValue("responsibleParty", [this.options[0].label])
                    return (Array.isArray(initialValue) ? initialValue[0] : initialValue)
                },
                get value() {
                    let initialValue = getPrevValue("responsibleParty", [this.options[0].label])
                    return initialValue
                }
            },
            "field-7": {
                id: "field-7",
                name: 'injurySeverity',
                type: 'dropdown',
                showDropdown: false,
                value: getPrevValue("injurySeverity", "--"),
                label: "Injury Severity",
                wrapperWidth: '100%',
                get isValid() {
                    return this.validate(this.value)
                },
                shouldShow: false,
                options: ["Moderate",
                    "Significant",
                    "Hospitalization without Surgery",
                    "Surgery Required",
                    "Loss of Life",].map(it => ({ id: it, value: it, innerText: it })),
                placeholder: 'Additional Details / Comments',
                validate: function (v = this.value) {
                    return this.value !== "--"
                },
            },
            "field-8": {
                id: "field-8",
                name: 'accidentYear',
                // maxWidth: '200px',
                type: 'dropdown',
                showDropdown: false,
                value: getPrevValue("accidentYear", "--"),
                label: "Accident Year",
                wrapperWidth: '100%',
                get isValid() {
                    return this.validate(this.value)
                },
                shouldShow: false,
                options: (function (x) {
                    return x.map((it, index) => {
                        if (index < x.length - 1) {
                            return (new Date().getFullYear() - index).toString();
                        } else return `${new Date().getFullYear() - index} Or Earlier`;
                    });
                })(new Array(4).fill(0)).sort(
                    (a, b) => b.replace(/[^\d]+/g, "") - a.replace(/[^\d]+/g, "")
                ).map(it => {
                    console.log("it:::", it)
                    return ({ id: it, value: it, innerText: it })
                }),
                placeholder: 'Additional Details / Comments',
                validate: function (v = this.value) {
                    return v !== "--"
                },
            },
            "field-10": {
                id: 'field-10',
                name: 'termsAgreement',
                type: 'checkbox',
                checked: getPrevValue("termsAgreement", false),
                value: getPrevValue("termsAgreement", false),
                isValid: getPrevValue("termsAgreement", false),
                text: <p style={{ fontSize: '12px', margin: '0' }}>I agree to the <Link to="/terms" target="_blank">Terms</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link></p>
            },
        },

        fieldOrder: ["field-1", "field-2", "field-3", {
            fields: ["field-4", "field-5"],
            label: <>Where did the accident take place?<br /><span style={{
                fontSize: '12px', fontWeight: '500',
                // color: 'var(--secondary)'
            }}> Enter state, then city.</span></>,
            showLabel: true
        }, { fields: ["field-7", "field-8"], gridCol: "2fr 1fr" }, "field-6", "field-9", "field-10"],
    })
    const { fieldOrder, fields, fields: {
        "field-5": { isEnabled: geoDetailedEnabled, value: geoDetailedValue },
        "field-4": { value: geoValue },
    } } = formData
    function resetValidatorFields() {
        // let _formData = formData['fields']
        // console.log("_formData", _formData)
        // _formData = Object.fromEntries(Object.entries(_formData).map(field => {
        //     let x = field[0]
        //     let y = field[1]
        //     if (y.shouldShow !== undefined && y.isValid)
        //         y['shouldShow'] = false
        //     return [x,y]
        // }))
        // console.log("_formData", _formData)
        // setFormData(prev => ({
        //     ...prev,
        //     fields: {
        //         ..._formData
        //     }
        // }))
    }
    function validateFields(returnBoolOnly = false) {
        if (serverResponse['sending'])
            return false
        if (window.sessionStorage.getItem('didSubmitForm')) {
            setServerResponse(prev => ({ ...prev, msg: 'Form sent!', didSend: true, sending: false, buttonText: '' }))
            return false
        }
        let _value = Object.keys(fields).reduce((val, b) => {
            if (!val)
                return val
            if (typeof (fields[b].validate) === 'function' && fields[b]['value'] !== undefined) {
                val = fields[b].validate()
                console.log("b::::", b, val)
            } else {
                // return val
            }
            return val
        }, true)

        // if (returnBoolOnly)
        //     return _value['value']
        // else
        return _value
    }
    const formatData = (flatten = true) => {
        let data = {}
        data.startTime = formData["startTime"];
        if (!flatten) {
            Object.keys(fields).forEach((fieldKey) => {
                data[fields[fieldKey].name] = fields[fieldKey].value;
            })
            console.log("datadata", data)
            return data;
        }
        else {

            data.endTime = Date.now();
            Object.keys(fields).forEach((fieldId) => {
                const field = fields[fieldId]
                if (field.name === 'geographicArea')
                    data["ste_name"] = field.value;
                else if (field.name === 'phoneNumber')
                    data["phoneNumber"] = { phoneNumber: field.value, ext: '' };
                else if (field.name === 'geographicAreaDetailed') {
                    if (geoDetailedValue === "" || (typeof (geoDetailedValue) === 'object') && geoDetailedValue['id'] === '') {
                        const inputField = document.getElementById('geographicAreaDetailedInput')
                        if (inputField && inputField['value'].trim().length > 0)
                            data["city"] = inputField['value']
                    } else {
                        data["city"] = field.value.value;
                        data["pla_code"] = field.value.pla_code;
                    }
                    return;
                } else {
                    data[field.name] = field['value']
                }
            })
        }
        return data
    }
    async function submitForm() {
        const _isValid = validateFields()
        // const { value: _isValid, message } = validateFields()
        resetValidatorFields()
        console.log("_isValid", _isValid)
        if (_isValid) {
            setShouldShowValidators(false)
            setServerResponse({ isValid: true, showServerResponse: false, msg: '', didSend: false, sending: true, buttonText: 'Sending...' })
            try {
                const data = formatData();
                const resData = await API.submitForm(data);
                if (window.sessionStorage.getItem("formDataInline"))
                    sessionStorage.removeItem("formDataInline");
                if (resData) {
                    const { googleCount } = resData
                    // if (googleCount) {
                    if (window.dataLayer && Array.isArray(window.dataLayer)) {
                        let ev = {
                            'event': 'form_submission',
                            'form_id': 'PRIMARY FORM - INLINE',
                            // 'form_entry': formEntry.current
                        }
                        if (googleCount)
                            ev['isCAC'] = true
                        // window.dataLayer.push(ev)
                    }
                }
                setServerResponse(prev => ({ ...prev, isValid: true, msg: prev['msg'], didSend: true, sending: false, buttonText: 'Sent!' }))
                navigate("/success");

            } catch (error) {
                console.log("an error occurred: ", error)
                const { response: { data: { msg } } } = error
                setServerResponse(prev => ({ ...prev, showServerResponse: true, msg: msg, didSend: false, sending: false, buttonText: 'Submit' }))
            }
        } else {
            setShouldShowValidators(true)
        }
    }
    const setShowDropdown = (field) => {
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                [field['id']]: {
                    ...prev['fields'][field['id']],
                    showDropdown: !prev['fields'][field['id']]['showDropdown']
                },
            }
        }))
    }
    const setItems = (items) => {
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                "field-4": {
                    ...prev['fields']["field-4"],
                    items: items,
                    value: items.length > 0 ? items[0]['value'] : ""
                },
                "field-5": {
                    ...prev['fields']["field-5"],
                    isEnabled: items.length > 0 ? true : false
                },
            }
        }))
    }
    function addState(state) {
        setItems([{ value: state.name, id: state.name }])
    }

    const isSelected = (stateName) => {
        return formData['fields']['field-4']['items'].find(it => it.value === stateName)
    }

    const normalizeString = (str) => {
        return str.toLowerCase()
    }

    const findMatchAcrossKeys = (obj, value) => {
        return Object.keys(obj).reduce((a, key) => {
            let v = normalizeString(value)
            let vK = normalizeString(obj[key])
            // let regex = new 
            if (normalizeString(obj[key]).includes(normalizeString(value)))
                return true
            else return a
        }, false)
    }

    const findSearchMatch = (value) => {
        return states.filter(state => {
            return (!isSelected(state.name) && findMatchAcrossKeys(state, value))
        })
    }

    const getSuggestions = (_input, setInput) => {
        //value: state.name, id: state.name
        return findSearchMatch(_input).map(state => {
            return ({
                innerText: state.name,
                value: state.abbreviation,
                onClick: () => {
                    if (!isSelected(state.name)) {
                        addState(state)
                        setInput('')
                    }
                },
                key: state.abbreviation
            })
        })
    }
    useEffect(() => {
        if (validateFields(true))
            setValid(true)
        else
            setValid(false)
        sessionStorage.setItem(
            "formDataInline",
            JSON.stringify({ ...formatData(false) })
        );
    }, [fields])

    const onChange = (field, e) => {

        let value = e.target.value
        let { shouldShow } = fields[field]
        let isValid = false
        if (fields[field]['name'] === 'phoneNumber') {
            value = formatPhoneNumber(value.replace(/\D/g, ''))
        }
        if (typeof (fields[field].validate) === 'function') {
            isValid = fields[field].validate(value)
            console.log("isValid", isValid)

        } else {
            isValid = true
        }
        if (shouldShowValidators)
            shouldShow = true
        console.log("field", field, value)
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                [field]: {
                    ...prev['fields'][field],
                    value,
                    isValid,
                    shouldShow
                }
            }
        }))
    }
    console.log("fields: ", geoDetailedValue)
    return (
        <PageContentContainer column justifyContent="center" justifyMobile="start" height="100%">
            <Container>
                {/* <GridContainer column width="max-content" justifySelf="center">
                    <h3 style={{ margin: '0', fontWeight: '200' }}><span>"CALM reimagines what finding an injury attorney looks like."</span></h3>
                    <h3 style={{ margin: '0', fontWeight: '200', textAlign: 'right' }}><span>-Time</span></h3>
                </GridContainer> */}
                <h3 style={{ margin: '0' }}>Advanced AI will help find the top attorney for your specific case.</h3> <h5 style={{ margin: '0' }}>
                    <span>#1 Matching Attorney Will Call or Email You for Free Consultation</span>
                    <br />
                    <span style={{ fontWeight: '400', marginTop: '50px', display: 'block', fontSize: '14px' }}>Powered by ChatGPT</span></h5>
                {/* <p style={{margin: '0'}}>Find out what your case is worth. Get matched with a top local attorney.</p> */}
                {
                    fieldOrder.map(field => {
                        let _f = typeof (field) === 'object' ? field['fields'] : [field]
                        console.log("_f", _f, field)
                        return (
                            <Box showContainer={_f.length > 1} cols={_f.length}
                                label={typeof (field) === 'object' ? field['label'] : undefined}
                                forEl={typeof (field) === 'object' ? field['for'] : undefined}
                                showLabel={typeof (field) === 'object' ? field['showLabel'] : undefined}
                                gridCol={typeof (field) === 'object' ? field['gridCol'] : undefined}>{
                                    _f.map(fieldId => {
                                        if (!fieldId.includes('field'))
                                            return
                                        const fieldObj = formData['fields'][fieldId]
                                        if (fieldObj.type === 'input')
                                            return (
                                                <InputField
                                                    key={field}
                                                    labelMargin="0 0 5px"
                                                    labelTextAlign="left"
                                                    labelFontSize="14px"
                                                    placeholder={fieldObj['name'] !== 'phoneNumber' ? toTitleCase(fieldObj['name']) : 'Phone [i.e. 213-555-5555]'}
                                                    {...fieldObj}
                                                    display="flex"
                                                    focusOutline="none"
                                                    padding="0 40px 0 20px"
                                                    isValid={fieldObj['isValid']}
                                                    outline="none"
                                                    border="none; border-bottom: 1px solid #fff"
                                                    useUnderlineTheme={true}
                                                    flexDirection="column"
                                                    showValidationCheckmark={(shouldShowValidators && !fields[field]['isValid']) || fields[field]['shouldShow']}
                                                    fontSize="16px"
                                                    // onChange={(e) => {
                                                    //     let val = e.target.value
                                                    //     console.log(val)
                                                    //     setFormData(prev => ({
                                                    //         ...prev,
                                                    //         fields: {
                                                    //             ...prev['fields'],
                                                    //             [fieldObj['id']]: {
                                                    //                 ...prev['fields'][fieldObj['id']],
                                                    //                 value: val,
                                                    //                 showDropdown: false,
                                                    //                 isValid: fieldObj['isValid'] === undefined && typeof (fieldObj['validate']) === 'function' ? undefined : fieldObj.validate(val)
                                                    //             },
                                                    //         }
                                                    //     }))
                                                    // }}
                                                    onChange={
                                                        (e) => {
                                                            onChange(field, e)
                                                        }
                                                    }
                                                    width="100%"></InputField>)
                                        if (fieldObj.type === 'textArea')
                                            return (
                                                <div>
                                                    <Label style={{
                                                        fontSize: '14px', margin: '0',
                                                        // color: fieldObj['isValid'] !== undefined && !fieldObj['isValid'] ? '#ff0000' : 'var(--primary)'
                                                    }} for={fieldObj['name']}><h3 >{fieldObj['label']}</h3></Label>
                                                    <TextArea
                                                        key={field}
                                                        placeholder={toTitleCase(fieldObj['name'])}
                                                        {...fieldObj}
                                                        isValid={fieldObj['isValid']}
                                                        useUnderlineTheme={true}
                                                        flexDirection="column"
                                                        onChange={(e) => {
                                                            onChange(field, e)
                                                        }}
                                                        padding="10px 35px 10px 20px"
                                                        fontSize="16px"
                                                        width="100%" label={undefined}></TextArea>
                                                </div>)
                                        if (fieldObj.type === 'dropdown') {
                                            let options = fieldObj['options']
                                            return (
                                                <div>
                                                    <Flexbox position="relative" alignItems="start">
                                                        <Label style={{
                                                            fontSize: '14px', margin: '0',
                                                            //  color: fieldObj['isValid'] !== undefined && !fieldObj['isValid'] ? '#ff0000' : 'var(--primary)' 
                                                        }} for={fieldObj['name']}><h3 >{fieldObj['label']}</h3></Label>{shouldShowValidators && <CheckmarkValidate top="unset" isValid={fieldObj['isValid']} />}</Flexbox>
                                                    <Dropdown
                                                        dropdownRef={null}
                                                        listContainerRef={null}
                                                        headerFontSize="16px"
                                                        headerHeight="49px"
                                                        itemHeight="49px"
                                                        maxHeight={4 * 49 + 'px'}
                                                        dropdownListMarginTop={"10px"}
                                                        {...fieldObj}
                                                        showHeader={true}
                                                        showArrow={true}
                                                        name={'Suggested Tags'}
                                                        marginBottom={'1rem'}
                                                        border="none"
                                                        headerBgd="#dbe7f2"
                                                        handleMenuStateInternally={false}
                                                        menuState={fieldObj['showDropdown']}
                                                        setMenuState={() => setShowDropdown({ ...fieldObj, field })}
                                                        handleStateChange={(e) => {
                                                            let val = e.target.getAttribute('value')
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                fields: {
                                                                    ...prev['fields'],
                                                                    [fieldObj['id']]: {
                                                                        ...prev['fields'][fieldObj['id']],
                                                                        value: val,
                                                                        showDropdown: false,
                                                                        isValid: fieldObj['isValid'] === undefined ? undefined : val !== "--"
                                                                    },
                                                                }
                                                            }))
                                                        }}
                                                        currSelection={fieldObj['value'] ? fieldObj['value'] : null}
                                                        valueX={fieldObj['value']}
                                                        boxShadow={" 0 0 4px rgba(var(--formBlue2Raw),0.5)"}
                                                        id={'suggested-tags'}
                                                        setValidContinue={() => { }}
                                                        justifyContent="start"
                                                        textAlign="left"
                                                        options={fieldObj['options']} />
                                                </div>)
                                        }
                                        if (fieldObj.type === 'radio') {
                                            let options = fieldObj['options']
                                            return (
                                                <div>
                                                    <Label style={{ fontSize: '14px', margin: '0' }} for={fieldObj['name']}><h3 >{fieldObj['label']}</h3></Label>
                                                    <RadioButtons
                                                        dropdownRef={null}
                                                        listContainerRef={null}
                                                        onClick={(v) => {
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                fields: {
                                                                    ...prev['fields'],
                                                                    [fieldObj['id']]: {
                                                                        ...prev['fields'][fieldObj['id']],
                                                                        value: [v],
                                                                        selected: v,
                                                                    },
                                                                }
                                                            }))
                                                        }}
                                                        headerFontSize="16px"
                                                        headerHeight="49px"
                                                        itemHeight="49px"
                                                        maxHeight={4 * 49 + 'px'}
                                                        dropdownListMarginTop={"10px"}
                                                        {...fieldObj}
                                                        showHeader={true}
                                                        showArrow={true}
                                                        name={'Suggested Tags'}
                                                        marginBottom={'1rem'}
                                                        border="none"
                                                        headerBgd="#dbe7f2"
                                                        handleMenuStateInternally={false}
                                                        menuState={fieldObj['showDropdown']}
                                                        setMenuState={() => setShowDropdown(fieldObj['id'])}
                                                        handleStateChange={(e) => {
                                                            let val = e.target.getAttribute('value')
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                fields: {
                                                                    ...prev['fields'],
                                                                    [fieldObj['id']]: {
                                                                        ...prev['fields'][fieldObj['id']],
                                                                        value: val,
                                                                        showDropdown: false
                                                                    },
                                                                }
                                                            }))
                                                        }}
                                                        currSelection={fieldObj['value'] ? fieldObj['value'] : null}
                                                        valueX={fieldObj['value']}
                                                        boxShadow={" 0 0 4px rgba(var(--formBlue2Raw),0.5)"}
                                                        id={'suggested-tags'}
                                                        setValidContinue={() => { }}
                                                        justifyContent="start"
                                                        textAlign="left"
                                                        options={fieldObj['options']} />
                                                </div>)
                                        }
                                        if (fieldObj.name === 'geographicArea') {
                                            return (
                                                <TiledInput
                                                    key={"geographicArea"}
                                                    labelTextAlign="left"
                                                    labelFontSize="14px"
                                                    placeHolder={toTitleCase(formData['fields'][fieldId]['placeholder'])}
                                                    {...formData['fields'][fieldId]}
                                                    display="flex"
                                                    padding="0 40px 0 20px"
                                                    mobileMaxWidth="100%"
                                                    maxWidth="unset"
                                                    focusOutline="none"
                                                    boxShadow="var(--defaultInputBoxShadow)"
                                                    background="#dbe7f2"
                                                    outline="none"
                                                    border="none"
                                                    inputBorder="none"
                                                    onFocusProps={{ outline: 'none' }}
                                                    isValid={formData['fields'][fieldId]['isValid']}
                                                    useUnderlineTheme={true}
                                                    multiTermEnabled={false}
                                                    flexDirection="column"
                                                    getSuggestions={getSuggestions}
                                                    setItems={setItems}
                                                    showValidationCheckmark={(shouldShowValidators && fields[fieldId]['shouldShow'] !== undefined)}
                                                    // showValidationCheckmark={(shouldShowValidators && !fields[fieldId]['isValid']) || fields[fieldId]['shouldShow']}
                                                    fontSize="16px"
                                                    width="100%"></TiledInput>)
                                        }
                                        if (fieldObj.type === 'checkbox') {

                                            return (
                                                <Flexbox position="relative"><Checkbox {...fieldObj} onCheck={() => {
                                                    let newValue = !fields[fieldId]['checked']
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        fields: {
                                                            ...prev['fields'],
                                                            [fieldId]: {
                                                                ...prev['fields'][fieldId],
                                                                checked: newValue,
                                                                value: newValue,
                                                                isValid: newValue === true
                                                            },
                                                        }
                                                    }))
                                                }} />{shouldShowValidators && <CheckmarkValidate isValid={fields[fieldId]['checked']} />}</Flexbox>
                                            )
                                        }
                                        if (fieldObj.name === 'geographicAreaDetailed') {
                                            return (
                                                <GeographicAreaDetailed inputOnly={true}
                                                    disabled={!geoDetailedEnabled}
                                                    geoClassName={geoDetailedEnabled ? "" : "disabled"}
                                                    setDisabled={() => { }}
                                                    selectedStates={geoValue}
                                                    initialValue={fieldObj["initialValue"]}
                                                    counties={geoDetailedValue}
                                                    onBlur={() => {
                                                        console.log("on blur")
                                                        if (geoDetailedValue === "") {
                                                            const inputField = document.getElementById('geographicAreaDetailedInput')
                                                            if (inputField && inputField['value'].trim().length > 0)
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    fields: {
                                                                        ...prev['fields'],
                                                                        [fieldId]: {
                                                                            ...prev['fields'][fieldId],
                                                                            value: {
                                                                                id: inputField['value'],
                                                                                innerText: inputField['value'],
                                                                                value: inputField['value'],
                                                                                state: geoValue
                                                                            }
                                                                        },
                                                                    }
                                                                }))
                                                        }
                                                    }}
                                                    setCounties={(v) => {
                                                        setFormData(prev => ({
                                                            ...prev,
                                                            fields: {
                                                                ...prev['fields'],
                                                                [fieldId]: {
                                                                    ...prev['fields'][fieldId],
                                                                    value: v
                                                                },
                                                            }
                                                        }))
                                                    }}
                                                    inputFieldProps={{
                                                        cursorDisabled: 'not-allowed',
                                                        backgroundDisabled: '#c2c2c2',
                                                        boxShadow: "var(--defaultInputBoxShadow)",
                                                        background: "#dbe7f2",
                                                        outline: "none",
                                                        border: "none",
                                                        inputBorder: "none",
                                                        mobileMaxWidth: '100%',
                                                        onFocusProps: { outline: 'none' },
                                                        showValidationCheckmark: (shouldShowValidators && !fields[fieldId]['isValid']) || fields[fieldId]['shouldShow']
                                                    }} />
                                            )
                                        }

                                    })}</Box>)
                        // const fieldObj = formData['fields'][field]


                    })}
                <Button text="Submit" borderRadius="30px" onClick={submitForm} />
                <h5>{(shouldShowValidators && !isValid) && <Flexbox width=""><span style={{ marginRight: '5px', color: '#990000' }}>Please complete all required fields marked with a </span><CheckmarkValidate position="relative" right="unset" isValid={false} top="unset" /></Flexbox>}</h5>
                <h5>{(showServerResponse) && <Flexbox width=""><span style={{ marginRight: '5px', color: '#990000' }}>{serverResponse['msg']}</span></Flexbox>}</h5>
            </Container>
            <Flexbox
                // background="#dbe7f2"
                width="100%"
                // boxShadow="var(--defaultInputBoxShadow)"
                margin="0 0 20px">
                <AsSeenOn _offset="0px" position="relative"
                    filter="brightness(0) saturate(100%) invert(34%) sepia(46%) saturate(5152%) hue-rotate(219deg) brightness(102%) contrast(101%)" />
            </Flexbox>
            <Cases />
            <FAQ isHome={true} />
        </PageContentContainer>
    )
}

export default Home2