import { useEffect, useState } from 'react';
import { debounce } from '../utils';

function useWindowHeight(delay = 700) {
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        const debouncedHandleResize = debounce(handleResize, delay);
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }, [delay]);

    return height;
}

export default useWindowHeight;