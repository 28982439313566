import React, { useRef } from 'react'
import Flexbox from '../../Flexbox'
import styled from 'styled-components'
import { Wrapper } from '../../Flexbox/Flexbox.styles'

import navArrow from '../../../images/navArrow2.svg'

import { Icon, SVG } from './NavArrow.styles'

const ArrowIcon = ({ ...props }) => {
    return (<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.48 175.48" {...props}><g id="a" /><g id="b"><g id="c"><g><g><circle class="d" cx="87.74" cy="87.74" r="84.24" /><circle class="e" cx="87.74" cy="87.74" r="84.24" /></g><polygon class="f" points="118.17 87.74 66.97 58.18 66.97 117.3 118.17 87.74" /></g></g></g></SVG>)
}

const NavArrow = ({ skipText = false, direction = 'next', disabled, onClick, isLastStep, className }) => {

    const navRef = useRef(null)
    return (
        <Flexbox
            isLastStep={isLastStep}
            mobileWidth={isLastStep ? "0px" : ''}
            mobileZindex={isLastStep ? "-1" : ''}
            mobileDisplay={isLastStep ? "none" : ''}
            alignSelf="center"
            // onMouseOver={(e) => {
            //     if (navRef.current)
            //         if (direction === 'next')
            //             navRef.current.style.transform = 'translate(-10px)'
            //         else
            //             navRef.current.style.transform = 'translate(10px)'
            // }}
            // onMouseOut={(e) => {
            //     if (navRef.current)
            //         navRef.current.style.transform = 'translate(0)'
            // }}

            refID={navRef}
            // left="-30px"
            flexDirection={direction === 'back' ? 'row' : 'row-reverse'}
            opacity={disabled ? '0.25' : '1'}
            gap="5px"
            height="min-content"
            // padding="30px"
            relative
            width="min-content"
            alignItems="center"
            transition="all 0.3s ease-in-out"
            justifySelf={direction === 'back' ? 'end' : undefined}
            justifyContent={direction === 'back' ? 'start' : undefined}
            // justifyContent={direction === 'next' ? undefined : 'end'}
            gridRow="2" onClick={onClick}>
            <ArrowIcon transform={direction === 'back' ? 'rotate(180deg)' : undefined} />
            {!skipText && <div>{direction.toUpperCase()}</div>}
        </Flexbox>
    )
}

export default NavArrow