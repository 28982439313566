import React from 'react'
import styled from 'styled-components';
import Flexbox from '../Flexbox'
import { NavLink } from 'react-router-dom'

const Link = styled(NavLink)`
all: unset;
cursor: pointer;
opacity: 1;
color: var(--linkColorDisclaimer);
width: min-content;
white-space: nowrap;
font-weight: 500;
:hover{
    text-decoration: underline;
}
`;
const FooterDisclaimer = () => {

    return (
        <Flexbox padding="0 30px 0" alignText="center" 
        // color="#fff" 
        position="relative">
            <p style={{fontSize: '12px', opacity: '0.5'}}>ATTORNEY ADVERTISING: {process.env.REACT_APP_DOMAIN} is not a law firm or an attorney referral service.  The information provided on this site, or any affiliated postings such as videos, blogs, social media, or elsewhere, is not legal advice.  No attorney-client or confidential relationship is, or will be, formed by usage of the site.  This site is a pooled attorney advertisement.  Participating Attorneys and/or law firms who appear on our site, or Participating Attorneys or law firms who contact Requestors based on form submissions, have paid an advertising fee.  Do not rely on AI or statements from our service when deciding which attorney to hire. While AI may help, a human will determine which attorney is your match. We are not affiliated with OpenAI. By submitting your contact info you agree an advertising attorney may contact you using any form of communication, including calls, emails, auto-dial, pre-recorded messages, and text messages. You understand consent is not a condition of purchase. Your use of this website constitutes acceptance of our <Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</p>
        </Flexbox>
    )
}

export default FooterDisclaimer