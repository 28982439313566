import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import anime from 'animejs'

import Flexbox from '../Flexbox'

const SVG = styled.svg`
height: 100%;
max-height: calc(100vh - 100px);
text-align: center;
position: relative;
.cls-1{filter:url(#gaussianBlur);}
.cls-2{fill:url(#linear-gradient-2);}
.cls-3{stroke:#ccc;}
.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8{stroke-miterlimit:10;}
.cls-3,.cls-4,.cls-5,.cls-8{fill:none;}
.cls-3,.cls-8{stroke-width:4px;}
.cls-9,.cls-10,.cls-11,.cls-12{fill:#fff;}
.cls-4,.cls-5,.cls-6,.cls-7{stroke:#5d8bfe;stroke-width:6px;}
.cls-4,.cls-7{opacity:.5;}
.cls-13{fill:#386aff;}
.cls-14{letter-spacing:-.02em;}
.cls-15{fill:#257cf9;font-family:'Albert Sans';font-size:22px;font-weight: bold}
.cls-16{fill:#5da8f6;}
.cls-6,.cls-7{fill:rgba(249,249,249,.15);}
.cls-17{fill:#2b77fb;}
.cls-18{fill:#0ad38c;}
.cls-19{letter-spacing:0em;}

.cls-21{letter-spacing:0em;}
.cls-22{opacity:.15;}
.cls-23{letter-spacing:-.05em;}
.cls-24{filter:url(#AI_Shadow_1);}
/* .cls-8{opacity:.25;stroke:#fff;stroke-linecap:round;} */
.cls-8{stroke:#fff;stroke-linecap:round;}
.cls-25{fill:#ccc;}
.cls-26{font-size:20px;opacity:.45;}
.cls-26,.cls-10,.cls-27,.cls-12{font-family:AlbertSans-Regular, 'Albert Sans';}
.cls-11{opacity:.35;}
.cls-29{fill:url(#linear-gradient);}
.cls-30{letter-spacing:-.01em;}
.cls-31{letter-spacing:0em;}
.cls-32{letter-spacing:-.05em;}
.cls-12{font-size:40px;}
.cls-28{letter-spacing:-.07em;}
.cls-20{letter-spacing:0em;}
.cls-10{font-size:12px;}
.cls-27{fill:#257df9;font-size:15.15px;}
@media only screen and (max-width: 550px),
        screen and (max-device-width: 550px) {
            width: 100%;
            max-height: 50vh;
        }
`;
const AnimatedStep3 = ({className}) => {

    useEffect(() => {
        const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
            let elements;
            elements = document.querySelectorAll(className);
            Array.from(elements).forEach((element) => {
                let BBox = element.getBBox();
                let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
                let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
                let BBoxX = BBox["x"] + BBoxX2;
                let BBoxY = BBox["y"] + BBoxY2;
                BBox = BBoxX + "px" + " " + BBoxY + "px";
                element.style.transformOrigin = BBox;
            });
        }
        fixSvgElementCoordinates('#letterhead')
        // fixSvgElementCoordinates('#tape2')

        // anime.set('#gaussianBlur feGaussianBlur', {
        //     stdDeviation: 0
        // })
        // anime.set('.callFadeIn', {
        //     opacity: 0
        // })
        // anime.set('#overlay', {
        //     opacity: 0
        // })
        // anime.set('#blurredEnvelope', {
        //     opacity: 0
        // })
        // anime.set('#answerCall', {
        //     opacity: 0
        // })
        // anime.set('#attorneyCallText', {
        //     opacity: 0
        // })
        // var tl = anime.timeline({
        //     easing: 'easeOutExpo',
        //     duration: 3500,
        //     autoplay: true,
        //     loop: true,
        //     delay: 3500 * 2
        // })
        // tl
        // .add({
        //     targets: '#blurredEnvelope',
        //     opacity: 1,
        //     duration: 500,
        //     delay: 500,
        //     // endDelay: 1000
        // })
        //     .add({
        //         targets: '#gaussianBlur feGaussianBlur',
        //         stdDeviation: 15,
        //         duration: 500,
        //         delay: 500,
        //         // endDelay: 1000

        //     })
        //     .add({
        //         targets: '.callFadeIn',
        //         opacity: 1.0,
        //         duration: 500,
        //         delay: 0,
        //     }, "-=500")
        //     .add({
        //         targets: '#overlay',
        //         opacity: 0.15,
        //         duration: 500,
        //         delay: 0,
        //         endDelay: 2500
        //     }, "-=500")
        // .add({
        //     targets: '#attorneyCallText',
        //     opacity: 1.0,
        //     duration: 500,
        //     delay: 0,
        // },"-=500")
        // .add({
        //     targets: '#answerCall',
        //     opacity: 0.15,
        //     duration: 500,
        //     delay: 0,
        // },"-=500")
        // .add({
        //     targets: '#letterhead',
        //     height: 326.1,
        //     y: 381.95,
        //     // scale: 1.5,
        //     duration: 1250,
        //     // delay: 500,
        // })
        // .add({
        //     targets: '#letterText1',
        //     opacity: 1,
        //     duration: 500,
        //     // endDelay: 1000

        // })

        // .add({
        //     targets: '#matchGroup',
        //     opacity: 1,
        //     duration: 250,
        //     endDelay: 1000
        // }, '-=250')

        // tl.play()

    }, [])
    return (

        <SVG id="Layer_2" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 462.19 935.45">
            <defs>
                <linearGradient id="linear-gradient" x1="-80.15" y1="780.32" x2="545.04" y2="155.13" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#386aff" />
                    <stop offset="1" stopColor="#1b86f6" />
                </linearGradient>
                <filter id="gaussianBlur">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
                </filter>
                <linearGradient id="linear-gradient-2" x1="231.32" y1="384.87" x2="231.32" y2="693.81" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fff" stopOpacity=".75" />
                    <stop offset="1" stopColor="#fff" stopOpacity=".15" />
                </linearGradient>
                <filter id="AI_Shadow_1">
                    <feGaussianBlur in="SourceAlpha" result="blur" stdDeviation="5" />
                    <feColorMatrix result="bluralpha" type="matrix" values=
                        "1 0 0 0   0 0 1 0 0   0 0 0 1 0   0 0 0 0 0.4 0 " />
                    <feMerge>
                        <feOffset dx="0" dy="0" in="blur" result="offsetBlurredAlpha" />
                        <feMergeNode in="offsetBlurredAlpha" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g id="Layer_1-2">
                <path className="cls-25" d="M396.03,9c31.52,0,57.16,25.53,57.16,56.9V869.56c0,31.37-25.64,56.89-57.16,56.89H68.16c-31.51,0-57.15-25.52-57.15-56.89V65.9c0-31.37,25.64-56.9,57.15-56.9H396.03M139.82,50.96c-4.67,0-9.07-1.78-12.36-5.02-3.27-3.21-5.07-7.48-5.07-12,0-7.9-6.49-14.32-14.47-14.32h-41.27c-26.03,0-47.2,20.85-47.2,46.48V869.36c0,25.63,21.17,46.48,47.2,46.48H398.25c26.03,0,47.2-20.85,47.2-46.48V66.1c0-25.63-21.17-46.48-47.2-46.48h-41.27c-3.86,0-7.48,1.48-10.19,4.16-2.76,2.71-4.28,6.32-4.28,10.16,0,9.38-7.82,17.02-17.43,17.02H139.82M396.03,3H68.16C33.28,3,5.01,31.16,5.01,65.9V869.56c0,34.74,28.27,62.89,63.15,62.89H396.03c34.88,0,63.16-28.15,63.16-62.89V65.9c0-34.74-28.28-62.9-63.16-62.9h0ZM139.82,56.96h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48V869.36c0,22.35-18.44,40.48-41.2,40.48H66.65c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h0Z" />
                <path className="cls-25" d="M254.6,39.53h-44.56c-1.1,0-2,.9-2,2s.9,2,2,2h44.56c1.1,0,2-.9,2-2s-.9-2-2-2h0Z" />
                <path className="cls-25" d="M285.86,36.21c-2.94,0-5.32,2.38-5.32,5.32s2.38,5.32,5.32,5.32,5.32-2.38,5.32-5.32-2.38-5.32-5.32-5.32h0Z" />
                <path className="cls-29" d="M439.45,66.1V869.36c0,22.35-18.44,40.48-41.2,40.48H66.65c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48Z" />
                <line className="cls-3" x1="2" y1="118.55" x2="2" y2="153.28" />
                <line className="cls-3" x1="2" y1="192.63" x2="2" y2="262.87" />
                <line className="cls-3" x1="2" y1="277.79" x2="2" y2="348.03" />
                <line className="cls-8" x1="172.6" y1="898.91" x2="292.3" y2="898.91" />
                <g id="blurredEnvelope" className="cls-1">
                    {/* <path className="cls-6" d="M368.45,549.77l-137.13,83.5-137.13-83.5c1.45-3.36,3.78-6.34,6.81-8.58l119.92-89.03c6.17-4.58,14.63-4.58,20.8,0l119.92,89.03c3.03,2.24,5.36,5.22,6.81,8.58Z" />
                    <line className="cls-5" x1="231.32" y1="633.27" x2="94.19" y2="549.77" />
                    <rect className="cls-2" x="114.73" y="381.95" width="233.18" height="326.1" rx="19.21" ry="19.21" />
                    <path className="cls-7" d="M94.19,549.77l120.96,72.21-119.17,93.7c-2.27-4.47-3.54-9.53-3.54-14.88v-142.6c0-2.94,.61-5.8,1.75-8.43Z" />
                    <path className="cls-7" d="M370.2,558.2v142.6c0,5.35-1.27,10.41-3.54,14.88l-116.68-91.74,118.47-74.17c1.14,2.63,1.75,5.49,1.75,8.43Z" />
                    <line className="cls-4" x1="95.98" y1="715.68" x2="94.19" y2="716.77" />
                    <path className="cls-7" d="M366.66,715.68c-5.43,10.75-16.58,18.12-29.46,18.12H125.44c-12.88,0-24.03-7.37-29.46-18.12l135.34-106.41,135.34,106.41Z" />
                    <g>
                        <path className="cls-9" d="M194.32,531.76c-.42-.11-.85-.19-1.29-.25-.48-.08-.97-.13-1.46-.14-1.02,.97-2.23,1.74-3.56,2.27h3.12c.41,0,.82,.02,1.22,.07,5.21,.61,9.26,5.05,9.26,10.42v13.36c-5.29,3.19-11.36,4.87-17.59,4.87s-12.62-1.77-18.02-5.13v-13.1c0-5.37,4.05-9.81,9.26-10.42,.4-.05,.81-.07,1.22-.07h3.11c-1.33-.53-2.53-1.3-3.55-2.27-.5,.01-.98,.06-1.46,.14-.44,.06-.87,.15-1.29,.25-5.5,1.43-9.57,6.43-9.57,12.37v14.34l.52,.34c5.89,3.81,12.73,5.83,19.78,5.83s13.55-1.92,19.34-5.55l.53-.33v-14.63c0-5.94-4.07-10.95-9.57-12.37Z" />
                        <g>
                            <path className="cls-17" d="M183.8,510.63c-6.89,0-12.48,5.59-12.48,12.48,0,3.24,1.23,6.19,3.26,8.4,.74,.82,1.59,1.54,2.53,2.13,1.93,1.24,4.23,1.95,6.69,1.95s4.76-.72,6.7-1.95c.94-.59,1.79-1.31,2.53-2.13,2.03-2.21,3.26-5.16,3.26-8.4,0-6.89-5.59-12.48-12.49-12.48Zm7.77,20.74c-1.02,.97-2.23,1.74-3.56,2.27-1.3,.52-2.72,.81-4.21,.81s-2.91-.29-4.21-.81c-1.33-.53-2.53-1.3-3.55-2.27-2.2-2.07-3.58-5-3.58-8.26,0-6.25,5.09-11.34,11.34-11.34s11.35,5.09,11.35,11.34c0,3.26-1.38,6.19-3.58,8.26Z" />
                            <path className="cls-9" d="M183.8,509.49c-7.51,0-13.62,6.11-13.62,13.62,0,3.28,1.17,6.29,3.11,8.65,.59,.72,1.25,1.37,1.97,1.95,2.34,1.89,5.31,3.02,8.54,3.02s6.22-1.14,8.55-3.02c.73-.59,1.39-1.24,1.97-1.95,1.94-2.36,3.11-5.37,3.11-8.65,0-7.51-6.11-13.62-13.63-13.62Zm0,24.96c-1.49,0-2.91-.29-4.21-.81-1.33-.53-2.53-1.3-3.55-2.27-2.2-2.07-3.58-5-3.58-8.26,0-6.25,5.09-11.34,11.34-11.34s11.35,5.09,11.35,11.34c0,3.26-1.38,6.19-3.58,8.26-1.02,.97-2.23,1.74-3.56,2.27-1.3,.52-2.72,.81-4.21,.81Z" />
                        </g>
                        <g>
                            <path className="cls-16" d="M279.4,532.74c-.48-.11-.97-.17-1.47-.2-.28-.03-.56-.04-.84-.04h-.98c-.66,.46-1.38,.84-2.14,1.14-1.3,.52-2.72,.81-4.2,.81s-2.91-.29-4.21-.81c-.76-.3-1.48-.68-2.14-1.14h-.98c-.29,0-.57,.01-.85,.04-.5,.03-.98,.09-1.46,.2-5.31,1.06-9.31,5.75-9.31,11.39v14c5.42,3.4,11.85,5.37,18.73,5.37s13.64-2.07,19.16-5.65v-13.72c0-5.64-4-10.33-9.31-11.39Zm-9.85,28.1c-6.45,0-12.49-1.85-17.59-5.1v-11.61c0-5.37,4.05-9.81,9.26-10.42,2.34,1.89,5.32,3.02,8.55,3.02s6.2-1.13,8.54-3.02c5.21,.61,9.26,5.05,9.26,10.42v11.28c-5.17,3.43-11.36,5.43-18.02,5.43Z" />
                            <path className="cls-9" d="M280.28,531.76c-.42-.1-.85-.19-1.29-.25-.48-.08-.97-.13-1.46-.14-.44,.42-.91,.8-1.42,1.13-.66,.46-1.38,.84-2.14,1.14h3.12c.41,0,.82,.02,1.22,.07,5.21,.61,9.26,5.05,9.26,10.42v13.1c-5.4,3.36-11.62,5.13-18.02,5.13s-12.3-1.68-17.59-4.87v-13.36c0-5.37,4.05-9.81,9.26-10.42,.4-.05,.81-.07,1.22-.07h3.12c-.76-.3-1.48-.68-2.14-1.14-.51-.33-.98-.71-1.42-1.13-.49,.01-.98,.06-1.46,.14-.44,.06-.87,.14-1.29,.25h0c-5.49,1.43-9.56,6.43-9.56,12.37v14.63l.53,.33c5.79,3.63,12.47,5.55,19.34,5.55s13.89-2.02,19.78-5.83l.52-.34v-14.34c0-5.94-4.07-10.94-9.57-12.37Z" />
                        </g>
                        <g>
                            <path className="cls-16" d="M269.76,510.63c-6.89,0-12.48,5.59-12.48,12.48,0,3.24,1.23,6.19,3.26,8.4,.32,.36,.68,.71,1.05,1.03,.46,.4,.96,.77,1.48,1.1,1.93,1.24,4.23,1.95,6.69,1.95s4.76-.72,6.7-1.95c.52-.33,1.01-.7,1.47-1.1,.38-.32,.73-.66,1.06-1.03,2.03-2.21,3.26-5.16,3.26-8.4,0-6.89-5.59-12.48-12.49-12.48Zm7.77,20.74c-.44,.42-.91,.8-1.42,1.13-.66,.46-1.38,.84-2.14,1.14-1.3,.52-2.72,.81-4.2,.81s-2.91-.29-4.21-.81c-.76-.3-1.48-.68-2.14-1.14-.51-.33-.98-.71-1.42-1.13-2.2-2.07-3.58-5-3.58-8.26,0-6.25,5.09-11.34,11.35-11.34s11.34,5.09,11.34,11.34c0,3.26-1.38,6.19-3.58,8.26Z" />
                            <path className="cls-9" d="M269.77,509.49c-7.52,0-13.63,6.11-13.63,13.62,0,3.28,1.17,6.29,3.1,8.65h0c.27,.34,.57,.67,.88,.98,.35,.34,.71,.67,1.09,.97,2.34,1.89,5.32,3.02,8.55,3.02s6.2-1.13,8.54-3.02c.38-.3,.74-.63,1.09-.97,.31-.31,.61-.64,.88-.98,1.94-2.36,3.11-5.37,3.11-8.65,0-7.51-6.11-13.62-13.62-13.62Zm6.34,23.01c-.66,.46-1.38,.84-2.14,1.14-1.3,.52-2.72,.81-4.2,.81s-2.91-.29-4.21-.81c-.76-.3-1.48-.68-2.14-1.14-.51-.33-.98-.71-1.42-1.13-2.2-2.07-3.58-5-3.58-8.26,0-6.25,5.09-11.34,11.35-11.34s11.34,5.09,11.34,11.34c0,3.26-1.38,6.19-3.58,8.26-.44,.42-.91,.8-1.42,1.13Z" />
                        </g>
                        <g>
                            <path className="cls-13" d="M307.55,528.16c0,20.95-17.05,38-38,38-17.87,0-33.1-12.24-37.04-29.43h-13.9c-1.47,0-2.66-1.19-2.66-2.66s1.19-2.66,2.66-2.66h16.08c1.3,0,2.41,.94,2.63,2.22,1.42,8.45,5.97,15.66,12.36,20.53,.38,.29,.76,.56,1.14,.83,.38,.26,.75,.51,1.14,.75,5.1,3.25,11.14,5.1,17.59,5.1s12.85-2,18.02-5.43c.39-.25,.77-.52,1.14-.79,.39-.28,.76-.56,1.14-.86,7.54-5.99,12.38-15.24,12.38-25.6,0-11.15-5.59-21.42-14.96-27.47-5.28-3.41-11.41-5.22-17.72-5.22s-12.14,1.72-17.32,4.97c-4.71,2.95-8.61,7.07-11.3,11.92-.71,1.29-2.33,1.75-3.62,1.04-1.28-.71-1.75-2.33-1.04-3.61,3.13-5.64,7.67-10.44,13.14-13.86,6.02-3.78,12.99-5.78,20.14-5.78s14.47,2.1,20.6,6.07c10.9,7.04,17.4,18.97,17.4,31.94Z" />
                            <polygon className="cls-13" points="209.22 534.07 220.91 540.82 220.91 527.32 209.22 534.07" />
                        </g>
                        <path className="cls-9" d="M184.03,566.16c-7.34,0-14.47-2.1-20.61-6.08-10.89-7.03-17.4-18.97-17.4-31.93,0-20.96,17.05-38.01,38.01-38.01,17.87,0,33.1,12.25,37.03,29.43h13.9c1.47,0,2.66,1.19,2.66,2.66s-1.19,2.66-2.66,2.66h-16.09c-1.3,0-2.41-.94-2.62-2.22-2.65-15.77-16.21-27.21-32.23-27.21-18.02,0-32.69,14.66-32.69,32.69,0,11.15,5.59,21.41,14.97,27.46,5.28,3.42,11.4,5.22,17.72,5.22s12.14-1.72,17.32-4.97c4.71-2.95,8.62-7.07,11.3-11.93,.71-1.28,2.33-1.75,3.62-1.04,1.28,.71,1.75,2.33,1.04,3.62-3.12,5.64-7.66,10.43-13.13,13.86-6.03,3.78-12.99,5.78-20.14,5.78Z" />
                        <polygon className="cls-9" points="244.35 522.24 232.66 515.5 232.66 528.99 244.35 522.24" />
                        <g>
                            <path className="cls-9" d="M307.55,528.16c0,20.95-17.05,38-38,38-17.87,0-33.1-12.24-37.04-29.43h-13.9c-1.47,0-2.66-1.19-2.66-2.66s1.19-2.66,2.66-2.66h16.08c1.3,0,2.41,.94,2.63,2.22,1.42,8.45,5.97,15.66,12.36,20.53,.38,.29,.76,.56,1.14,.83,.38,.26,.75,.51,1.14,.75,5.1,3.25,11.14,5.1,17.59,5.1s12.85-2,18.02-5.43c.39-.25,.77-.52,1.14-.79,.39-.28,.76-.56,1.14-.86,7.54-5.99,12.38-15.24,12.38-25.6,0-11.15-5.59-21.42-14.96-27.47-5.28-3.41-11.41-5.22-17.72-5.22s-12.14,1.72-17.32,4.97c-4.71,2.95-8.61,7.07-11.3,11.92-.71,1.29-2.33,1.75-3.62,1.04-1.28-.71-1.75-2.33-1.04-3.61,3.13-5.64,7.67-10.44,13.14-13.86,6.02-3.78,12.99-5.78,20.14-5.78s14.47,2.1,20.6,6.07c10.9,7.04,17.4,18.97,17.4,31.94Z" />
                            <polygon className="cls-9" points="209.22 534.07 220.91 540.82 220.91 527.32 209.22 534.07" />
                        </g>
                    </g>
                    <text className="cls-15" transform="translate(182.39 422.07)">
                        <tspan className="cls-23" x="0" y="0">W</tspan>
                        <tspan x="21.56" y="0">e </tspan>
                        <tspan className="cls-21" x="40.02" y="0">f</tspan>
                        <tspan className="cls-31" x="47.21" y="0">ound</tspan>
                        <tspan className="cls-14" x="-33.68" y="26.4">y</tspan>
                        <tspan className="cls-31" x="-21.45" y="26.4">ou an attorn</tspan>
                        <tspan className="cls-14" x="101.99" y="26.4">e</tspan>
                        <tspan className="cls-32" x="113.91" y="26.4">y</tspan>
                        <tspan className="cls-31" x="125.38" y="26.4">.</tspan>
                    </text>
                    <text className="cls-27" transform="translate(185.97 476.81)">
                        <tspan x="0" y="0">Lo</tspan>
                        <tspan className="cls-19" x="16.81" y="0">r</tspan>
                        <tspan x="22.11" y="0">em Ipsum</tspan>
                    </text> */}
                    <path className="cls-6 " d="M368.45,549.77l-137.13,83.5-137.13-83.5c1.45-3.36,3.78-6.34,6.81-8.58l119.92-89.03c6.17-4.58,14.63-4.58,20.8,0l119.92,89.03c3.03,2.24,5.36,5.22,6.81,8.58Z" />
                    <rect id="letterhead" className="cls-2" x="114.73" y="381.95" width="233.18" height="326.1" rx="19.21" ry="19.21" />
                    <line className="cls-5 " x1="231.32" y1="633.27" x2="94.19" y2="549.77" />
                    <path className="cls-6 " d="M94.19,549.77l119.99,73.03-118.2,92.88c-2.27-4.47-3.54-9.53-3.54-14.88v-142.6c0-2.94,.61-5.8,1.75-8.43Z" />
                    <path className="cls-6 " d="M370.2,558.2v142.6c0,5.35-1.27,10.41-3.54,14.88l-118.14-92.88,119.93-73.03c1.14,2.63,1.75,5.49,1.75,8.43Z" />
                    <line className="cls-3 " x1="95.98" y1="715.68" x2="94.19" y2="716.77" />
                    <path className="cls-6 " d="M366.66,715.68c-5.43,10.75-16.58,18.12-29.46,18.12H125.44c-12.88,0-24.03-7.37-29.46-18.12l135.34-106.41,135.34,106.41Z" />
                    <g >
                        <text className="cls-15" transform="translate(182.39 422.07)">
                            <tspan className="cls-23" x="0" y="0">W</tspan>
                            <tspan x="21.56" y="0">e </tspan>
                            <tspan className="cls-21" x="40.02" y="0">f</tspan>
                            <tspan className="cls-31" x="47.21" y="0">ound</tspan>
                            <tspan className="cls-14" x="-33.68" y="26.4">y</tspan>
                            <tspan className="cls-31" x="-21.45" y="26.4">ou an attorn</tspan>
                            <tspan className="cls-14" x="101.99" y="26.4">e</tspan>
                            <tspan className="cls-32" x="113.91" y="26.4">y</tspan>
                            <tspan className="cls-31" x="125.38" y="26.4">.</tspan></text>
                        <text className="cls-27" transform="translate(185.97 476.81)">
                            {/* <tspan x="0" y="0">Lorem Ipsum</tspan> */}
                        </text>
                    </g>
                    <g id="matchGroupX">
                        <g id="personRight">
                            <path className="cls-8" d="M292.49,547.42v13.56c-5.45,3.53-11.95,5.58-18.92,5.58s-13.15-1.95-18.51-5.31v-13.83c0-6.07,4.71-11.02,10.66-11.44,2.16,1.88,4.98,3.01,8.06,3.01s5.9-1.13,8.06-3.01c5.96,.41,10.65,5.37,10.65,11.44Z" />
                            <path className="cls-8" d="M286.11,526.67c0,3.72-1.65,7.06-4.26,9.32-2.16,1.88-4.98,3.01-8.06,3.01s-5.9-1.13-8.06-3.01c-2.61-2.26-4.26-5.59-4.26-9.32,0-6.81,5.52-12.33,12.33-12.33s12.33,5.52,12.33,12.33Z" />
                        </g>
                        <path id="pathLeft" className="cls-8" d="M239.4,525.81h-15.89c-2.78-16.51-17.14-29.08-34.43-29.08-19.28,0-34.92,15.63-34.92,34.92,0,12.31,6.37,23.13,15.99,29.34,5.45,3.53,11.95,5.58,18.93,5.58s13.14-1.94,18.5-5.31c5.02-3.15,9.19-7.54,12.07-12.74" />
                        <g id="personLeft">
                            <path className="cls-8" d="M207.58,547.42v13.83c-5.36,3.37-11.71,5.31-18.51,5.31s-13.47-2.05-18.93-5.58v-13.56c0-6.07,4.7-11.01,10.65-11.44,2.16,1.87,4.99,3.01,8.07,3.01s5.9-1.13,8.06-3.01c5.95,.41,10.66,5.37,10.66,11.44Z" />
                            <path className="cls-8" d="M201.19,526.67c0,3.72-1.65,7.06-4.26,9.32-2.16,1.88-4.98,3.01-8.06,3.01s-5.91-1.14-8.07-3.01c-2.61-2.26-4.26-5.59-4.26-9.32,0-6.81,5.53-12.33,12.34-12.33s12.33,5.52,12.33,12.33Z" />
                        </g>
                        <polygon id="arrowLeft" className="cls-10" points="248.67 525.81 237.12 519.14 237.12 532.48 248.67 525.81" />
                        <polygon id="arrowRight" className="cls-10" points="213.97 537.49 225.52 544.16 225.52 530.82 213.97 537.49" />
                        <path id="pathRight" className="cls-8" d="M223.24,537.49h15.89c2.78,16.51,17.14,29.08,34.43,29.08,19.28,0,34.92-15.63,34.92-34.92,0-12.31-6.37-23.13-15.99-29.34-5.45-3.53-11.95-5.58-18.93-5.58s-13.14,1.94-18.5,5.31c-5.02,3.15-9.19,7.54-12.07,12.74" />
                    </g>
                </g>
                <g id="overlay" className="cls-22" >
                    <path d="M439.45,66.1V869.36c0,22.35-18.44,40.48-41.2,40.48H66.65c-22.76,0-41.2-18.13-41.2-40.48V66.1c0-22.36,18.44-40.48,41.2-40.48h41.27c4.68,0,8.47,3.72,8.47,8.32,0,6.36,2.62,12.11,6.86,16.28,4.24,4.16,10.1,6.74,16.57,6.74h185.26c12.94,0,23.43-10.31,23.43-23.02,0-2.3,.95-4.38,2.48-5.88,1.53-1.51,3.65-2.44,5.99-2.44h41.27c22.76,0,41.2,18.12,41.2,40.48Z" />
                </g>
                <g id="attorneyCallText" className="callFadeIn">
                    <text className="cls-12" transform="translate(105.91 192.68)">
                        <tspan className="cls-28" x="0" y="0">Y</tspan>
                        <tspan x="23.16" y="0">our Attorn</tspan>
                        <tspan className="cls-20" x="210.4" y="0">e</tspan>
                        <tspan x="232.56" y="0">y</tspan>
                    </text>
                    <text className="cls-10" transform="translate(215.84 218.13)">
                        <tspan x="0" y="0">Office</tspan>
                    </text>
                </g>
                <g id="answerCall" className="callFadeIn">
                    <rect className="cls-11" x="77.4" y="753.48" width="310.1" height="65.73" rx="32.87" ry="32.87" />
                    <g id="backArrow" className="cls-24">
                        <path className="cls-9" d="M138.44,786.74c0,15.26-12.42,27.67-27.67,27.67-7.46,0-14.43-2.98-19.72-8.27-5.16-5.16-8.04-12.02-8.1-19.33-.06-7.39,2.77-14.37,7.97-19.62,10.68-10.86,28.22-11.02,39.1-.33l.32,.32s.06,.06,.08,.08c5.13,5.13,8.05,12.22,8.02,19.48Z" />
                    </g>
                    <g id="Layer_2-2">
                        <g id="Layer_4">
                            <path className="cls-18" d="M100.77,771c.61,.43,1.27,.79,1.81,1.3,1.41,1.33,2.76,2.72,4.12,4.1,1.24,1.25,1.23,2.46-.01,3.71-.86,.85-1.71,1.7-2.55,2.55-.52,.53-.56,.91-.14,1.52,2.5,3.56,5.61,6.66,9.17,9.17,.63,.45,1.01,.4,1.56-.15,.9-.89,1.79-1.79,2.68-2.68,.98-.98,2.21-1.1,3.21-.15,1.75,1.65,3.45,3.36,5.11,5.1,.94,.98,.83,2.24-.14,3.21-.56,.56-1.16,1.1-1.68,1.69-1.54,1.76-3.53,2.15-5.72,1.91-3-.34-5.69-1.56-8.2-3.15-5.58-3.54-10.02-8.18-13.06-14.09-.91-1.77-1.57-3.62-1.82-5.61-.02-.08-.05-.15-.09-.22v-2.08c.04-.13,.09-.27,.12-.4,.22-1.14,.67-2.16,1.54-2.96,.59-.55,1.13-1.16,1.73-1.71,.43-.39,.92-.7,1.38-1.05h.98Z" />
                        </g>
                    </g>
                    <text className="cls-26" transform="translate(149.02 791.73)">
                        <tspan x="0" y="0">slide to answer</tspan>
                        {/* <tspan className="cls-30" x="31.32" y="0">w</tspan>
                        <tspan x="46.2" y="0">er</tspan> */}
                    </text>
                </g>
            </g>
        </SVG>

    )
}

export default AnimatedStep3