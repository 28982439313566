import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import API from '../../API'
import Flexbox from '../Flexbox'
import FadeIn from '../FadeIn'
import Icon from '../Icon'
import Footer from '../Footer'

import addIcon from '../../images/addIcon.svg'

import anime from 'animejs'

const Collapsible = ({ qa, index }) => {
    const [open, setOpen] = useState(false)
    const elementRef = useRef(null)
    const collapseSection = (element) => {
        if (open)
            anime({
                targets: `.qa${index}`,
                height: [0, '100%'], // from 100 to 250
                delay: 500,
                // direction: 'alternate',
                loop: false
            });
        else
            anime({
                targets: `.qa${index}`,
                height: [0, '100%'], // from 100 to 250
                delay: 500,
                // direction: 'alternate',
                loop: false
            });

    }
   
    return (
        // <FadeIn padding="20px" onClick={() => { setOpen(!open) }}
        //     column alignItems="start" flexGrow="1" flexBasis="100%" justifyContent="start">
        <Flexbox
            padding="20px 0 0" onClick={() => {
                setOpen(!open)
            }}
            column alignItems="start" flexGrow="1" flexBasis="100%" justifyContent="start">
            <Flexbox justifyContent="space-between" width="100%" padding="10px" background="#dbe7f2" borderRadius="10px">
                <h3 style={{ margin: '0', fontWeight: '400', fontSize: '18px', textAlign: 'left' }} >{qa['question']}</h3>
                <Icon src={addIcon} transition="all 0.25s ease-in" height="25px" width="25px" transform={open ? "rotate(45deg)" : "rotate(0deg)"} />
            </Flexbox>

            <Flexbox
                className={`qa${index}`}
                refID={elementRef}
                column
                // transition="all 1s ease-in"
                alignItems={"start"}
                // flex={open ? '1' : '0'}
                transitionDelay="0"
                height={"auto"}
                maxHeight={open ? "1800px" : "0px"}
                // height="auto"
                overflow={"hidden"}>
                {typeof (qa['answer']) === 'string' && qa['answer'].split('\\n').map((el, index) =>
                    <p key={index}
                        style={{
                            // color: 'var(--secondary)',
                            fontSize: '17px',
                            textAlign: 'left',
                            fontWeight: '300'
                            // textAlign: 'center'
                        }}>
                        {el}
                    </p>
                )}
                {
                    typeof (qa['answer']) !== 'string' &&
                    <p
                        style={{
                            color: 'var(--secondary)',
                            fontSize: '17px',
                            textAlign: 'left',
                            fontWeight: '300'
                            // textAlign: 'center'
                        }}>
                        {qa['answer']}
                    </p>
                }

            </Flexbox>
        </Flexbox>
    )
}

const FAQ = ({ isHome = false }) => {
    const [faq, setFAQ] = useState([])
    useEffect(() => {
        const fetchFAQ = async () => {
            // let _faq = await API.getFAQ()
            let _faq = [
                {
                    question: "How does your AI system work?",
                    answer: <>
                        We use ChatGPT, possibly the world's most advanced AI, to help find the top match based on your specific case details.
                        <br /><br />
                        While, for ethical reasons, ChatGPT does not ever issue a direct recommendation for hiring a specific attorney, we have developed a proprietary process to use this incredible AI system to help determine which attorney is the closest fit for your personal situation.<br /><br />We present ChatGPT with all available data for your case, including all details you provide and any other available data such as (with your permission) your car accident police report.  We then have the system analyze the attorneys currently active in our database to help determine who may be the top match.
                        {/* When you tell a lawyer about your case, they'll be able to tell you exactly how much time you have to file your claim. */}
                    </>,
                },
                {
                    question: "How long do I have to file a claim?",
                    answer: <>
                        Each state in the US has its own deadline.  Some states only allow one year, so it's recommended that you file a claim within one year of the accident.
                        <br /><br />
                        However, other states allow more time.<br /><br />Unfortunately, it's not possible to recover any financial compensation if your state's deadline has expired.  When it comes to injury claims, time is of the essence, so we recommend speaking to a lawyer right away.
                        {/* When you tell a lawyer about your case, they'll be able to tell you exactly how much time you have to file your claim. */}
                    </>,
                },
                // {
                //     question: "Can I still get a settlement after the deadline?",
                //     answer: <>
                //         Unfortunately, it's not possible to recover any financial compensation if your state's deadline has expired.
                //         <br /><br />
                //         When it comes to injury claims, time is of the essence, so we recommend speaking to a lawyer right away.
                //     </>,
                // },
                {
                    question: "How do I get the car accident police report?",
                    answer: <>
                        An attorney will be able to get this for you.
                        <br /><br />
                        However, you can also get this without an attorney by contacting the police department who wrote the report.
                        <br /><br />
                        There are also third party companies online that provide this service for a fee, but you can likely avoid the fee by contacting the police directly.
                    </>,
                },
                {
                    question: "Should I take the settlement insurance is offering me?",
                    answer: <>
                        That's your decision, but keep in mind it's the insurance company's job to pay you the absolute minimum.
                        <br /><br />
                        A scientific study conducted by the insurance industry found those represented by an attorney, on average, received a settlement 3.5x larger than those who dealt with the insurance company themselves.
                        <br /><br />
                        For this reason, the insurance company does not want you to have an attorney, but the results show better outcomes for those who have one.
                    </>,
                },
                // {
                //     question: "What determines how much I get paid?",
                //     answer: <>The main thing that determines this is how severe your injuries are, as well as if you're losing income due to missed work.  Additional factors include potential ongoing costs like physical therapy, and future medical expenses.</>,
                // },
                {
                    question: "How much money is my claim worth?",
                    answer: <>The most severe injuries, or loss of life, can be cause for compensation in the millions.
                        <br /><br />
                        Other serious injuries can lead to compensation in the range of $100k-$1mil.
                        <br /><br />
                        Injuries that are significant, but not severe, will typically bring in less than $100k.
                        <br /><br />
                        Minor injuries may only qualify for $20k or less, or be too small to qualify for any compensation.
                        <br /><br />
                        This varies greatly depending on the specifics of where the accident happened, who was at fault, and the exact injury type. The only way to get an accurate assessment of what your injury is worth is to have an attorney review your case details.</>,
                },
                {
                    question: "What can an attorney do for me?",
                    answer: <>Most importantly, on average they increase settlement size by 3.5x.  For example, taking an initial settlement offer of $100k and increasing it to $350k.
                        <br /><br />
                        They also make life easier and eliminate stress by dealing with the insurance company on your behalf.</>,
                },
                {
                    question: "Can I file on behalf of a deceased family member?",
                    answer: <>Yes.  Loss of life is the most tragic of all injuries, and grieving family members can file a claim.  Loss of life claims may qualify for large settlements.</>,
                },
                {
                    question: "How much does an attorney cost?",
                    answer: <>You don't pay unless you win.  If you win, the attorney receives a portion of your settlement.
                        <br /><br />
                        The increase in settlement size achieved by an attorney means you will likely end up with a substantially larger payment than if you go it alone.
                        <br /><br />
                        For example, let's say insurance offered you $100k, but the attorney increases that to $350k, and the attorney receives a fee of 33% of your settlement.
                        <br /><br />
                        That means you receive $235k, more than double the $100k you would have received on your own.</>,
                },
                {
                    question: "Do I really need a lawyer?",
                    answer: <>No.  While you are likely to get significantly more compensation with an attorney, you are always free to deal with the insurance company yourself.</>,
                },
                {
                    question: "How can I find a lawyer qualified to help in my case?",
                    answer: <>That's what we do.
                        <br /><br />
                        Our attorneys have won $1.2 billion for their clients, including settlements of record-breaking size in nationwide headline news cases.  Some have received the highest awards in the legal field.
                        <br /><br />
                        Most attorneys take the same settlement fee, so it's always best to go with the top attorney willing to take your case.
                        <br /><br />
                        Simply enter basic info about your case like location and injury type, and we'll put you in touch with a top local attorney who can take your case, or just give you free legal advice and tell you what your case is worth.</>,
                },
                {
                    question: "What about those huge firms I see on TV and billboards?",
                    answer: <>
                        We don't include huge firms like this in our network.
                        <br /><br />
                        They may have thousands of cases at a time, and some of their clients have said they feel like just a number.  Some complained their case was passed off to a paralegal or legal assistant, and they felt the attorney was barely involved.
                        <br /><br />
                        Our network only includes firms capable of giving each client the personal service, time, and attention we feel is the foundation of the attorney-client relationship.
                    </>,
                },
                {
                    question: "Will I have to actually go to court?",
                    answer: <>
                        Not likely.  Approximately 95% of cases settle out of court without ever needing to go in front of a judge.
                    </>,
                },
                {
                    question: "If I get a case evaluation, do I have to pay or hire the attorney?",
                    answer: <>
                        No.  This is a free service.  You can just talk to the attorney, get free advice and info about what your claim is worth.
                    </>,
                },

            ]
            // _faq = _faq.map(item => {
            //     // let answer = item['answer'].split('\\n')
            //     // answer = answer.map((el, index) =>

            //     //     <p key={index} style={{
            //     //         color: 'var(--secondary)', fontSize: '1.2rem',
            //     //         // textAlign: 'center'
            //     //     }}>
            //     //         {el}
            //     //     </p>

            //     // )
            //     return ({
            //         question: item['question'],
            //         answer: item['answer'],
            //         // answer

            //     })
            // })
            setFAQ(_faq)
        }
        fetchFAQ()
    }, [])
    console.log("faq", faq)
    return (
        <Flexbox column position="relative"
            color="var(--primary)"
            minHeight={!isHome ? "calc(100vh - var(--navBarHeight))" : ''}
            margin={!isHome ? 'calc(var(--navBarHeight) * -1 ) 0 0' : undefined}
            background={!isHome ? "var(--formBackgroundAngled)" : undefined}
            alignItems="center">
            <Flexbox

                padding={!isHome ? 'calc(var(--navBarHeight) * 1) 50px 50px' : '0 50px 50px'}
                mobilePadding="0 5% 50px"
                height="100%"
                // minHeight={!isHome ? "calc(100vh - var(--navBarHeight))" : ''}
                position="relative"
                // overflow={!isHome ? "hidden auto" : undefined} 
                column alignItems="center"
            // background="var(--formBackgroundAngled)" color="#fff" 
            >
                <h1>FAQs</h1>
                {/* <p>lorem</p> */}
                <Flexbox gap="0px" flexWrap="wrap" height="min-content" alignItems="start" maxWidth="1000px">

                    {
                        faq.map((it, index) => {
                            return (
                                <Collapsible qa={it} key={index} index={index} />
                            )
                        })
                    }

                </Flexbox>

            </Flexbox>

        </Flexbox>
    )
}

export default FAQ