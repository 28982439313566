import React, { useEffect, useState, useRef, } from 'react'
import styled from 'styled-components'
import anime from 'animejs'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'
import useWindowHeight from '../hooks/useWindowHeight'
import useWindowWidth from '../hooks/useWindowWidth'
import { debounce } from '../utils'
import abc from '../images/abc.svg'
import cbs from '../images/cbs.svg'
import fox from '../images/fox.svg'
import nbc from '../images/nbc.svg'
import yahoo from '../images/yahoo.svg'
import time from '../images/time.svg'
import associatedPress from '../images/associatedPress.svg'
import chicagoTribune from '../images/chicagoTribune.svg'
import denverPost from '../images/denverPost.svg'
import usNews from '../images/usNews.svg'
import sfGate from '../images/sfgate.svg'
import motortrend from '../images/motortrend.svg'
import readersDigest from '../images/readersDigest.svg'


const Logo = styled.img`
position: relative;
height: ${({ h = 'auto' }) => h}; 
width: ${({ w = '100%' }) => (Number(w.replace(/\D/g, '')) * 0.67 + 'px')};
/* max-width: 150px; */
/* max-height: 45px; */
object-fit: contain;
/* flex-grow: 1;
 */
flex-shrink: 0;
flex-grow: 0;
/* -webkit-filter: invert(100%); filter: invert(100%); /* Safari/Chrome */
/* filter: ${({ filterOff }) => filterOff ? 'none' : "inherit"}; */
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    height: ${({ h = 'auto' }) => h}; 
    width: ${({ w = '100%' }) => (Number(w.replace(/\D/g, '')) * 0.67 + 'px')};
}
`;

const FlexWrapper = styled.div`
display: flex;
/* gap: 20px; */
position: relative;
/* left: 0; */
width: 100%;
transform: translateX(0);
margin: 10px auto;
align-items: center;
flex-wrap: wrap;
justify-content: center;
&> img{
    /* margin: 5px 50px; */
    /* margin: 5px 10px; */
}
& > :not(:last-child) {
    margin: 0 10px;
}
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
    }
    animation: ${({ isMobile }) => isMobile ? "scroll 40s linear infinite" : undefined}; 
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    
justify-content: space-around;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    gap: 20px;
    grid-template-rows: min-content;
    position: absolute;
    /* left: 0; */
    width: min-content;
    transform: translateX(0);
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    left: 0;
    &> img{
    /* margin: 5px 20px; */
    /* margin: 5px 10px; */
}
}
`;

const CarouselWrapper = styled.div`
position: relative;
/* overflow: hidden; */
/* width: 200%; */
height: 100%;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    overflow: hidden;
width: 200%;
height: 40px;
}
`;

const ColumnWrapper = styled.div`
display: flex;
flex-direction: column;
justify-self: end;
width: 100%;
height: auto;
position: ${({ position = "absolute" }) => position};
bottom: 0;
padding: 0;
/* opacity: 0; */
/* transform: translateY(200px); */
/* top: ${({ offset }) => offset}; */
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    /* position: relative; */
    /* bottom: 0; */
    /* top: 0; */
    /* top: 666px; */
}
img{
           /* -webkit-filter: invert(100%);  // Safari/Chrome */
            /* filter: invert(100%); */
            /* filter: 'var(--asSeenOnFilter)' */
            filter: ${({filter = 'var(--primary)' === '#fff' ? undefined : 'invert(100%)'}) => filter}; 
        }
`;

const H5 = styled.h5`
position: relative;
/* text-transform: uppercase; */
/* transform: ${({ offset }) => `translateY(${offset})`}; */
/* font-family: 'Lusitana', serif; */
font-weight: 200;
/* color: var(--formBlue1); */
/* opacity: 0; */
margin: 5px 0;
/* color: #fff; */
`;

const DivOuter = styled.div`
display: flex;
position: relative;
justify-content: center;
align-items: center;

@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    width: 100%;
left: 0;
overflow: hidden;
height: 40px;
}
`;

const Logos = () => {
    const isMobile = window.innerWidth <= 550
    return (
        <DivOuter>
            <CarouselWrapper>
                <FlexWrapper id="logoWrapper" isMobile={isMobile}>
                    <LogoWrapper />
                    {isMobile && <LogoWrapper filterOff={true} />}
                </FlexWrapper>
            </CarouselWrapper>
        </DivOuter>
    )
}

const LogoWrapper = ({ ...props }) => {
    return (
        <>
            <Logo src={abc} w="45px" {...props} />
            <Logo src={cbs} w="100px" {...props} />
            <Logo src={fox} w="70px" {...props} />
            <Logo src={nbc} w="45px" {...props} />
            <Logo src={time} w="90px" {...props} />
            <Logo src={associatedPress} w="40px" {...props} />
            <Logo src={chicagoTribune} w="140px" {...props} />
            <Logo src={denverPost} w="140px" {...props} />
            <Logo src={usNews} w="140px" {...props} />
            <Logo src={sfGate} w="60px" {...props} />
            <Logo src={motortrend} w="150px" {...props} />
            <Logo src={readersDigest} w="100px" {...props} />
        </>
    )
}

const AsSeenOn = ({ className, _offset, position, filter }) => {
    const [trH, setTrH] = useState('-13vh')
    const windowHeight = useWindowHeight(200)
    // const windowHeight = useState(window.innerHeight)
    const windowWidth = useRef(window.innerWidth)
    const offset1 = useRef('-13vh')
    const anim = useRef(null)
    const container2Ref = useRef(null)
    const isInitial = useRef(true)
    const [containerRef, isVisible] = useIntersectionObserver(
        {
            root: null,
            rootMargin: "0px",
            threshold: 0.5, // half of item height
        },
        true
    );
    // useEffect(() => {
    //     if (windowWidth.current >= 550){
    //         windowHeight.current = window.innerHeight
    //     }
    // },[windowHeight])

    useEffect(() => {

        if (container2Ref.current) {
            // setTrH(`-${container2Ref.current.clientHeight}px`)
            setTrH(container2Ref.current.clientHeight)
            // console.log("container2Ref.current.clientHeight", container2Ref.current.clientHeight, containerRef.current.clientHeight)
        }

    }, [container2Ref.current])
    return (
        <ColumnWrapper id="outerWrapperAsSeenOn" ref={container2Ref} position={position} filter={filter}
            offset={_offset || trH}
        >
            <div><H5 id="asSeenOn"
                offset={_offset || trH}
            >As Seen On</H5></div>
            <Logos />
        </ColumnWrapper>
    )
}

export default AsSeenOn