import React from 'react'
import styled from 'styled-components'
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";
import { useNavigate } from 'react-router-dom'
import ThemisAnimated from './ThemisAnimated'
import GridContainer from './GridContainer'
import Flexbox from './Flexbox';
import AsSeenOn from './AsSeenOn'
import Button from './ButtonHome';

const Container = styled.div`


@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {

}
`;

const Span = styled.span`
display: block;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    margin-top: 10px;
}
`;

const H1 = styled.h1`
margin: 50px 0 0;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    font-size: 1.3rem;
}
`;

const P = styled.p`
text-align: left;
position: relative;
margin: -7px 0 0; 
font-size: 12px;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    text-align: center;
    font-size: 10px;
    margin: 20px 0;
    /* bottom: -40px; */
    /* justify-self: end; */
    /* position: absolute; */
}
`;
const Hero = () => {
    const navigate = useNavigate()
    const [containerRef, isVisible] = useIntersectionObserver(
        {
            root: null,
            rootMargin: "0px",
            threshold: 0.5, // half of item height
        },
        true
    );
    return (
        <>
            <Flexbox
                minHeightMobile={`${window.innerHeight}px`}
                minHeight="100vh"
                // minHeight="70vh"
                margin="0"
                // margin="50px 0"
                // mobileMargin="50px 5%"
                width="100%"
                padding="var(--navBarHeight) 0"
                mobilePadding="0"
                justifyContent="center"
                justifyMobile="center"
                position="relative"
                background="var(--lightGrey)"
                backgroundSize="contain"
                backgroundPosition="center right" flexDirection="column">
                <Flexbox position="absolute" justifyContent="end" width="100%" right="50px" topMobile="50px">
                    <ThemisAnimated />
                </Flexbox>
                <GridContainer column gap="20px" width="100%"
                    zIndex="1"
                    refId={containerRef}
                    className={isVisible ? "homeHeaderContainer overflowVisible" : "homeHeaderContainer"}
                    padding="0 50px"
                    paddingMobile="0"
                    justifyContent="center" alignItems="start">

                    <H1
                        className={isVisible ? "visible homeHeader" : "homeHeader hideOnMobile"} style={{
                            // color: "#fff",
                            
                        }}><span style={{ display: 'block' }}>Don't settle for just any lawyer.</span><Span>Our network has won $1.2B for clients.</Span></H1>
                        <h3 className={isVisible ? "visible homeHeader hideOnDesktop2" : "homeHeader hideOnDesktop2"} style={{
                        // color: "#fff",
                        fontWeight: '400',
                        margin: '0'
                    }} >A top-rated lawyer will fight for you and show you what your case is really worth.</h3>
                    <h3 className={isVisible ? "visible homeHeader hideOnMobile" : "homeHeader hideOnMobile"} style={{
                        // color: "#fff",
                        fontWeight: '400',
                        margin: '0'
                    }}>
                        <span>A top-rated lawyer will fight for you and show you what your case is really worth.</span>
                        <Span>They don't charge a dime unless you win.</Span></h3>
                    {/* <P className={isVisible ? "visible hideOnMobile" : undefined}>*Based on average found, according to a study by the Insurance Research Council in its report titled "Attorney Involvement In Auto Injury Claims"</P> */}
                    <Button text="Free Attorney Case Review" marginMobile="5px auto" margin="5px 0" borderRadius="30px" boxShadow="0 0 5px rgba(0,0,0.5)" width="95%" maxWidth="400px" onClick={() => navigate('/form', { state: { form_entry: 'Home Page - Get Started Button' } })} />
                </GridContainer>
                <AsSeenOn />
            </Flexbox>
                {/* <P className={isVisible ? "visible hideOnDesktop2" : undefined}>*Based on average found, according to a study by the Insurance Research Council in its report titled "Attorney Involvement In Auto Injury Claims"</P> */}
        </>

    )
}

export default Hero