import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import anime from 'animejs'

import Flexbox from '../Flexbox'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import AnimatedStep2 from '../AnimatedStep2'
import AnimatedStep3 from '../AnimatedStep3'

import { fixSvgElementCoordinates } from '../../utils'

const SVG = styled.svg`
height: 80px;
width: auto;
.cls-1{fill:#fff;}
.cls-2{fill:#386aff;}
.cls-3{stroke:#5d8bfe;stroke-linecap:round;stroke-width:3px;}
.cls-3,.cls-4{fill:none;stroke-miterlimit:10;}
.cls-4{stroke:#386aff;}
#eyeballpath{fill: none;stroke: none;}
`;


const InvestigateIcon = () => {

    useEffect(() => {
        const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
            let elements;
            elements = document.querySelectorAll(className);
            Array.from(elements).forEach((element) => {
                let BBox = element.getBBox();
                let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
                let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
                let BBoxX = BBox["x"] + BBoxX2;
                let BBoxY = BBox["y"] + BBoxY2;
                BBox = BBoxX + "px" + " " + BBoxY + "px";
                element.style.transformOrigin = BBox;
            });
        }
        // fixSvgElementCoordinates(`#iris`)
        // fixSvgElementCoordinates('#tape2')


        anime.set(`#eyeball`, {
            opacity: 0
        })

        var tl = anime.timeline({
            // easing: 'easeOutExpo',
            duration: 4500,
            autoplay: true,
            loop: true,
            direction: 'alternate',
        })
        var path = anime.path('#eyeballpath');
        tl
            .add({
                targets: `#eyeball`,
                opacity: 1.0,
                duration: 500,
                delay: 1000
            })
            .add({
                targets: `#iris`,
                translateX: path('x'),
                translateY: path('y'),
                rotate: path('angle'),
                // delay: 1000,
                duration: 2000,
                endDelay: 1000
            })

        // .add({
        //     targets: `${componentId} .callFadeIn`,
        //     opacity: 1.0,
        //     duration: 500,
        //     delay: 0,
        //     endDelay: 2500
        // }, "-=500")



        tl.play()

    }, [])
    return (
        <SVG id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172.94 172.94">
            <g id="Layer_1-2">
                <g>
                    <circle class="cls-1" cx="86.47" cy="86.47" r="80.47" transform="translate(-35.82 86.47) rotate(-45)" />
                    <path class="cls-2" d="M86.47,12c41.06,0,74.47,33.41,74.47,74.47s-33.41,74.47-74.47,74.47S12,127.54,12,86.47,45.41,12,86.47,12m0-12C38.71,0,0,38.72,0,86.47s38.71,86.47,86.47,86.47,86.47-38.72,86.47-86.47S134.23,0,86.47,0h0Z" />
                </g>
                <path class="cls-2" d="M125.21,48.12c-12.49-12.49-32.73-12.5-45.23-.01-11.3,11.31-12.36,28.96-3.2,41.47l-7.79,7.81-2.19-2.19-28.43,28.43,11.32,11.32,28.44-28.43-2.18-2.18,7.79-7.8c12.5,9.17,30.16,8.11,41.47-3.2,12.49-12.49,12.48-32.73,0-45.22Zm-6.03,39.19c-7.97,7.95-20.22,8.99-29.3,3.1-1.37-.88-2.66-1.91-3.85-3.1-1.19-1.2-2.24-2.49-3.12-3.85-5.88-9.09-4.85-21.34,3.11-29.31,9.16-9.15,24.01-9.15,33.16,0,9.15,9.16,9.14,24,0,33.16Z" />
                <g id="eyeball">
                    <circle id="iris" class="cls-2" cx="0" cy="0" r="4.64" />
                    <path id="eyeballpath" d="M107.66,71.47q-2.2-.65-5.06-.74-2.86,.09-5.06,.74" />
                    <path class="cls-3" d="M116.38,70.72s-4.86,8.92-13.78,8.92-13.78-8.92-13.78-8.92c0,0,4.86-8.92,13.78-8.92s13.78,8.92,13.78,8.92Z" />
                </g>
            </g>
        </SVG>
    )
}

export default InvestigateIcon