import React, { useEffect } from 'react'
import styled from 'styled-components'
import anime from 'animejs';
import Flexbox from '../Flexbox';

import { fixSvgElementCoordinates, setSvgElementCoords } from '../../utils'

const SVG = styled.svg`
height: 300px;
width: auto;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
      width: 100%;
      height: auto;
    }
.cls-1{stroke:#999;}
.cls-1,.cls-2,.cls-3,.cls-4{stroke-miterlimit:10;}
.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{fill:none;}
.cls-6{fill:#fff;}
.cls-7{fill:#b82612;}
.cls-2,.cls-4{stroke:#fff;}
.cls-3{stroke:#b3b3b3;}
.cls-8{fill:#e6e6e6;}
.cls-9{fill:gray;}
.cls-10{fill:#f2622e;}
.cls-11{fill:#9a3a17;}
.cls-4{stroke-dasharray:0 0 11.92 11.92;}
.cls-12{fill:#999;}
.cls-13{fill:#ccc;}
.cls-14{fill:#b3b3b3;}
.cls-15{fill:#b5441b;}
.cls-16{fill:#666;}
.cls-17{fill:#c1481d;}
#airplanePath{
    /* stroke: #ff0000; */
}
/* #airplane::after{
    content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  /* background-color: #f012be; }*/

#airplane{
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 74px;
    height: 23px;
}
`;

const Airplane = () => {
    useEffect(() => {
        // fixSvgElementCoordinates('#airplane', 0, 2, null, null)
        let air = document.querySelector('#airplane')
        air.style.transformOrigin = '-2% 2%'
        // fixSvgElementCoordinates('#airplane', 30, 1.8, null, null)

        var path = anime.path('#airplanePath');
        var tl = anime.timeline({
            delay: 0,
            duration: 3000,
            easing: 'easeInQuart',
            loop: false
        })
        tl.add({
            targets: '#airplane',
            translateX: path('x'),
            translateY: path('y'),
            rotate: path('angle'),
            // scale: '1.5',
            duration: 3000,
            // endDelay: 4000

        })
        tl.add({
            targets: '#mask-path-path',
            strokeDashoffset: [anime.setDashoffset, 0],

            duration: 3000,
            delay: function (el, i) { return i * 250 },
            direction: 'alternate',
            endDelay: 3000
        }, '-=3000')

    }, [])
    return (
        <Flexbox 
        // style={{ alignSelf: 'center', top: '35px', left: '25px', position: 'relative' }}
         top="35px" left="25px" position="relative" alignSelf="center" mobileWidth="100%" leftMobile="0">
            <SVG id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.48 306.54">
                <defs>
                    <style>
                    </style>
                    <mask id="mask-path" >
                        <path id="mask-path-path" d="M380.8,287.78c1.93-27.94-9.61-56.86-31.08-75-26.05-22.01-65.28-26.98-95.99-12.15-11.59,5.6-22.45,14.47-26.4,26.72-3.95,12.25,1.43,27.94,13.63,32.04,13.48,4.53,27.97-6.83,32.7-20.24,5.19-14.69,1.75-32.09-8.64-43.71-9.31-10.4-23.13-15.77-36.91-17.93-13.79-2.16-27.83-1.46-41.78-1.68-23.88-.37-54.23,5.09-74.25-7.92-18.23-11.85-32.87-38.01-9.98-68.16" stroke="#ffffff" />
                    </mask>
                </defs>
                <g id="Layer_2-2">
                    <g >
                        <g>
                            {/* <path className="cls-2" d="M377.7,305.4c.56-1.92,1.04-3.87,1.46-5.82" /> */}
                            <path mask="url(#mask-path)" id="airplanePath" className="cls-4" d="M380.8,287.78c1.93-27.94-9.61-56.86-31.08-75-26.05-22.01-65.28-26.98-95.99-12.15-11.59,5.6-22.45,14.47-26.4,26.72-3.95,12.25,1.43,27.94,13.63,32.04,13.48,4.53,27.97-6.83,32.7-20.24,5.19-14.69,1.75-32.09-8.64-43.71-9.31-10.4-23.13-15.77-36.91-17.93-13.79-2.16-27.83-1.46-41.78-1.68-23.88-.37-54.23,5.09-74.25-7.92-18.23-11.85-32.87-38.01-9.98-68.16" />
                            {/* <path className="cls-2" d="M105.87,95.14c1.28-1.45,2.64-2.92,4.1-4.39" /> */}
                        </g>
                    </g>
                    <g id="airplane">
                        <polygon className="cls-14" points="21.17 12.53 30.89 20.41 44.79 24.74 74.44 13.19 21.17 12.53" />
                        <g>
                            <g>
                                <polygon className="cls-10" points="22.33 12.84 .5 13.19 14.9 7.37 22.33 12.84" />
                                <polygon className="cls-15" points="21.48 12.95 18.29 13 14.9 7.37 21.48 12.95" />
                                <polygon className="cls-11" points="13.48 17.56 17.68 12.92 22.33 12.84 13.48 17.56" />
                                <polygon className="cls-17" points="13.48 17.56 .5 13.19 18.71 12.8 13.48 17.56" />
                            </g>
                            <polygon className="cls-9" points="33.06 12.67 19.83 22.49 8.55 20.79 21.17 12.53 33.06 12.67" />
                            <polygon className="cls-16" points="30.49 13.39 19.78 22.26 31.23 13.56 30.49 13.39" />
                            <polygon className="cls-8" points="33.06 12.67 20.07 2.54 9.82 3.35 21.17 12.53 33.06 12.67" />
                            <polygon className="cls-13" points="21.17 12.53 29.6 2.23 43.17 .5 74.44 13.19 21.17 12.53" />
                            <line className="cls-3" x1="42.82" y1="1.04" x2="33.05" y2="12.68" />
                            <line className="cls-1" x1="33.05" y1="12.68" x2="44.39" y2="24.33" />
                            <polygon className="cls-6" points="74.44 13.19 49.26 17.88 33.41 17.5 21.17 12.53 74.44 13.19" />
                            <polygon className="cls-6" points="74.44 13.19 49.55 20.05 33.51 17.45 21.17 12.53 74.44 13.19" />
                            <polygon className="cls-8" points="49.5 19.46 33.51 17.45 21.15 12.85 21.15 12.85 33.13 12.68 49.5 19.46" />
                            <line className="cls-5" x1="33.06" y1="12.67" x2="20.16" y2="12.87" />
                            <polygon className="cls-13" points="74.44 13.19 49.57 19.73 34.17 17.56 49.5 19.46 74.44 13.19" />
                            <polygon className="cls-7" points="9.37 12.5 2.06 13.21 9.41 13.38 9.37 12.5" />
                            <g>
                                <polygon className="cls-14" points="33.06 12.67 31.86 12.5 11.95 11.48 5.59 13.06 33.06 12.67" />
                                <polygon className="cls-12" points="33.06 12.67 31.99 12.88 11.99 14.21 5.59 13.06 33.06 12.67" />
                                <polygon className="cls-9" points="33.06 12.67 12.66 12.62 11.85 13.01 12.72 13.3 33.06 12.67" />
                            </g>
                        </g>

                        <line className="cls-5" x1="56.48" y1="12.97" x2="55.22" y2="12.95" />
                        <line className="cls-5" x1="47.33" y1="12.86" x2="44.65" y2="12.82" />
                    </g>
                </g>
            </SVG>
        </Flexbox>
    )
}

export default Airplane