import React, { useState, useEffect } from 'react'

import axios from 'axios'

const Test = () => {

    useEffect(() => {
        const getStates = async () => {
            // const res = await axios({
            //     method: 'get',
            //     url: 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-state-millesime&q=&sort=year&facet=year&rows=51&facet=ste_code&facet=ste_name&facet=ste_type&refine.ste_type=state',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            // })
            // const { data: { records } } = res
            // console.log("res", records)
            // let newRecords = []
            // records.forEach(record => {
            //     const { fields: { ste_code, ste_name, ste_stusps_code, ste_gnis_code } } = record
            //     newRecords.push({
            //         name: ste_name,
            //         ste_code,
            //         ste_gnis_code,
            //         abbreviation: ste_stusps_code
            //     })
            // })
            // newRecords = newRecords.sort((a, b) => parseInt(a.ste_code) - parseInt(b.ste_code)).filter( it => it.name !== 'Alabama' && it.name !== 'Alaska' && it.name !== 'Arizona')
            
            const res = await axios({
                method: 'get',
                url: 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-county&q=&sort=year&facet=year&rows=3&index=9&facet=ste_code&facet=ste_name&facet=ste_type&facet=coty_fips_code',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            const { data: { records } } = res
            console.log("res", records)
            let newRecords = []
            records.forEach(record => {
                const { fields: { coty_name_long, coty_code, ste_code, coty_name, coty_gnis_code, ste_name, coty_fp_code } } = record
                newRecords.push({
                    name: coty_name,
                    coty_name_long,
                    ste_name,
                    coty_code,
                    coty_gnis_code,
                    ste_code,
                    coty_fips_code: coty_fp_code
                })
            })
            //newRecords = newRecords.sort((a, b) => parseInt(a.ste_code) - parseInt(b.ste_code)).filter( it => it.name !== 'Alabama' && it.name !== 'Alaska' && it.name !== 'Arizona')
            

            console.log("newRecords", newRecords)
            const res2 = await axios({
                method: 'post', // & controllers
                url: `http://localhost:5000/api/v1/counties`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({ counties: newRecords })
            })
        }
        getStates()

    }, [])
    return (
        <div>Test</div>
    )
}

export default Test