import React from 'react'
import styled from 'styled-components';
import { Marker, ListItem, Span, ListComponent, OrderedList } from './PageListStyle3'
import Flexbox from './Flexbox';
import carIcon from "../images/carIcon3.svg";
import pedestrian from "../images/pedestrianIcon.svg";
import motorcycle from "../images/motorcycleIcon.svg";
import truck from "../images/truckIcon.svg";

const Ul = styled.ul`
display: grid;
margin: 20px 0 0;
padding: 0;
max-width: 1200px;
flex-direction: row;
grid-auto-rows: min-content;
grid-auto-columns: 1fr;
grid-template-columns: repeat(4,1fr);
grid-auto-flow: row;
flex-wrap: wrap;
/* max-height: 200px; */
gap: 40px;
width: 95%;
/* >li{
    flex-basis: 25%;
    width: 25%;
    margin: 5px;
    min-width: 300px;
} */
@media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {

        grid-template-columns: 1fr;
    }
`;
const Cases = () => {
    const listItems = [
        {
            img: carIcon,
            heading: 'Car Accidents',
            // text: <>Lawyers in our network are well experienced in handling car accident cases, big or small. Don't be discouraged if your insurance company has refused compensation or pressured you to accept a low-ball offer.<br/><br/><span style={{color: 'var(--formBlue1)'}}>Attorney Network Case Result:</span><br/>Partially at-fault, uninsured motorcylist with a traumatic brain injury. The defendant insurance policy had a limit of $1M. Insurance company refused request for a $125k settlement and ended up owing the victim $5.3M.</>
        },
        {
            img: truck,
            heading: '18-Wheeler / Truck',
        },
        {
            img: motorcycle,
            heading: 'Motorcycle',
        },
        {
            img: pedestrian,
            heading: 'Pedestrian',
        },
    ]
    return (
        <Flexbox position="relative" column width="100%"
            background="#dbe7f2"
            padding="50px 0"
            boxShadow="var(--defaultInputBoxShadow)">
            <h2>We Handle All Automotive Injury Cases</h2>
            <Ul >
                {listItems.map((item, index) => (
                    <ListComponent key={index} {...item} index={index + 1} bgd="none" boxShadow="none"/>
                ))}
            </Ul>
            <Flexbox>

            </Flexbox>
        </Flexbox>
    )
}

export default Cases
