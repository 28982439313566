import React from "react";
import styled from "styled-components";
import Flexbox from "../Flexbox";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import carIcon from "../../images/carIcon3.svg";
import pedestrian from "../../images/pedestrianIcon.svg";
import motorcycle from "../../images/motorcycleIcon.svg";
import truck from "../../images/truckIcon.svg";
// import
const OrderedList = styled.ul`
  /* color: #fff; */
  list-style: none;
  padding: 0;
  /* max-width: 700px; */
  align-self: center;
  width: 90%;
  h3 {
    text-align: left;
    margin: 0;
  }
  & > :not(:last-child) {
    /* margin: 0 0 20vh; */
    margin: 0 0 70px;
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
    & > :not(:last-child) {
      margin: 0 0 50px;
      /* margin: 0 0 50px; */
    }
  }
  /* margin: 100px 0; */
`;

const H1 = styled.h1`
  /* opacity: 0;
transform: translateY(20px);
transition: all 0.4s ease;

&.visible{
  opacity: 1;
  transform: translateY(0);
} */
`;

const Span = styled.h2`
  z-index: 1;
  position: relative;
  height: min-content;
  width: min-content;
  white-space: nowrap;
  align-self: center;
  margin: 0;
  /* margin-top: -0.45em; */
  font-size: 2rem;
  line-height: 1.1em;
  transition: all 0.2s ease;
  font-family: 'Playfair Display', serif;
  letter-spacing: -.03em;
  transform: translateY(20px);
  opacity: 0;
  &.visible {
    transform: translateY(0); 
  opacity: 1;
  }
  ::after {
    content: "";
    font-size: inherit;
    position: absolute;
  transition: all .2s ease;
    left: 0;
    bottom: 0em;
    /* width: 0; */
    width: 100%;
    height: 2px;
    /* border-radius: 0.1em; */
    /* opacity: 0; */
    opacity: 1;
    background-color: transparent;
    /* background: linear-gradient(
      90deg,
      var(--formBlue1),
      var(--formBlue2) 50%,
      transparent 50%
    ); */
    background: linear-gradient(
      90deg,
      var(--listUnderLineBgdHighlight),
      transparent 50%,
      transparent 50%
    );
    background-position: 100% 0%;
    background-size: 200% 100%;
    z-index: -1;
  }
  &.visible::after {
    width: 100%;
    background-position: 0% 0%;
  transition: all 0.2s ease;

    /* background-color: var(--formBlue1); */
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
      /* white-space: normal; */
      /* width: 100%; */
  }
`;

const ListItem = styled.li`
  display: grid;
  width: 100%;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    "icon heading"
    ". text";
  column-gap: 20px;

  /* text-shadow: 0 0 1px var(--formBlue1); */
  h3 {
    transition: all 0.2s ease;
    font-size: 1.5rem;
    transform: translateY(20px);
    opacity: 0;
  }
  &.visible {
    h3 {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 40px min-content;
    grid-gap: 15px;
    grid-template-areas:
      "icon"
      "heading"
      "text";
    justify-items: center;
    text-align: center !important;
    h3 {
      text-align: center !important;
      font-size: 16px;
    }
    h2 {
      font-size: 1.5rem;
      white-space: wrap;
    }
  }
`;

const Marker = styled.div`
  display: flex;
  background: transparent;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 70px;
  width: 70px;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.5s ease;
  
  background: var(--formBackground);
  /* border: 1px solid var(--formBlue1); */

  &.visible {
    /* box-shadow: 0 0 12px #ffffff; */
    box-shadow: -5px -5px 12px rgba(168, 245, 255, 0.25),
      6px 6px 15px rgba(0, 29, 194, 0.6),
      inset 5px 5px 5px rgba(255, 255, 255, 0.25), inset -5px -5px 5px rgba(0, 29, 194, 0.3);
    /* box-shadow: 0 0 5px var(--formBlue1); */
  }
  img {
    position: relative;
    height: 90%;
    width: 90%;
    filter: ${'var(--primary)' === '#fff' ? undefined : 'var(--asSeenOnFilter)'}; 
  }
  
`;
const ListComponent = ({ img, heading, text, index }) => {
  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px 0px 0px 0px",
      // rootMargin: "10px",
      threshold: 0.2, // half of item height
    },
    true
  );
  return (
    <ListItem className={isVisible ? "visible" : undefined}>
      <Marker className={isVisible ? "visible" : undefined}>
        {img ? <img src={img} /> : <h5 style={{ fontFamily: 'Playfair Display', fontSize: '1.9rem', lineHeight: '.7rem', margin: '0', marginBottom: '0.37rem' }}>{index}</h5>}
      </Marker>
      <Span
        ref={containerRef}
        gridArea="heading"
        className={isVisible ? "visible" : undefined}
      >
        {heading}
      </Span>
      <h3 style={{ fontWeight: "300", gridArea: "text" }}>{text}</h3>
    </ListItem>
  );
};

const PageList = () => {
  const listItems = [
    {
      img: motorcycle,
      heading: "$5.3M Jury Verdict",
      text: "Partially at-fault, uninsured motorcylist with a traumatic brain injury. The defendant insurance policy had a limit of $1M. Insurance company refused request for a $125k settlement and ended up owing the victim $5.3M.",
    },
    {
      img: carIcon,
      heading: "$2.1 Million Verdict from $0 Offer",
      text: "Insurance company claimed the at-fault driver was excluded from the policy.",
    },
    {
      img: carIcon,
      heading: "Awarded 30x Insurance Offer",
      text: "The at-fault driver tried to deny hitting the victim. Insurance company tried to claim the victim was partially at fault and that the accident was not the cause of the victim's injuries. Victim was awarded $1.5 million.",
    },
    {
      img: truck,
      heading: "$2M Truck Accident",
      text: "Victim was rear-ended by an 18-wheeler and suffered neck and back injuries. The law firm was able to obtain a $2M settlement for the victim despite the insurance company's initial refusal to settle.",
    },
    {
      img: pedestrian,
      heading: "$26M Pedestrian Accident",
    },
    {
      img: carIcon,
      heading: "$12M Car Accident",
    },
    {
      img: carIcon,
      heading: "$2.3M Auto Accident/DUI",
    },
    {
      img: motorcycle,
      heading: "$2.25M Motorcycle Accident",
    },
  ];

  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px 0px -40% 0px",
      threshold: 0.5, // half of item height
    },
    true
  );

  return (
    <Flexbox
      column
      alignItems="center"
      width="100%"
      // color="#fff"
      padding="0 50px 50px"
      mobilePadding="0"
      fontWeight="400"
    // background="var(--formBackground)"
    >
      <OrderedList>
        {listItems.map((item, index) => (
          <ListComponent key={index} {...item} index={index + 1} />
        ))}
      </OrderedList>
    </Flexbox>
  );
};

export default PageList;
