import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import navArrow from '../../images/navArrow.svg'
import anime from 'animejs'

import Flexbox from '../Flexbox'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import AnimatedStep1 from '../AnimatedStep1'
import AnimatedStep2 from '../AnimatedStep2'
import AnimatedStep3 from '../AnimatedStep3'
import Button from '../Button'

import { Wrapper } from '../GridContainer/GridContainer.styles'

const GridC = styled(Wrapper)`
grid-template-columns: min-content 1fr;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            min-height: calc(100vh - var(--navBarHeight));
            height: 100%;
            grid-template-areas: 
            'stepImg'
            'text1';
            grid-template-columns: 1fr;
            grid-template-rows: 1fr min-content ;
            text-align: center;
            .howItWorks h1{
                text-align: center !important;
            }
            .howItWorks{
                align-self: start;
            }
            .howItWorks h3{
                text-align: center !important;
            }
            .listContainer{
                grid-template-rows: repeat(6,min-content);
                grid-template-columns: 1fr;
                justify-items: center;
                margin-bottom: 50px;
            }
            .listItem{
                margin-left: 0;
            }     
        }
`;

const OL = styled.ol`
    position: relative;
    counter-reset: li; /* Initiate a counter */
    list-style: none; /* Remove default numbering */
    *list-style: decimal; /* Keep using default numbering for IE6/7 */

    padding: 0;
    margin-bottom: 4em;
    /* text-shadow: 0 1px 0 rgba(255,255,255,.5); */

    li{
    padding: 2.5em ;
    /* border-bottom: 1px dashed #ccc; */
    }

    .listItem{
        margin-left: 50px;
    }

    h3{
        position: relative;
        margin: 0;
        text-align: left;
    }

    p{
        margin: 0 0 0 10px;
        text-align: left;
        font-weight: bold;
        font-size: 1.2rem;
    }

    h3:before{
        content: counter(li);
        counter-increment: li;
        position: absolute;
        /* z-index: -1; */
        left: -1.3em;
        top: -.2em;
        background: rgba(255,255,255,.10);
        height: 1.5em;
        width: 1.5em;
        border: 1px solid rgba(255,255,255,.45);
        text-align: center;
        /* font: italic bold 1em/1.5em Georgia, Serif; */
        /* color: #ccc; */
        border-radius: 1.5em;
        line-height: 1.5em;
        /* padding: 1.5rem; */
        transition: all .2s ease-out;
    }


    /* li:hover h3:before{
        background-color: #000;
        border-color: rgba(0,0,0,.08);
        border-width: .2em;
        color: #444;
        transform: scale(1.5);
    } */
    `;



const ListItem = styled.div`
    font-weight: bold;
    border-radius: 50%;
    
    height: 1.5em;
    line-height: 1.4em;
    width: 1.5em;
    border: 1px solid rgba(255,255,255,.45);
    text-align: center;
    background: rgba(255,255,255,.10);
`;

const H3 = styled.h3`
margin: 0;
text-align: left;
`;

const Layout1 = ({ componentId }) => {

    const initialRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
            let elements;
            elements = document.querySelectorAll(className);
            Array.from(elements).forEach((element) => {
                let BBox = element.getBBox();
                let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
                let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
                let BBoxX = BBox["x"] + BBoxX2;
                let BBoxY = BBox["y"] + BBoxY2;
                BBox = BBoxX + "px" + " " + BBoxY + "px";
                element.style.transformOrigin = BBox;
            });
        }
        fixSvgElementCoordinates(`${componentId} #gesture`)
        // fixSvgElementCoordinates('#tape2')

        const initializeTimeline = () => {
            anime.set(`${componentId} #california  #CA`, {
                fill: 'none'
            })
            anime.set(`${componentId} #gesture`, {
                opacity: 0
            })
            anime.set(`${componentId} #californiaTag`, {
                opacity: 0
            })
            anime.set(`${componentId} #nextArrow`, {
                opacity: 0.25
            })

            anime.set(`${componentId} #letterText1`, {
                opacity: 0
            })
            anime.set(`${componentId} .envelope`, {
                opacity: 0
            })
            anime.set(`${componentId} #matchGroup`, {
                opacity: 0
            })
            anime.set(`${componentId} #letterhead`, {
                y: 381.95 + 326.1,
                height: 0
            })


            anime.set(`${componentId} #gaussianBlur feGaussianBlur`, {
                stdDeviation: 0
            })
            anime.set(`${componentId} .callFadeIn`, {
                opacity: 0
            })
            anime.set(`${componentId} #overlay`, {
                opacity: 0
            })
            // anime.set(`${componentId} #blurredEnvelope`, {
            //     opacity: 0
            // })
            anime.set(`#step2Animation${componentId.replace('.', '')}`, {
                opacity: 0
            })
            anime.set(`#step3Animation${componentId.replace('.', '')}`, {
                opacity: 0
            })


            var tl = anime.timeline({
                easing: 'easeOutExpo',
                duration: 10000,
                autoplay: true,
                loop: true,
            })
            tl
                .add({
                    targets: `.step1`,
                    background: '#fff',
                    color: '#3b89fa',
                    duration: 250,
                    delay: 250,
                    // endDelay: 3000,
                })
                .add({
                    targets: `${componentId} #gesture`,
                    opacity: [
                        { value: 1.0, duration: 250 },
                        { value: 0.0, duration: 250 }
                    ],
                    scale: [
                        { value: 1.5, duration: 250 },
                        { value: 1, duration: 250 }
                    ],
                    // duration: 250,
                    // delay: 500,
                })
                .add({
                    targets: `${componentId} #california #CA`,
                    fill: "rgba(255,255,255,0.20)",
                    duration: 2500,
                    // endDelay: 3000,
                }, 750)
                .add({
                    targets: [`${componentId} #californiaTag`, `${componentId} #nextArrow`],
                    opacity: 1,
                    duration: 2500,
                    // endDelay: 3000,
                }, 750)
                // .add({
                //     targets: `.step1`,
                //     background: 'rgba(255,255,255,.10)',
                //     color: '#fff',
                //     duration: 250,
                //     delay: 0,
                //     // endDelay: 3000,
                // })
                // .add({
                //     targets: `.step2`,
                //     background: '#fff',
                //     color: '#3b89fa',
                //     duration: 250,
                //     delay: 0,
                //     // endDelay: 3000,
                // })
                .add({
                    targets: `#step2Animation${componentId.replace('.', '')}`,
                    opacity: 1,
                    duration: 550,
                    delay: 0,
                    // delay: 500,
                    // endDelay: 1000

                })
                // .add({
                //     targets: `.step1`,
                //     background: 'rgba(255,255,255,.10)',
                //     color: '#fff',
                //     duration: 250,
                //     delay: 0,
                //     // endDelay: 3000,
                // })
                // .add({
                //     targets: `.step2`,
                //     background: '#fff',
                //     color: '#3b89fa',
                //     duration: 250,
                //     delay: 0,
                //     // endDelay: 3000,
                // })
                .add({
                    targets: `${componentId} .envelope`,
                    opacity: 1,
                    duration: 550,
                })
                .add({
                    targets: `.step1`,
                    background: 'rgba(255,255,255,.10)',
                    color: '#fff',
                    duration: 250,
                    delay: 0,
                })
                .add({
                    targets: `.step2`,
                    background: '#fff',
                    color: '#3b89fa',
                    duration: 250,
                    delay: 0,
                    // endDelay: 3000,
                })
                .add({
                    targets: `${componentId} #letterhead`,
                    height: 326.1,
                    y: 381.95,
                    // scale: 1.5,
                    duration: 1000,
                    // delay: 500,
                })
                .add({
                    targets: [`${componentId} #letterText1`, `${componentId} #matchGroup`],
                    opacity: 1,
                    duration: 500,
                    // endDelay: 1000

                })

                // .add({
                //     targets: `${componentId} #matchGroup`,
                //     opacity: 1,
                //     duration: 250,
                //     // endDelay: 1000
                // }, '-=250')

                .add({
                    targets: `#step3Animation${componentId.replace('.', '')}`,
                    opacity: 1,
                    duration: 550,
                    delay: 0,
                    // endDelay: 1000

                })

                // .add({
                //     targets: `${componentId} #gaussianBlur feGaussianBlur`,
                //     stdDeviation: 15,
                //     duration: 1500,
                //     delay: 0,

                // })
                .add({
                    targets: `${componentId} #overlay`,
                    opacity: 0.25,
                    duration: 500,
                    delay: 0
                })
                .add({
                    targets: `${componentId} #gaussianBlur feGaussianBlur`,
                    stdDeviation: 2,
                    duration: 500,
                    // delay: 0,

                }, '-=500')
                .add({
                    targets: `${componentId} .callFadeIn`,
                    opacity: 1.0,
                    duration: 500,
                    delay: 0,
                    // endDelay: 2500
                })
                .add({
                    targets: `.step2`,
                    background: 'rgba(255,255,255,.10)',
                    color: '#fff',
                    duration: 250,
                    delay: 0,
                    // endDelay: 3000,
                })
                .add({
                    targets: `.step3`,
                    background: '#fff',
                    color: '#3b89fa',
                    duration: 250,
                    delay: 0,
                    endDelay: 2500,
                })



            tl.play()
        }
        if (initialRef.current) {
            initialRef.current = false
            initializeTimeline()
        }



    }, [])

    // 
    return (
        <GridC
            id="how-it-works"
            width="100%"
            color="#fff"
            align="center"
            // background="var(--formBackground)"
            gap="20px 20px"
            height="100vh"
            padding="50px"
            justifyContent="center"
            // gridTemp={{ rows: 'min-content min-content', columns: 'repeat(3,min-content)' }}
            gridTempAreas="'stepImg text1'"
        >
            <GridItem
                // row="1" 
                gridArea="text1"
                className="howItWorks"
                display="flex"
                flexDirection="column"
                gap="20px"
            >
                <h1 style={{ textAlign: 'start' }}>How It Works</h1>
                <GridContainer className="listContainer" gap="1em" gridTemp={{ columns: 'min-content 1fr', rows: 'repeat(3, min-content)' }}>

                    <ListItem className="step1 listItem">1</ListItem>
                    <H3>Go to our "Get Started" form and enter basic info about your case</H3>
                    <ListItem className="step2 listItem">2</ListItem>
                    <H3>We process this to match you with an attorney who can help</H3>
                    <ListItem className="step3 listItem">3</ListItem>
                    <H3>Attorney calls you to provide free case evaluation</H3>
                    
                </GridContainer>
            </GridItem>
            <GridItem

                width="20vw"
                mobileWidth="100%"
                className={componentId.replace('.', '')}
                id={"step1Animation" + componentId.replace('.', '')}
                gridArea="stepImg">
                {/* gridArea="step1Img"> */}
                <AnimatedStep1 className={componentId.replace('.', '')} />
            </GridItem>
            <GridItem

                width="20vw"
                mobileWidth="100%"
                id={`step2Animation${componentId.replace('.', '')}`}
                gridArea="stepImg">
                {/* gridArea="step2Img"> */}
                <AnimatedStep2 className={componentId.replace('.', '')} />
            </GridItem>
            <GridItem

                width="20vw"
                mobileWidth="100%"
                id={`step3Animation${componentId.replace('.', '')}`}
                gridArea="stepImg">
                {/* gridArea="step3Img"> */}
                {/* <AnimatedStep3 className={componentId.replace('.', '')} /> */}
            </GridItem>
        </GridC>
    )
}

// const Layout2 = ({ componentId }) => {
//     useEffect(() => {
//         const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2) => {
//             let elements;
//             elements = document.querySelectorAll(className);
//             Array.from(elements).forEach((element) => {
//                 let BBox = element.getBBox();
//                 let BBoxX2 = xFactor == 0 ? 0 : BBox["width"] / xFactor;
//                 let BBoxY2 = yFactor == 0 ? 0 : BBox["height"] / yFactor;
//                 let BBoxX = BBox["x"] + BBoxX2;
//                 let BBoxY = BBox["y"] + BBoxY2;
//                 BBox = BBoxX + "px" + " " + BBoxY + "px";
//                 element.style.transformOrigin = BBox;
//             });
//         }
//         fixSvgElementCoordinates('#gesture')
//         // fixSvgElementCoordinates('#tape2')

//         anime.set(`${componentId} #california  #CA`, {
//             fill: 'none'
//         })
//         anime.set(`${componentId} #gesture`, {
//             opacity: 0
//         })
//         anime.set(`${componentId} #californiaTag`, {
//             opacity: 0
//         })
//         anime.set(`${componentId} #nextArrow`, {
//             opacity: 0.25
//         })

//         anime.set(`${componentId} #letterText1`, {
//             opacity: 0
//         })
//         anime.set(`${componentId} .envelope`, {
//             opacity: 0
//         })
//         anime.set(`${componentId} #matchGroup`, {
//             opacity: 0
//         })
//         anime.set(`${componentId} #letterhead`, {
//             y: 381.95 + 326.1,
//             height: 0
//         })


//         anime.set(`${componentId} #gaussianBlur feGaussianBlur`, {
//             stdDeviation: 0
//         })
//         anime.set(`${componentId} .callFadeIn`, {
//             opacity: 0
//         })
//         anime.set(`${componentId} #overlay`, {
//             opacity: 0
//         })
//         anime.set(`${componentId} #blurredEnvelope`, {
//             opacity: 0
//         })
//         // anime.set(`${componentId} #step2Animation`, {
//         //     opacity: 0
//         // })
//         // anime.set(`${componentId} #step3Animation`, {
//         //     opacity: 0
//         // })


//         var tl = anime.timeline({
//             easing: 'easeOutExpo',
//             duration: 3500,
//             autoplay: true,
//             loop: true,
//         })
//         tl
//             .add({
//                 targets: `${componentId} #gesture`,
//                 opacity: 1,
//                 scale: 1.5,
//                 duration: 250,
//                 delay: 500,
//             })
//             .add({
//                 targets: `${componentId} #gesture`,
//                 opacity: 0,
//                 scale: 1,
//                 duration: 250,
//             })
//             .add({
//                 targets: [`${componentId} #california #CA`,`${componentId} #californiaTag`,`${componentId} #nextArrow`],
//                 fill: "rgba(255,255,255,0.20)",
//                 duration: 500,
//                 // endDelay: 3000,
//             }, 750)
//             // .add({
//             //     targets: `${componentId} #step2Animation`,
//             //     opacity: 1,
//             //     duration: 550,
//             //     // delay: 500,
//             //     // endDelay: 1000

//             // })
//             .add({
//                 targets: `${componentId} .envelope`,
//                 opacity: 1,
//                 duration: 550,
//                 // delay: 500,
//                 // endDelay: 1000

//             })
//             .add({
//                 targets: `${componentId} #letterhead`,
//                 height: 326.1,
//                 y: 381.95,
//                 // scale: 1.5,
//                 duration: 1000,
//                 // delay: 500,
//             })
//             .add({
//                 targets: `${componentId} #letterText1`,
//                 opacity: 1,
//                 duration: 500,
//                 // endDelay: 1000

//             })

//             .add({
//                 targets: `${componentId} #matchGroup`,
//                 opacity: 1,
//                 duration: 250,
//                 // endDelay: 1000
//             }, '-=250')
//             // .add({
//             //     targets: `${componentId} #step3Animation`,
//             //     opacity: 1,
//             //     duration: 550,
//             //     delay: 500,
//             //     // endDelay: 1000

//             // })
//             .add({
//                 targets: `${componentId} #blurredEnvelope`,
//                 opacity: 1,
//                 duration: 500,
//                 delay: 500,
//                 // endDelay: 1000
//             })
//             .add({
//                 targets: `${componentId} #gaussianBlur feGaussianBlur`,
//                 stdDeviation: 15,
//                 duration: 500,
//                 delay: 500,
//                 // endDelay: 1000

//             })
//             .add({
//                 targets: `${componentId} .callFadeIn`,
//                 opacity: 1.0,
//                 duration: 500,
//                 delay: 0,
//             }, "-=500")
//             .add({
//                 targets: `${componentId} #overlay`,
//                 opacity: 0.15,
//                 duration: 500,
//                 delay: 0,
//                 endDelay: 2500
//             }, "-=500")


//         tl.play()

//     }, [])

//     //
//     return (
//         <GridContainer color="#000"
//             width="100%"
//             // background="var(--formBackground)"
//             gap="20px 50px" padding="50px" justifyContent="center"
//             gridTemp={{ rows: 'min-content min-content', columns: 'repeat(3,min-content)' }}
//         // gridTempAreas="'stepImg text1''stepImg text2''stepImg text3'"
//         >
//             <GridItem
//                 row="1"
//             // gridArea="text1"
//             >
//                 <h3>Take less than 1 min to tell us the basics of your case</h3>
//             </GridItem>
//             <GridItem
//                 row="2" col="1"
//                 id="step1Animation"
//             // gridArea="stepImg"
//             >
//                 {/* gridArea="step1Img"> */}
//                 <AnimatedStep1 className={componentId.replace('.', '')} />
//             </GridItem>
//             <GridItem
//                 row="1" col="1"
//             // gridArea="text2"
//             >
//                 <h3>We process the info you provide to connect you with an attorney who may be able to help</h3>
//             </GridItem>
//             <GridItem
//             // row="2" col="1" 
//             // id="step2Animation"
//             // gridArea="stepImg"
//             >
//                 {/* gridArea="step2Img"> */}
//                 <AnimatedStep2 className={componentId.replace('.', '')} />
//             </GridItem>
//             <GridItem
//                 row="1" col="3"
//             // gridArea="text3"
//             >
//                 <h3>Receive free consultation</h3>
//             </GridItem>
//             <GridItem
//             // row="2" col="1" 
//             // id="step3Animation"
//             // gridArea="stepImg"
//             >
//                 {/* gridArea="step3Img"> */}
//                 <AnimatedStep3 className={componentId.replace('.', '')} />
//             </GridItem>
//         </GridContainer>
//     )
// }

const HowItWorks = ({ altLayout = false, componentId = '.svg' }) => {

    return (
        <>
            {/* <Layout2 componentId=".ipsum"></Layout2> */}
            <Layout1 componentId=".lorem" />
        </>)
}

export default HowItWorks