import React, { useState } from "react";
import Flexbox from "../../Flexbox";
import InputField from "../../InputField";
import TextArea from "../../TextArea";
import Button from "../../Button";
import { NavLink } from "react-router-dom";
import connectWith from "../../../images/connectWith@4x.png";

import styled from "styled-components";
import { Wrapper } from "../../Flexbox/Flexbox.styles";

const Link = styled(NavLink)`
  all: unset;
  color: var(--linkColor);
  font-weight: 700;
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  grid-area: ${({ gridArea }) => gridArea};
  grid-column: ${({ col }) => col};
  grid-row: ${({ row }) => row};
  :hover {
    text-decoration: underline;
  }
`;

const FlexWrapper = styled(Wrapper)`
  position: absolute;
  z-index: -1;
  visibility: hidden;
  width: 0;
  height: 0;
  display: none;
  @media only screen and (max-width: 750px),
    screen and (max-device-width: 750px) {
    position: relative;
    z-index: unset;
    visibility: visible;
    width: 100%;
    height: 100%;
    display: flex;
  }
`;

const PrivacyInfo = styled.h5`
font-weight: 300;
text-align: center;
font-size: .8rem;
margin: 10px 0;
max-width: 500px;
@media only screen and (max-width: 750px),
    screen and (max-device-width: 750px) {
    font-size: 15px;
    font-weight: 300;
  }
`;

const FinalStep = ({
  containerProps,
  label,
  fields,
  handleChange,
  placeholder,
  step,
  onKeyUp,
  onKeyDown,
  nextStep,
  backStep,
}) => {
  const [shouldShowValidators, setShouldShowValidators] = useState(false);
  const [valid, setValid] = useState(false);
  const { fieldOrder } = step;
  return (
    <Flexbox
      // background="#fff"
      // color="#000"
      column
      //   gap="20px"
      width="100%"
      className="noMarginForText left"
      // border="1px solid #fff"
      padding="5% 5% 0"
      mobilePadding="0 5%"
      maxWidth="690px"
      {...containerProps}
      overflow="auto"
      minHeight="min-content"
    >
      <h3>How can the attorney reach you?</h3>
      <PrivacyInfo >Your personal information is encrypted, confidential, and shared only with the one law firm with whom we connect you. </PrivacyInfo>
      {fieldOrder.map((fieldKey) => {
        const field = fields[fieldKey];
        const { name, type } = field;
        if (type === undefined || field["type"] !== "textArea")
          return (
            <>
              <h4 style={{ margin: "10px 0 5px", alignSelf: "start" }} className="left">
                {field.label}
              </h4>
              <Flexbox gap="10px" width="100%">
                <Flexbox flexGrow="2" flexBasis="100%" width="100%">
                  <InputField
                    type={type ? type : "text"}
                    onChange={
                      name === "phoneNumber"
                        ? () => {
                          // if (shouldShowValidators)
                          //     setShouldShowValidators(false)
                        }
                        : function (e) {
                          // if (shouldShowValidators)
                          //     setShouldShowValidators(false)
                          field.onChange(e);
                        }
                    }
                    // onChange={function (_val) { step.onChange(_val, 'phoneNumber') }}
                    name={name}
                    placeholder={
                      name === "phoneNumber"
                        ? field.placeholder[name]
                        : field.placeholder
                    }
                    marginBottom={"20px"}
                    value={
                      name === "phoneNumber" ? field.value[name] : field.value
                    }
                    width="100%"
                    onKeyDown={
                      name === "phoneNumber" ? onKeyDown(`${name}`) : undefined
                    }
                    onKeyUp={name === "phoneNumber" ? onKeyUp : undefined}
                    uppercaseOff={true}
                    border="none; border-bottom: 1px solid #fff"
                    showValidationCheckmark={
                      (shouldShowValidators && !field["isValid"]) ||
                      field["shouldShow"]
                    }
                    labelTextAlign="left"
                    labelFontSize="14px"
                    
                    useUnderlineTheme={true}
                  />
                </Flexbox>
                {/* <Flexbox fontSize="12px">
                                        ext
                                    </Flexbox>
                                    <Flexbox flexShrink="2" flexBasis="30%">
                                        <InputField
                                            // onKeyPress={continueKey}
                                            type={type ? type : 'text'}
                                            // onChange={handleChange(`${name}`)}
                                            onChange={function (_val) { field.onChange(_val, 'extension') }}
                                            name={'extension'}
                                            placeholder={field.placeholder['extension']}
                                            marginBottom={'20px'}
                                            value={field.value['extension']}
                                            width="100%"
                                            // onKeyDown={onKeyDown(`${name}`)}
                                            onKeyUp={onKeyUp}
                                            uppercaseOff={true}
                                        />
                                    </Flexbox> */}
              </Flexbox>
            </>
          );
        else
          return (
            <>
              <h4 style={{ margin: "10px 0 5px", alignSelf: "start" }}>{field.label}</h4>
              {field.subtextLabel && (
                <h5 style={{ margin: "0 0 10px", alignSelf: "start", fontWeight: '400' }}>
                  {field.subtextLabel}
                </h5>
              )}
              <TextArea
                {...step}
                name={name}
                type={type}
                value={field.value}
                placeholder={field['placeholder']}
                // inputPlaceHolderColor="#9c9c9c"
                // color="#000"
                // border="1px solid #000"
                // focusOutline="1px solid var(--formBlue1)"
                onChange={function (event) {
                  console.log("here::::::::");
                  field.onChange(event);
                }}
                // background="rgba(255,255,255,0.1)"
                // border="none; border-bottom: 1px solid #fff"
                padding="10px 35px 10px 20px"
                // focusBorder="none; border-bottom: 2px solid #fff"
                // focusOutline="none"
                fontSize="16px"
                useUnderlineTheme={true}
              // onChange={handleChange(`${name}`)}
              />
            </>
          );
      })}
      <Flexbox width="100%" gap="10px" margin="10px 0 0">
        <FlexWrapper>
          <Button
            background="var(--formBackButtonBgd)"
            color="var(--formBackButtonColor)"
            colorHover="var(--formBackButtonColorHover)"
            paddingRight="0"
            paddingLeft="0"
            border="var(--formBackButtonBorder)"
            text="Back"
            letterSpacing="0.05em"
            bgdHover="var(--formBackButtonBgdHover)"
            onClick={backStep}
            width="100%"
            borderRadius="30px"
          />
        </FlexWrapper>
        <Button
          background="var(--formSubmitButtonBgd)"
          color="var(--formSubmitButtonColor)"
          colorHover="var(--formSubmitButtonColorHover)"
          paddingRight="0"
          paddingLeft="0"
          border="2px solid #fff"
          borderHover="2px solid #fff"
          text="Submit"
          letterSpacing="0.05em"
          bgdHover="var(--formSubmitButtonBgdHover)"
          // colorHover="#fff"
          onClick={async (e) => {
            // if (fieldOrder.map)
            // gtag_report_conversion(window.location.href)
            setShouldShowValidators(true);
            await nextStep(e);
          }}
          width="100%"
          borderRadius="30px"
        />
      </Flexbox>

      <p
        style={{
          fontSize: "12px",
          margin: "5px 0 0",
          textAlign: "center",
        }}
      >By submitting this form you agree to our <Link to="/privacy-policy" target="_blank">Privacy Policy</Link> and <Link to="/terms" target="_blank">Terms and Conditions</Link>.
      </p>
    </Flexbox>
  );
};

export default FinalStep;
