import React from 'react'
import PageContentContainer from './PageContentContainer'
import Flexbox from './Flexbox'
import machineLearningMarketing4 from '../images/machineLearningMarketing4.svg'
import quickAndEasy from '../images/quickAndEasy.svg'
import chess from '../images/summit.svg'
import silverPlatter from '../images/silverPlatter.svg'
import sparkInterest from '../images/sparkInterest.svg'
import { RLink } from './PrivacyPolicy'
import { H2 } from './Section/Section.styles'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Button from './ButtonHome'

const H2B = styled.h2`
font-size: 2rem;
text-transform: uppercase;
font-weight: bold;
letter-spacing: -.03em;
line-height: 1.2em;
`;

const H1 = styled.h1`
line-height: 1.2em;
text-transform: uppercase;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
        align-self: center;
    }
`;

const P = styled.p`
margin: 1em 0;
`;

const Img = styled.img`
max-width: 450px;
flex-shrink: 1;
width: 100%;
height: auto;
max-height: 90%;
object-fit: contain;
object-position: left;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        max-height: 300px;
        align-self: flex-start;
    }
`;


const About = ({ title = "About",  color = "#000 !important", margin = "calc(var(--navBarHeight) * -1) 0 0", hideButton = false }) => {
    const navigate = useNavigate()
    const props = {
        flexDirection: 'column',
        lineHeight: "1.3em",
        flexBasis: "50%",
        alignItems: "start",
        flexGrow: '1',
    }
    const props2 = {
        flexWrapMobile: 'wrap'
    }
    return (
        <PageContentContainer
            column
            color={color}
            display="flex"
            position="relative"
            alignText="start"
            margin={margin}
            width="100%"
            height="100%"
            background="#ffffff"
            justifyContent="start" alignItems="center" flexDirection="row" padding="calc(var(--navBarHeight) + 30px) 0 0">
            <Flexbox column padding="0 50px 50px" mobilePadding="0 5% 50px" gap="5px">
                <h1 style={{ margin: '0', fontSize: '2.2rem' }}>{title}</h1>
                {/* <Flexbox> */}

                <h2 style={{ fontSize: '1.3rem', margin: '0', fontWeight: '300', textAlign: 'center', maxWidth: '700px', color: 'var(--grey1)' }}>
                    We combine our data-driven matching algorithm and All-Star network of attorneys to make maximizing your settlement easy.
                </h2>
                <img src={machineLearningMarketing4} height="100%" style={{ maxHeight: '500px', width: 'auto', margin: '20px 0 0' }} />

            </Flexbox>
            <Flexbox background="#f2f2f2" padding="50px" mobilePadding="30px  5% 50px" justifyContent="space-between" gap="50px" {...props2}>
                <Flexbox column lineHeight="1.3em" {...props} flexGrow="2">
                    <p> <H1>Connecting you with the most elite attorneys in the country</H1>

                    </p>
                    <P>
                        Only a handful of attorneys could be said to be at the very top of the legal field.  While there are countless firms who will take any case, the very best are overwhelmed with demand and have to be far more selective.
                        <br /><br />
                        They invest time and money into each case but don't get paid a dime unless you win, so they only want significant cases they believe will result in a large payment.  It can be hard to get attorneys like these.
                        <br /><br />
                        Some may decline your case, some may never even call you back.
                        <br /><br />
                        That's where we come in.  Our service has a special agreement with top attorneys.  If you have a valid case, they WILL call you. They've even agreed to provide free legal advice and analysis of your case's value for everyone who goes through our service, whether they take your case or not.
                        <br /><br />
                        Here's what's critical to know.  Since most attorneys charge the exact same fee, there's absolutely no reason not to choose one at the very top of the field.
                        <br /><br />We don't just make this possible, we make it quick and easy.
                    </P>
                </Flexbox>
                <img src={chess} height="100%" width="auto" style={{ maxWidth: '450px', flexShrink: '1', width: '100%', height: 'auto', objectFit: 'contain' }} />
                {/* <Flexbox flexBasis="50%">
                </Flexbox> */}
            </Flexbox>
            <Flexbox padding="50px 50px 50px 0" width="100%" mobilePadding="30px 5% 50px 0" justifyContent="space-between" {...props2}>
                <Img src={silverPlatter} />
                <Flexbox column lineHeight="1.3em"  {...props} margin="0 0 0 50px" mobileMargin="0 0 0 5%">

                    <H1>MAXIMUM COMPENSATION SERVED ON A SILVER PLATTER</H1><p>No one honest can ever guarantee you an outcome, but it's our goal to connect you with the kind of attorney who is capable of delivering maximum injury compensation for their clients, and has a proven track record of doing just that.
                        <br /><br />
                        We want you to stay calm, relax and focus on healing while an elite injury attorney fights the insurance company to get the full amount you're owed.
                        <br /><br />
                        The insurance company's job is to pay you as little as possible, no matter how unfair it is.  They want to avoid paying you for future medical expenses, pain and suffering, all of which you may be legally entitled to.  They have a ruthless team of lawyers to help them get away with it. <br /><br />
                        Top injury attorneys are experienced in taking them on, and winning.  <br /><br />
                        By providing an opportunity to gain access to powerhouse firms like this, one that most people never get, we make it easy to pursue maximum compensation for your case.
                    </p>
                </Flexbox>
            </Flexbox>
            <Flexbox background="#f2f2f2" padding="50px" mobilePadding="30px 5%" justifyContent="space-between" gap="50px" {...props2} flexWrapMobile="wrap-reverse">
                <Flexbox {...props}>
                    <H1 id="how-onboarding-works" >WHY THE NAME CALM?</H1>
                    <p>
                        The main reason inured people don't get all the compensation they're entitled to is because of emotions like fear and anxiety.  Many insurance companies use psychological tactics to make you think their lowball offer is the best you're going to get.  They turn on the stopwatch and tell you if you don't accept it soon, you'll get nothing.
                        <br /><br />
                        They know this will cause you to get angry at the injustice, and frightened of paying endless bills for an injury that wasn't your fault.  People get emotional and don't think clearly.  They accept unfair offers before they talk to an attorney to find out their rights, and what their case is really worth.
                        <br /><br />
                        This trick works far too often.<br /><br />
                        We chose CALM because staying calm is the key to making sure you don't get short-changed.
                        <br /><br />Injury attorneys give free consultations.  Take advantage.  Never accept an offer from insurance without at least talking to an attorney.
                    </p>
                </Flexbox>
                <img src={quickAndEasy} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', minWidth: '200px', margin: '0 auto' }} />
            </Flexbox>
            <Flexbox padding="50px" mobilePadding="30px 5%" justifyContent="space-between" gap="50px" width="100%" {...props2}>
                <img src={sparkInterest} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', minWidth: '200px', margin: '0 auto' }} />
                <Flexbox column lineHeight="1.3em" flexBasis="50%" {...props}>
                    <H1 style={{ lineHeight: '1.2em' }}>HOW DOES YOUR MATCHING ALGORITHM WORK?</H1>
                    <p style={{ maxWidth: '700px' }}>
                    We use ChatGPT, possibly the world's most advanced AI, to help find the top match based on your specific case details.
                        <br /><br />
                        While, for ethical reasons, ChatGPT does not ever issue a direct recommendation for hiring a specific attorney, we have developed a proprietary process to use this incredible AI system to help determine which attorney is the closest fit for your personal situation.<br /><br />We present ChatGPT with all available data for your case, including all details you provide and any other available data such as (with your permission) your car accident police report.  We then have the system analyze the attorneys currently active in our database to help determine who may be the top match.
                        <br /><br />We use an extremely targeted approach and match you with just one attorney.<br /><br />The attorney will then reach out for a free case evaluation.  You can discuss hiring them, or just get a case evaluation and free legal advice.</p>
                    {
                        !hideButton && <Button text="Evaluate My Case" boxShadow="0 0 5px rgba(0,0,0.5)" width="100%" maxWidth="400px" onClick={() => navigate('/form', { state: { form_entry: 'About Page - Get Started Button' } })}
                        />}
                </Flexbox>
            </Flexbox>
        </PageContentContainer>
    )
}

export default About