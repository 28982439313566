import React, { useState, useEffect, useRef } from 'react'
import {
    DropDownContainerBox,
    DropDownNavBtn,
    DropdownListContainer,
    BackgroundWrapper,
    DropdownArrow,
    DropdownList,
    DropdownHeader,
    DropdownContainer,
    ListItem,
    RemoveButton,
    LabelStyled,
    SVG
} from './Dropdown.styles'

import { useNavigate } from 'react-router-dom';
import Flexbox from '../Flexbox';
import DropdownIcon from '../../images/dropdownArrow.svg'

const Label = ({ name, label, marginBottom }) => (
    <LabelStyled htmlFor={name} marginBottom={marginBottom}>{label}</LabelStyled>

)

// const CloseDropdown = ({ shouldRotate }) => (

//     <DropdownArrow src={DropdownIcon} alt="dropdown-arrow" shouldRotate={shouldRotate} />
// )
const CloseDropdown = ({ shouldRotate }) => {
    //
    console.log("shouldRotate",shouldRotate)
    return (
        <Flexbox transform={shouldRotate ? 'rotate(180deg)' : 'rotate(0)'} right="20px" width="12px" position="absolute">
        <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.98 5" ><g id="Layer_2" dataName="Layer 2"><g id="Layer_1-2" dataName="Layer 1"><polygon class="cls-1" points="8.46 0 8.98 0.51 4.49 5 0 0.51 0.51 0 4.49 3.98 8.46 0"/></g></g></SVG></Flexbox>
    )
}

const Option = ({ id, showRemoveButton = false, itemPadding, itemHeight, letterSpacing, fontSize, fontWeight, textAlign, color, colorOnSelect, value, defaultSelect, innerText, onClick, isSelected, justifyContent, ...props }) => {
    return (<ListItem height={itemHeight} itemPadding={itemPadding} defaultSelect={defaultSelect} value={value}
        isSelected={isSelected}
        color={color}
        textAlign={textAlign}
        colorOnSelect={colorOnSelect}
        justifyContent={justifyContent}
        letterSpacing={letterSpacing}
        fontSize={fontSize}
        fontWeight={fontWeight}
        id={id}
        onClick={onClick}
        {...props}>


        {innerText}
        {(isSelected && showRemoveButton) && (
            <RemoveButton onClick={onClick} value={innerText} >&#10006;&#xFE0E;</RemoveButton>
        )}
    </ListItem>)
};

const Dropdown = ({
    hasFocus = false,
    handleMenuStateInternally = false,
    dropdownRef,
    maxHeight,
    currSelection,
    textAlign,
    justifyContent,
    justifyContentList,
    label,
    showLabel = false,
    showHeader = false,
    // listContainerRef,
    height,
    itemHeight = '40px',
    itemPadding,
    showRemoveButton,
    width,
    boxShadow,
    padding,
    _handleChange,
    border,
    background,
    listBackground,
    mobileHeight,
    mobileMaxHeight,
    flexDirectionMobile,
    letterSpacing,
    headerBgd,
    fontSize,
    fontWeight,
    name,
    marginBottom,
    color,
    menuState,
    setMenuState,
    colorOnSelect,
    dropdownBottomMobile,
    marginTopMobile,
    showArrow = false,
    dropdownListMarginTop,
    options, setState, handleStateChange = () => { }, valueX, key, id, handleOrderChange, ...props }) => {
    const listContainerRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(currSelection || valueX)
    const [currentVal, setCurrentVal] = useState(currSelection || valueX)
    const bgdRef = useRef(null)
    const initial = useRef(true);
    const handleChange = (event) => {

        setIsOpen(!isOpen)

    }

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        if (!handleMenuStateInternally)
            setIsOpen(!isOpen)
    }, [])

    useEffect(() => {
        if (hasFocus && dropdownRef && dropdownRef.current)
            dropdownRef.current.focus()
    }, [hasFocus])

    useEffect(() => {
        if (listContainerRef.current)
            handleLongSuggestionsList()
    }, [currSelection])
    console.log("headerBgd",headerBgd)
    const handleLongSuggestionsList = () => {
        if (listContainerRef.current) {
            const activeIndex = options.findIndex(it => it.innerText === currSelection)
            // console.log("currSelection: ", activeIndex, Math.floor(parseInt(maxHeight) / parseInt(itemHeight)),
            // activeIndex % Math.floor(parseInt(maxHeight) / parseInt(itemHeight)) === 0,
            //     itemHeight * (activeIndex + 1), options.findIndex(it => it.innerText === currSelection))
            const { scrollHeight, offsetHeight } = listContainerRef.current
            if (scrollHeight > offsetHeight && (activeIndex % Math.floor(parseInt(maxHeight) / parseInt(itemHeight)) === 0))
                listContainerRef.current.scrollTop = parseInt(itemHeight) * (activeIndex)

        }
    }

    const listLength = options.length

    const getMinHeightMobile = () => {
        if (!mobileMaxHeight)
            return undefined
        let viewportHeight = window.innerHeight
        let _itemHeight = parseFloat(itemHeight)
        let _mobileMaxHeight = mobileMaxHeight.includes('vh') ? parseFloat(mobileMaxHeight) / 100 * viewportHeight : parseFloat(mobileMaxHeight)
        if (Math.min(_mobileMaxHeight, _itemHeight * listLength) === _mobileMaxHeight)
            return mobileMaxHeight
        else
            return `calc(${itemHeight} * ${listLength})`
    }
    console.log("menuState", menuState, isOpen, handleMenuStateInternally)

    if (showHeader)
        return (<>
            {/* {isOpen && <BackgroundWrapper ref={bgdRef} onClick={(e) => { if (e.target === bgdRef.current && typeof (_handleChange) === 'function') _handleChange() }} />} */}
            {
                showLabel &&
                <Label
                    name={name}
                    label={label}
                    marginBottom={marginBottom}
                ></Label>
            }
            <DropdownContainer
                textAlign={textAlign}
                width={width}
                id={id}
                boxShadow={showHeader ? boxShadow : undefined}
                {...props}>

                <DropdownListContainer
                    background={background}
                    boxShadow={showHeader ? boxShadow : undefined}
                    border={border}>
                    {
                        showHeader &&
                        <DropdownHeader
                            onClick={setMenuState ? setMenuState : () => setIsOpen(!isOpen)}
                            fontWeight={fontWeight}
                            {...props}
                            headerBgd={headerBgd}
                        >{valueX || currSelection}
                            {showArrow && <CloseDropdown shouldRotate={!handleMenuStateInternally ? menuState : (menuState|| isOpen)} />}
                        </DropdownHeader>
                    }
                    <DropdownList
                        // ref={dropdownRef ? dropdownRef : undefined}
                        boxShadow={boxShadow}
                        ref={listContainerRef}
                        onFocus={
                            () => {
                                console.log("focus is here")
                            }
                        }
                        height={height}
                        listBackground={listBackground}
                        border={border}
                        marginTop={dropdownListMarginTop}
                        maxHeight={maxHeight}
                        justifyContent={justifyContentList}
                        padding={padding}
                        flexDirectionMobile={flexDirectionMobile}
                        dropdownBottomMobile={dropdownBottomMobile}
                        marginTopMobile={marginTopMobile}
                        mobileMaxHeight={getMinHeightMobile()}
                        mobileHeight={mobileHeight}
                    >
                        {
                            (((isOpen && handleMenuStateInternally) || (menuState && !handleMenuStateInternally))) &&
                            options.map(option => {
                                return (
                                    <Option
                                        id={option['id'] ? option['id'].replaceAll(' ', '-').toLowerCase() : undefined}
                                        itemHeight={itemHeight}
                                        itemPadding={itemPadding}
                                        color={color}
                                        letterSpacing={letterSpacing}
                                        fontSize={fontSize}
                                        fontWeight={fontWeight}
                                        textAlign={textAlign}
                                        colorOnSelect={colorOnSelect}
                                        justifyContent={justifyContent}
                                        value={option.value}
                                        showRemoveButton={showRemoveButton}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={(e) => {
                                            if (typeof (option['onClick']) === 'function')
                                                option.onClick(e)
                                            handleStateChange(e)
                                            if (handleMenuStateInternally)
                                                setIsOpen(!isOpen)
                                        }} //was selectNew
                                        isSelected={valueX ? valueX : currSelection ? currSelection === option.innerText : valueX.length ? valueX.includes(option.value) : false}
                                        {...option.props}
                                    />

                                )
                            })
                        }
                        <input ref={dropdownRef} id="dropdownRef" />
                    </DropdownList>
                </DropdownListContainer>



            </DropdownContainer>
        </>

        )
    return (<>
        {
            showLabel &&
            <Label
                name={name}
                label={label}
                marginBottom={marginBottom}
            ></Label>
        }
        <DropdownContainer
            textAlign={textAlign}
            width={width}
            id={id}
            boxShadow={showHeader ? boxShadow : undefined}
            {...props}>
            {menuState && (
                <DropdownListContainer

                    background={background}
                    border={border}>
                    {
                        showHeader &&
                        <DropdownHeader
                            onClick={handleChange}
                            fontWeight={fontWeight}
                            {...props}
                            headerBgd={headerBgd}
                        >{valueX || currSelection}
                            {showArrow && <CloseDropdown shouldRotate={!handleMenuStateInternally ? menuState : (menuState|| isOpen)} />}
                        </DropdownHeader>
                    }
                    <DropdownList
                        boxShadow={boxShadow}
                        ref={listContainerRef}
                        onFocus={
                            () => {
                                console.log("focus is here")
                            }
                        }
                        height={height}
                        listBackground={listBackground}
                        border={border}
                        marginTop={dropdownListMarginTop}
                        maxHeight={maxHeight}
                        justifyContent={justifyContentList}
                        padding={padding}
                        dropdownBottomMobile={dropdownBottomMobile}
                        marginTopMobile={marginTopMobile}
                        flexDirectionMobile={flexDirectionMobile}
                        mobileMaxHeight={getMinHeightMobile()}
                        mobileHeight={mobileHeight}
                    >
                        {((isOpen && handleMenuStateInternally) || !handleMenuStateInternally) &&
                            options.map(option => {
                                return (
                                    <Option
                                        id={option['id'] ? option['id'].replaceAll(' ', '-').toLowerCase() : undefined}
                                        itemHeight={itemHeight}
                                        itemPadding={itemPadding}
                                        color={color}
                                        letterSpacing={letterSpacing}
                                        fontSize={fontSize}
                                        fontWeight={fontWeight}
                                        textAlign={textAlign}
                                        colorOnSelect={colorOnSelect}
                                        justifyContent={justifyContent}
                                        value={option.value}
                                        showRemoveButton={showRemoveButton}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={(e) => {
                                            if (typeof (option['onClick']) === 'function')
                                                option.onClick(e)
                                            handleStateChange(e)
                                            if (handleMenuStateInternally)
                                                setIsOpen(!isOpen)
                                        }} //was selectNew
                                        isSelected={valueX.length ? valueX.includes(option.value) : currSelection ? currSelection === option.innerText : false}
                                        {...option.props}
                                    />

                                )
                            })
                        }
                        <input ref={dropdownRef} id="dropdownRef" onFocus={() => console.log("focus is here")} />
                    </DropdownList>
                </DropdownListContainer>
            )}


        </DropdownContainer>
    </>)
}

export default Dropdown
