import React, { useEffect, useRef, useState } from 'react'

import { SearchIcon, AlertIconWrapper, Wrapper, InputStyled, LabelStyled, InnerWrapper, DollarSign } from './InputField.styles'

import { debounce } from '../../utils'

import CheckmarkValidate from '../CheckmarkValidate'

const Label = ({ label, name, marginBottom, labelTextAlign, labelMargin, ...props }) => (


    <LabelStyled
        htmlFor={name}
        labelTextAlign={labelTextAlign}
        {...props}
    ><h3 style={{ textAlign: labelTextAlign ? labelTextAlign : undefined, margin: labelMargin }}>{label}</h3></LabelStyled>
)

const InputField = ({
    triggerFocus,
    _inputRef,
    onKeyUp,
    isTyping,
    onKeyDown,
    refId,
    id,
    onBlur,
    className,
    disabled,
    showValidationCheckmark = false,
    inputFieldProps,
    activeSearch, clearAll, clearListOnSearch, onSearch,
    isSearch = false, focusOnMount, marginTop, textAlign, width, uppercaseOff,
    labelTextAlign,
    useUnderlineTheme = false,
    type, image, onChange = function () { }, placeholder, value, label, name, onKeyPress, priceOnBlur, ...props
}) => {
    const underLineTheme = useUnderlineTheme ? {
        background: "var(--underLineThemeBgd)",
        border: "none",
        focusBorder: "none",
        underline: "var(--underline)",
        underlineFocus: "var(--underlineFocus)",
        focusOutline: "none",
        padding: "",
        outline: "",
    } : {}
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);

    const getRef = () => {
        if (refId)
            return refId
        return inputRef
    }

    function handleCursor(event) {

        event.preventDefault();
        // setCursorPosition(inputRef.current.selectionStart)
        // setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = getRef().current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            getRef().current.setSelectionRange(caretPos, caretPos);
        }
    }, [value])

    useEffect(() => {
        if (focusOnMount && getRef().current !== null)
            getRef().current.focus()
    }, [name])

    useEffect(() => {
        if (triggerFocus && triggerFocus.current) {
            console.log("triggering focus")
            inputRef.current.focus()
            triggerFocus.current = false
        }
    }, [triggerFocus])

    if (label !== undefined) {

        return (
            <Wrapper {...props}>
                <Label htmlFor={name}
                    label={label}
                    labelTextAlign={labelTextAlign}
                    {...props}
                ></Label>
                <InnerWrapper 
                        className={className}
                        {...inputFieldProps}>
                    {name === 'price' && <DollarSign
                        {...props}>$</DollarSign>}
                    <InputStyled
                        id={id}
                        isSearch={isSearch}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={name !== 'price' ? onChange :
                            function (e) {
                                let { value } = e.target
                                let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                let decimal;
                                if (value.split('.').length > 1) {
                                    //decimal = value.split('.')[1] + '0';
                                    decimal = value.split('.')[1].substring(0, 2)
                                    price = price + '.' + decimal;
                                }
                                console.log("changing to: ", price)
                                onChange(price)
                            }}
                        name={name}
                        onKeyPress={onKeyPress}
                        onBlur={onBlur ? onBlur : priceOnBlur !== undefined ? priceOnBlur : undefined}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        {...underLineTheme}
                        {...props}
                        ref={getRef()}
                    />
                    {showValidationCheckmark && <CheckmarkValidate {...props} />}
                </InnerWrapper>
            </Wrapper >

        )
    }
    else {
        return (
            <InnerWrapper {...props} 
            className={className}
            {...inputFieldProps}>
                {name === 'price' && <DollarSign>$</DollarSign>}
                <InputStyled
                    id={id}
                    isSearch={isSearch}
                    type={type}
                    placeholder={placeholder}
                    
                    value={value}
                    disabled={disabled ? true : false}
                    onChange={onChange}
                    name={name}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur ? onBlur : priceOnBlur !== undefined ? priceOnBlur : undefined}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    {...underLineTheme}
                    {...props}
                    ref={getRef()}
                />
                {showValidationCheckmark && <CheckmarkValidate {...props} />}
            </InnerWrapper>
        )
    }



}

export default InputField
